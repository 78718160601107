import React from 'react'
import { withIonLifeCycle, IonRow, IonCol } from '@ionic/react'

import { connect } from 'react-redux'

import RecoverPasswordFormPassword from 'components/basepaws/account/RecoverPasswordForms/RecoverPasswordFormPassword'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'

class AccountRecoverPasswordPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = { otp: '' }
  }

  async ionViewWillEnter () {
    let otp = ''
    const query = this.props.location.hash
    if (query) {
      otp = query.substring(1)
      this.setState({ otp: otp })
      console.log(otp)
    }
  }

  render() {
    const user = this.props.user

    return (
      <GeneralPage
        headerStyle='center'
        title={content.get('recoverpassword.title')}
        titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
        user={user}
      >
        <IonRow className="ion-justify-content-center">
          <IonCol size="12"
            size-lg="4"
            size-md="6">
            <RecoverPasswordFormPassword otp={this.state.otp} />
          </IonCol>
        </IonRow>
      </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountRecoverPasswordPassword))
