import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonListHeader, IonCardContent } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'

import { Formik } from 'formik'
import * as Yup from 'yup'
import { servicesNew } from 'util/services'
import styles from './RecoverPasswordFormEmail.module.scss'
import Toaster from 'components/basepaws/utils/Toaster'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import content from 'util/content'

const RecoverPasswordFormEmail = () => {
  const registerSchema = Yup.object().shape({})

  const [edited, setEdited] = useState(false)

  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    try {
      servicesNew.post('/auth/resetPassword', values)
    } catch (e) {
      console.log(e)
    }
    setSubmitting(false)
    history.push({
      pathname: '/recoverPasswordEmail/confirmation',
      state: { detail: emailAttempt.email }
    })
  }

  const [emailAttempt, setEmailAttempt] = useState({ email: '' })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
        setEdited(true)
      }}
      validationSchema={registerSchema}
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Toaster
            header={content.get('RECOVERPASSWORD.EMAIL.TOAST.TITLE')}
            message={content.get('RECOVERPASSWORD.EMAIL.TOAST.TEXT')}
            onDidDismiss={() => {
              setEdited(false)
            }}
            status={edited}
          />
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <IonList className="form ion-margin-top">
                  <IonItem className="item-no-padding ion-margin-bottom"
                           lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Body_bold text_primary">{content.get('RECOVERPASSWORD.EMAIL.SUCCESS.TITLE')}</h4>
                        <p className="Body_regular text_grey">{content.get('RECOVERPASSWORD.EMAIL.SUCCESS.CONTENT')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="item-no-padding"
                           lines="none">
                    <IonInput
                      name="email"
                      onIonChange={(event) => {
                        handleChange(event)
                        setEmailAttempt({ email: event.target.value })
                      }}
                      required
                      type="email"
                    >
                    </IonInput>
                    {errors.born_on && touched.born_on
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                                                                      icon={closeCircle}
                                                                      slot="start" />{errors.born_on}</div>
                        </IonItem>
                        )
                      : null}
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              buttonText={content.get('RECOVERPASSWORD.CTA.SEND_EMAIL')}
              className="default_button"
              color="primary"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              type="submit"
            ></LoaderButton>
          </div>
          <p className="ion-text-center Body_regular text_dark">
            {content.get('RECOVERPASSWORD.PASSWORD.REMEMBER')}
            <NavLink
              className="ml-2 Body_bold"
              to="/login"
            >
              {content.get('RECOVERPASSWORD.SIGN_IN')}
            </NavLink>
          </p>
          {/* <hr className={styles.divider} />
          <p className="ion-text-center Body_regular text_dark">
            {content.get('RECOVERPASSWORD.NEW_ON_BASEPAWS')}
            <NavLink
              className="ml-2 Body_bold"
              to="/register"
            >
              {content.get('RECOVERPASSWORD.CREATE_ACCOUNT')}
            </NavLink>
          </p> */}
        </form>
      )}
    </Formik>
  )
}

export default RecoverPasswordFormEmail
