import React, { useContext, useRef } from 'react'
import { IonButton, IonIcon, IonImg, IonInput, IonLabel } from '@ionic/react'
import { closeSharp, mailSharp } from 'ionicons/icons'
import { string } from 'yup'
import { useField } from 'formik'

import InputSelect from 'components/basepaws/utils/InputSelect'

import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'
import { yupAdapter } from '../Form/Form'
import { Static } from '../Form/Static'

import componentStyles from './UserFind.module.scss'

export const UserFind = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, frame = true, label, bold } = props

  if (frame) {
    return (
    <Row
      bold={bold}
      className={label ? '' : styles.no_label_col}
      label={label}
    >
      <Input
        {...props}
        editable={editable}
        styles={styles}
      />
    </Row>
    )
  }

  return (
      <Input
        {...props}
        editable={editable}
        styles={styles}
      />
  )
}

const defaultCreateLabel = data => (
  <div className='new'>
    <IonImg
      className='icon'
      slot='start'
      src='/assets/icon/data/vet_add.svg'
    />
    <IonLabel>
      Add new Veterinarian
    </IonLabel>
  </div>
)

const Input = ({ icons = {}, editable, name, styles, label, options, required, noValidate = [], userIcon, emailIcon, placeholder, disabled, patientOwner, components, createLabel = defaultCreateLabel, ...props }) => {
  let validate
  const validateReqs = string().required('required')
  if (required) {
    validate = {
      first_name: { validate: noValidate.includes('first_name') ? undefined : yupAdapter(validateReqs) },
      last_name: { validate: noValidate.includes('last_name') ? undefined : yupAdapter(validateReqs) },
      email: { validate: noValidate.includes('email') ? undefined : yupAdapter(validateReqs) }
    }
  }

  const [field, { value }, { setValue }] = useField({ name: name })
  const [{ name: first_name_name }, { value: first_name, onChange: change_first_name }, { setValue: set_first_name }] = useField({ name: name + '.first_name', validate: validate?.first_name.validate })
  const [{ name: last_name_name }, { value: last_name, onChange: change_last_name }, { setValue: set_last_name }] = useField({ name: name + '.last_name', validate: validate?.last_name.validate })
  const [{ name: email_name }, { value: email, onChange: change_email }, { setValue: set_email }] = useField({ name: name + '.email', validate: validate?.email.validate })

  const newItem = (value !== undefined && value !== null) && value.id == null

  const inputRefFocus = useRef()

  icons.first_name = icons.first_name || '/assets/icon/data/vet.svg'
  icons.last_name = icons.last_name || '/assets/icon/data/vet.svg'
  if (!editable) {
    return (
      <>
        <Static
          icon={userIcon ?? (patientOwner ? '/assets/icon/data/pet-owner.svg' : '/assets/icon/data/vet.svg')}
          multipleFirst = {(first_name || last_name) && email}
        >
          {field?.value ? `${first_name} ${last_name}` : <span className='void'>No {label} assigned</span>}
        </Static>
        {email &&
          <Static
            icon={emailIcon ?? mailSharp}
            multiValues = {(first_name || last_name) && email}
          >
            {email}
          </Static>}
        {components?.email || null}
      </>
    )
  }

  return (<>
    {!newItem || disabled
      ? <InputSelect
          createLabel={createLabel}
          disabled={disabled}
          name={name}
          newOptionOnEmpty
          onChange={value => {
            const isNew = !value.id
            if (isNew)setValue(null)
            else setValue(value)
          }}
          onCreate={text => {
            setValue({})
            if (text.includes('@')) { set_email(text) } else {
              const split = text.split(' ')
              set_first_name(split[0])
              if (split.length > 1) set_last_name(split[1])
            }
            setTimeout(() => { // Set Focus on first_name input
              inputRefFocus?.current?.setFocus()
            }, 10)
          }}
          options={options}
          placeholder={`Search existing ${placeholder}s or Add New`}
          value=""
        />
      : <IonButton
          className="default_button icon-start button-remove"
          color="primary"
          fill="clear"
          mode='md'
          onClick={() => { setValue(null) }}
          shape="round"
          type='button'
        >
          <IonIcon
            color="primary"
            src={closeSharp}
          />
          Discard New {label}
        </IonButton>
    }

    <div className={styles.d_md_flex}>
      <IonInput
        className={`mb-2 ${!newItem || disabled ? componentStyles.opaqueLess : ''}`}
        data-qa={first_name_name}
        disabled={!newItem || disabled}
        name={first_name_name}
        onIonChange={e => e.detail.value != null && set_first_name(e.detail.value)}
        placeholder={`${placeholder}'s First name`}
        ref={inputRefFocus}
        value={first_name}
      >
        <IonImg
          className="ion-padding-start"
          src={icons.first_name}
        />
      </IonInput>
      <IonInput
        className={`mb-2 ${!newItem || disabled ? componentStyles.opaqueLess : ''}`}
        data-qa={last_name_name}
        disabled={!newItem || disabled}
        name={last_name_name}
        onIonChange={e => e.detail.value != null && set_last_name(e.detail.value)}
        placeholder={`${placeholder}'s Last name`}
        value={last_name}
      >
        <IonImg
          className="ion-padding-start"
          src={icons.last_name}
        />
      </IonInput>
    </div>

    {components?.email ||
      <IonInput
        className={'mb-2'}
        data-qa={email_name}
        disabled={!newItem || disabled}
        name={email_name}
        onIonChange={e => e.detail.value != null && set_email(e.detail.value)}
        placeholder={`${placeholder}'s email`}
        value={email}
      >
        <IonIcon
          className="ion-padding-start"
          icon={mailSharp}
          slot="start"
        />
      </IonInput>
    }

    {props.children}
  </>)
}
