import React from 'react'

import { IonHeader, IonGrid, IonToolbar, IonButtons, IonTitle } from '@ionic/react'
import BackButton from '../BackButton'

import styles from './Header.module.scss'

const Header = (props) => {
  const { headerStyle, title, actions, actionStyle } = props

  const headerClass = `
  ${!actions ? 'no_button' : 'with_button'} 
  ${headerStyle === 'center' ? styles.header_center : styles.header_left}
  ${(actionStyle === 'left' && headerStyle === 'center') ? styles.header_action_left : ''}`

  return (
    <>
    <IonHeader className={`${headerClass}`}>
      <IonGrid fixed>
        <IonToolbar>
          <IonTitle className={`Heading_1 text_primary ${styles.pl_md_0} noMarginTop`}>{title}</IonTitle>
          {actionStyle === 'left' &&
          <IonButtons className="no_mobile"
                      slot="start">
            <BackButton/>
          </IonButtons>
          }
          {actionStyle === 'right' &&
            <IonButtons slot="end">
              {actions}
            </IonButtons>
          }
        </IonToolbar>
      </IonGrid>
    </IonHeader>
    </>
  )
}

export default Header
