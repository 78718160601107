import { alertCircleSharp, checkmarkCircleSharp, refreshCircleSharp } from 'ionicons/icons'

import content from 'util/content'

const KitTypes = () => ({
  'whole genome sequencing': {
    includes: [
      {
        title: 'Breed + Health DNA Test',
        icon: '/assets/icon/reports-health-markers.svg'
      },
      {
        title: 'Dental Health Test',
        icon: '/assets/icon/reports-dental.svg'
      }
      // missing raw data here
    ]
  },
  'breed + health dna test': {
    title: 'Breed + Health DNA Test',
    includes: [
      {
        title: 'Breed + Health DNA Test',
        icon: '/assets/icon/reports-health-markers.svg'
      }
    ]
  },
  breed: {
    includes: [
      {
        title: 'Breed',
        icon: '/assets/icon/reports-breeds-group.svg'
      }
    ]
  },
  'dental health': {
    includes: [
      {
        title: 'Dental Health',
        icon: '/assets/icon/reports-dental.svg'
      }
    ]
  }
})

const KitStatus = () => [
  {
    text: content.get('KIT.STATUS.REGISTERED.TEXT'),
    simpleText: content.get('KIT.STATUS.REGISTERED.SIMPLE_TEXT'),
    icon: checkmarkCircleSharp,
    frontStatus: 'registered',
    backStatus: [
      'creating',
      'shipping',
      'registered',
      'activated'
    ],
    statusDetail: content.get('KIT.STATUS.REGISTERED.DESCRIPTION')
  },
  {
    text: content.get('KIT.STATUS.RECEIVED.TEXT'),
    simpleText: content.get('KIT.STATUS.RECEIVED.SIMPLE_TEXT'),
    icon: checkmarkCircleSharp,
    frontStatus: 'received',
    backStatus: [
      'receiving'
    ],
    statusDetail: content.get('KIT.STATUS.RECEIVED.DESCRIPTION')
  },
  {
    text: content.get('KIT.STATUS.PROCESSING.TEXT'),
    simpleText: content.get('KIT.STATUS.PROCESSING.SIMPLE_TEXT'),
    icon: refreshCircleSharp,
    frontStatus: 'processing',
    backStatus: [
      'extraction',
      'preparation',
      'sentToSequencing',
      'sequencing',
      'analysis',
      'in progress',
      'report',
      'reported'
    ],
    statusDetail: content.get('KIT.STATUS.PROCESSING.DESCRIPTION')
  },
  {
    text: content.get('KIT.STATUS.COMPLETED.TEXT'),
    simpleText: content.get('KIT.STATUS.COMPLETED.SIMPLE_TEXT'),
    icon: checkmarkCircleSharp,
    frontStatus: 'completed',
    backStatus: [
      'completed',
      'released'
    ],
    statusDetail: content.get('KIT.STATUS.COMPLETED.DESCRIPTION')
  },
  {
    text: content.get('KIT.STATUS.FAILED.TEXT'),
    simpleText: content.get('KIT.STATUS.FAILED.SIMPLE_TEXT'),
    icon: alertCircleSharp,
    frontStatus: 'failed',
    backStatus: [
      'failed',
      'fail'
    ],
    statusDetail: content.get('KIT.STATUS.FAILED.DESCRIPTION')
  }
]

const adoptedOptions = [
  {
    id: 'street',
    label: 'Off the street'
  },
  {
    id: 'shelter',
    label: 'Local shelter'
  },
  {
    id: 'friendFamily',
    label: 'Friend or family'
  },
  {
    id: 'petShop',
    label: 'Pet shop'
  },
  {
    id: 'breeder',
    label: 'Breeder'
  }
]

export { KitStatus }
export { KitTypes }
export { adoptedOptions }
