import React from 'react'
import { IonRow, IonCol, IonButton, IonImg, IonIcon } from '@ionic/react'

import styles from './SuccessWidget.module.scss'

interface IProps {
  heading: string
  message: string
  image?: string
  childrenContent?: any
  rightCTA?: {
    text: string
    url: string
    show: boolean
    external?: boolean
    target?: string
    icon?: string
  }
  leftCTA?: {
    text: string
    url: string
    show: boolean
    external?: boolean
    target?: string
    icon?: string
  }
}

const SuccessWidget = (props: IProps) => {
  const { heading, message, childrenContent } = props

  const image = props.image ?? '/assets/images/basepaws-kit-success.png'

  const leftCTA = props.leftCTA ?? {
    text: 'View My Cats',
    url: '/cats',
    show: true
  }

  const rightCTA = props.rightCTA ?? {
    text: 'Go to My Patients',
    url: '/patients',
    show: true
  }

  // use href or routerLink depending on external and target
  const leftPropsCTA = (leftCTA.external && leftCTA.target) ? { href: leftCTA.url, target: leftCTA.target } : { routerLink: leftCTA.url }
  const rightPropsCTA = (rightCTA.external && rightCTA.target) ? { href: rightCTA.url, target: rightCTA.target } : { routerLink: rightCTA.url }

  return <IonRow className={`${styles.empty_section} ion-justify-content-center`}>
    <IonCol className="ion-text-center"
      size-md="8">
      {image !== '' && <IonImg src={image} />}
      <h1 className="Heading_1 text_cyan">{heading}</h1>
      <p className="Lead_1 text_dark_grey">{message}</p>
      {(childrenContent) && childrenContent}
      {(leftCTA.show || rightCTA.show) &&
        <div className="d-flex ion-align-items-center ion-justify-content-center">
          {leftCTA.show &&
            <IonButton
              className="ion-margin-end ion-margin-start big_button"
              color="primary"
              mode="md"
              shape="round"
              {...leftPropsCTA}
            >
              {leftCTA.icon && <IonIcon icon={leftCTA.icon}/> }
              {leftCTA.text}
            </IonButton>
          }
          {rightCTA.show &&
            <IonButton
              className="ion-margin-end ion-margin-start big_button"
              color="primary"
              fill="outline"
              mode="md"
              shape="round"
              {...rightPropsCTA}
            >
              {rightCTA.icon && <IonIcon icon={rightCTA.icon}/> }
              {rightCTA.text}
            </IonButton>
          }
        </div>
      }
    </IonCol>
  </IonRow>
}

export default SuccessWidget
