import React from 'react'
import { IonButton, IonIcon, IonAvatar, IonRow, IonCol, IonList } from '@ionic/react'
import { arrowForwardSharp } from 'ionicons/icons'
import styles from './ProfileMyCatsCard.module.scss'
import services from 'util/services'
import { KitStatus } from '../OrderListItem/constants'
import content from 'util/content'

const ProfileCatCard = (props) => {
  const { pet } = props
  const kitStatus = KitStatus()
  const kits = pet.reports

  const petImage = pet?.images.find((i) => i.hit === 0)

  return <IonList key={pet.id}>
    <li className={styles.my_profile_welcome_card_mainitem}>
      <IonRow className="ion-justify-content-between ion-align-items-center">
        <IonCol size="2" size-md="3" size-lg="2" className="ion-text-center">
          <IonAvatar>
            <img src={services.thumb(petImage)} alt="Cat avatar"/>
          </IonAvatar>
        </IonCol>
        <IonCol size="7" size-md="5" size-lg="6">
          <h4 className="Heading_4">{pet.name}</h4>
        </IonCol>
        <IonCol size="3" size-md="4" size-lg="4" className="ion-text-end">
          <IonButton mode="md" shape="round" color="primary" fill="clear" routerLink={`/cats/${pet.handle}`}>
            <IonIcon slot="end" icon={arrowForwardSharp} />{content.get('dashboard.my_cats.cta_viewcat')}
          </IonButton>
        </IonCol>
      </IonRow>
    </li>
    { kits.map((kit, index) => {
      let lastStatus = null
      let realStatus = null

      kit.history.map((history, i) => {
        const isLast = (!history[i + 1]?.time) && (history.time != null && history.time !== undefined)
        if (isLast) {
          lastStatus = i
        }
        realStatus = kitStatus.find((el) => el.backStatus.includes(kit.history[lastStatus].status))
        return true
      })

      return <li key={index} className={styles.my_profile_welcome_card_secondaryitem}>
      <IonRow className={`ion-justify-content-between ion-align-items-center ${styles.item_state} ${styles[`${realStatus.frontStatus}`]}`}>
        <IonCol size="2" size-md="3" size-lg="2" className="ion-text-center">
          <IonIcon icon={realStatus.icon} className={`${styles.item_state_icon}`}></IonIcon>
        </IonCol>
        <IonCol size="6" size-md="5" size-lg="6">
          <h4 className="Body_1">{kit.productName}</h4>
        </IonCol>
        <IonCol size="4" size-md="4" size-lg="4" className="ion-text-end">
          <span className={`tags ${realStatus.frontStatus}`}>{realStatus.simpleText}</span>
        </IonCol>
      </IonRow>
    </li>
    }) }
  </IonList>
}

export default ProfileCatCard
