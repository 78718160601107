import React from 'react'

import styles from './NavbarWorkspaces.module.scss'
import { useSelector } from 'react-redux'

const OrganizationData = (props) => {
  const { organizationActive, className } = props

  const user = useSelector(state => state.userInReducer.user)
  return (
    <div className={`${styles.org_data} ${className}`}>
      <h6 className='Heading_5 text_primary m-0'>{organizationActive?.company}</h6>
      <p className='Body_small_regular text_light m-0'>{user?.email}</p>
    </div>
  )
}

export default OrganizationData
