import React, { useEffect, useState, forwardRef } from 'react'
import styles from './CatFilters.module.scss'
import { IonCol, IonCard, IonRow, IonGrid, IonButton, IonIcon, IonItem, IonLabel, IonCheckbox } from '@ionic/react'
import { calendarClearSharp, close, refreshSharp } from 'ionicons/icons'
import DateInputDesktop from 'components/basepaws/general/DateInputDesktop/'

import SortByButton from './SortByButton'
import SearchBar from './SearchBar'
import FilterByButton from './FilterByButton'

const SearchFilterSort = (props) => {
  const {
    enabledFeatures,
    // sort props
    defaultOrder,
    sortingOptions,
    // search props
    searchIn,
    searchPlaceholder,
    fieldsToSearch,
    setResultFunction,
    align,
    rightElement,
    resetFunction,
    showResetButton,
    statusSelected,
    setStatusSelected,
    options
  } = props

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [missingEmail, setMissingEmail] = useState(false)

  const [filteredResults, setFilteredResults] = useState([])
  const [searchedResults, setSearchedResults] = useState([])

  // eslint-disable-next-line react/display-name
  const CustomInputButton = forwardRef(({ value, onClick }, ref) => (
    <IonButton
      className="example-custom-input"
      color="dark"
      fill="outline"
      mode="md"
      onClick={onClick}
      ref={ref}
      shape="round"
    >
      {value || 'Filter Date'}
      <IonIcon
        icon={calendarClearSharp}
        slot="start"
      />
    </IonButton>
  ))

  const pass = (input) => input

  const searchByField = (field, object) => {
    const searchRegExp = new RegExp(searchText, 'i')
    let result = false

    if (field.includes('.')) { // search on subfield
      let subObject = object

      field.split('.').forEach((field) => {
        subObject = (subObject || {})[field]
      })
      result = searchRegExp.test(subObject)
    } else { // search on first level field
      result = searchRegExp.test(object[field])
    }

    return result
  }

  useEffect(() => {
    let repFiltered = []
    if (searchIn) {
      repFiltered = searchIn.filter(report => {
        return fieldsToSearch.map((field) => searchByField(field, report)).some(pass)
      })
    }
    if (startDate && endDate) {
      const fieldDate = enabledFeatures.fieldToDate || 'date'
      repFiltered = repFiltered.filter(report => {
        const date = new Date(Date.parse(report[fieldDate], 'dd D MMM, H:mm A'))
        return date >= startDate && date <= endDate
      })
    }

    if (missingEmail) {
      repFiltered = repFiltered.filter(report => {
        return !(report.pet?.owner?.email)
      })
    }

    setSearchedResults([...repFiltered])
  }, [startDate, endDate, searchText, missingEmail, searchIn])

  useEffect(() => {
    if (filteredResults.length > 0 && searchedResults.length > 0) {
      // TODO: this is comparing full objects, may need to update to compare solely IDs
      const tmp = filteredResults.filter(element => searchedResults.includes(element))
      setResultFunction(tmp)
    } else {
      (filteredResults.length > 0 && (!searchText || searchText.length === 0)) ? setResultFunction(filteredResults) : setResultFunction(searchedResults)
    }
  }, [filteredResults, searchedResults])

  return (
    <IonCol size="12">
      <IonCard className={styles.card_cat_filters}>
        <IonGrid fixed>
          <IonRow className={`ion-align-items-center ion-justify-content-${align ?? 'between'}`}>
            {enabledFeatures.filterSelector &&
              <div className={`${styles.d_md_flex} ion-align-items-center`}>
                <p className='Body_regular text_light mr-2 ml-2'>Filter by:</p>
                <FilterByButton
                  buttons={enabledFeatures?.buttons}
                  isRequisitionForm={enabledFeatures.isRequisitionForm}
                  options={options}
                  organization={enabledFeatures.organization}
                  patients={searchIn}
                  setPatients={setFilteredResults}
                  setStatusSelected={setStatusSelected}
                  statusSelected={statusSelected}
                />
                {(enabledFeatures.missingEmail || showResetButton) &&
                  <div className='d-flex ion-align-items-center'>
                    {enabledFeatures.missingEmail &&
                      <IonItem
                        className={`${styles.check_filter_button} check_filter_button mx-1`}
                        lines='none'
                      >
                        <IonCheckbox
                          checked={missingEmail}
                          color="primary"
                          id="deceased"
                          name="deceased"
                          onIonChange={(event) => {
                            setMissingEmail(event.detail.checked)
                          }}
                          slot="start"
                        />
                        <IonLabel className="Body_small_regular text_grey m-0">
                          Missing Email
                        </IonLabel>
                      </IonItem>
                    }
                    {((searchText && searchText !== '') ||
                      startDate ||
                      endDate ||
                      missingEmail ||
                      showResetButton) &&
                      <IonButton
                        class={` ${styles.filter_button} ${styles.clear} default_button icon-start`}
                        color='dark'
                        fill='clear'
                        onClick={() => {
                          setSearchText(null)
                          setStartDate(null)
                          setEndDate(null)
                          setMissingEmail(false)
                          resetFunction()
                        }}
                        shape='round'
                      >
                        <IonIcon icon={refreshSharp} />
                        Reset
                      </IonButton>
                    }
                  </div>
                }
              </div>
            }
            {enabledFeatures.search &&
              <SearchBar
                placeholder={searchPlaceholder}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            }
            {enabledFeatures.filterByDate &&
              <DateInputDesktop
                customInput={<CustomInputButton />}
                dateOptions={{ startDate, endDate, setStartDate, setEndDate }}
                format="MM/dd/yyyy"
                label="Filter by Date"
                name="filter_by_date"
                placeholder="MM/DD/YYYY - MM/DD/YYYY"
                portal="datepicker-portal"
                selectRange={true}
              />
            }
            {enabledFeatures.showDiscardButton &&
              <IonButton
                color="text_sad_purple"
                fill="clear"
                mode="md"
                onClick={() => {
                  setStartDate(null)
                  setEndDate(null)
                  setSearchText('')
                }}
                shape="round"
              >
                <IonIcon icon={close}
                         slot="start" />
                Discard filter
              </IonButton>
            }

            {enabledFeatures.sort &&
              <SortByButton
                defaultOrder={defaultOrder}
                options={sortingOptions}
                setResultFunction={setResultFunction}
                sortIn={searchIn}
              />
            }

            {(rightElement && ((startDate && endDate) || searchText)) && rightElement}
          </IonRow>
        </IonGrid>
      </IonCard>
    </IonCol>
  )
}

export default SearchFilterSort
