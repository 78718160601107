import React, { useRef } from 'react'
import DatePicker from 'react-datepicker'
import { IonIcon, IonInput } from '@ionic/react'
import { calendarClearSharp } from 'ionicons/icons'
import 'react-datepicker/dist/react-datepicker.css'
import './DateInputDesktop.scss'

const IonicInput = props => {
  const { onChange, name } = props

  return (
    <IonInput
      className={'input_w_300 mb-2'}
      data-qa={name}
      onIonChange={e => {
        onChange(e)
      }}
      {...props}
    >
      <IonIcon
        className="ion-padding-start"
        icon={calendarClearSharp}
        slot="start" />
    </IonInput>
  )
}

const DateInputDesktop = (props) => {
  const { selectRange, portal, format, placeholder, onChange } = props
  const { startDate, setStartDate, endDate, setEndDate } = props.dateOptions

  const datePickerRef = useRef()

  const onChangeLocal = (dates) => {
    if (selectRange) {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)
    } else {
      const start = dates
      setStartDate(start)
    }
  }

  return (
    <DatePicker
      customInput={
        <IonicInput onChange={onChangeLocal} />
      }
      endDate={endDate}
      format={format}
      onChange={(date) => {
        onChangeLocal(date)
        onChange(date)
      }}
      onKeyDown={(e) => {
        if (e.key === 'Tab') {
          datePickerRef.current.setOpen(false)
        }
      }}
      placeholderText={placeholder}
      portalId={portal}
      ref={datePickerRef}
      selected={startDate}
      selectsRange={selectRange || false}
      startDate={startDate}
      strictParsing={true}
      {...props}
    />
  )
}
export default DateInputDesktop
