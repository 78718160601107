import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IonGrid, useIonViewWillEnter } from '@ionic/react'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'
import NewForm, {
  Button,
  Message,
  Radio,
  Submit,
  Text
} from 'components/basepaws/form'
import Toaster from 'components/basepaws/utils/Toaster'
import services, { servicesNew } from 'util/services'
import content from 'util/content'

import styles from './AccountNotification.module.scss'

const AccountNotification = () => {
  const user = useSelector((state) => state.userInReducer.user)
  const history = useHistory()
  const [toast, setToast] = useState(false)
  const [data, setData] = useState({ email_type: '', email_address: '' })

  const load = async () => {
    const userLocal = await services.user()
    if (!userLocal || !userLocal.id) history.push('/login')

    let email = userLocal.email
    if (email.includes('+')) email = email.replace('+', '%2B')

    const exists = await servicesNew.get(`/notification?email=${email}`)
    if (exists.length) {
      const temp = {
        ...data,
        email_type: 'new_email',
        email_address: exists[0].alternate_email
      }
      setData(temp)
    } else {
      const temp = { ...data, email_type: 'account_email' }
      setData(temp)
    }
  }

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
  })

  const onSubmit = async (e) => {
    if (e.email_type === 'new_email') {
      await servicesNew.put('/notification/', {
        email: user.email,
        alternate_email: e.email_address
      })
    } else {
      let email = user.email
      if (email.includes('+')) email = email.replace('+', '%2B')
      const notificationUser = await servicesNew.get(
        `/notification?email=${email}`
      )
      if (notificationUser.length) {
        await servicesNew.delete(`/notification/${notificationUser[0].id}`)
      }
    }
    setToast(true)
  }

  const form = (
    <NewForm editable={true}
             initialValues={data}
             styles={styles}>
      {({ values }) => (
        <IonGrid className={`${styles.new_form_styles} form mt-3 my-4`}
                 fixed
        >
          <Message accent
                   info
                   styles={styles}>
            {content.get('vet.notificationManager.helper')}
          </Message>
          <Radio
            bold
            editable={true}
            label={content.get('vet.notificationManager.label')}
            list={[
              {
                label: 'notification.account_email',
                value: 'account_email'
              },
              {
                label: 'notification.alternate_email',
                value: 'new_email'
              }
            ]}
            multipleValueLabel
            name="email_type"
            required
          />
          <Text
            editable={values.email_type !== 'account_email'}
            frame={true}
            label={' '}
            name={'email_address'}
            onIonChange={(e) => {
              const temp = { ...data, email_address: e.target.value }
              setData(temp)
            }}
            placeholder={'Email'}
            small={true}
          />
          <Submit content={'Cancel'}
                  disabled={false}
                  space={true}
          >
            <Button
              color="dark"
              fill="outline"
              onClick={() => onSubmit(values)}
            >
              Update Email
            </Button>
          </Submit>
        </IonGrid>
      )}
    </NewForm>
  )

  return (
    <GeneralPage
      headerStyle='center'
      title={'Manage Notifications'}
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
      user={user}
    >
      {data.email_type && form}
      <Toaster
        header="Purrrfect!"
        message="The email was updated!"
        onDidDismiss={() => setToast(false)}
        status={toast}
      />
    </GeneralPage>
  )
}

export default AccountNotification
