import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'
import { IonHeader, IonImg, IonButton, IonIcon, IonAvatar, IonPopover, isPlatform } from '@ionic/react'
import { chevronDown } from 'ionicons/icons'

import content from 'util/content'
import services from 'util/services'
import { isOrganizationUser } from 'util/isOrganizationUser'

import NavbarWorkspaces, { OrganizationData } from '../NavbarWorkspaces'
import BackButton from '../../BackButton'

import styles from './Navbar.module.scss'

const Navbar = (props) => {
  const { user, mobileBackButton } = props
  const history = useHistory()

  const [popoverState, setPopoverState] = useState({ showPopover: false, event: undefined })
  const [transition, setTransition] = useState(false)
  const [height, setHeight] = useState(null)
  const [topbarStatus, setTopbarStatus] = useState(false)

  useEffect(() => {
    setTopbarStatus(content.get('NAVBAR.TOPBAR.ACTIVE') === 'true')
  }, [])

  const [popoverParameters, setPopoverParameters] = useState({
    show: false,
    height: null,
    size: null
  })

  const isUserAnOrganizationUser = isOrganizationUser(user)

  let desktop = isPlatform('desktop') || (innerWidth > 991)

  useEffect(() => {
    desktop = isPlatform('desktop') || (innerWidth > 991)
  }, [innerWidth])

  const onToggle = () => {
    setPopoverParameters({
      ...popoverParameters,
      show: !popoverParameters.show
    })
    setPopoverState({ ...popoverState, showPopover: !popoverState.showPopover })
  }

  const onLogout = async () => {
    onClose()
    await services.logout()
    history.push('/login')
  }

  const onOpen = () => {
    setTransition(true)
    setTimeout(() => {
      setHeight(isUserAnOrganizationUser ? styles.nav_not_common_user : styles.nav_common_user)
    }, 50)
    setTimeout(() => {
      setPopoverParameters({
        ...popoverParameters,
        show: true,
        size: null
      })
      setTransition(false)
    }, 350)
  }

  const onClose = () => {
    setTransition(true)
    setTimeout(() => {
      setHeight(null)
    }, 50)
    setTimeout(() => {
      setPopoverParameters({
        ...popoverParameters,
        show: false,
        size: null
      })
      setTransition(false)
    }, 350)
  }

  const onOpenMenu = () => {
    if (transition) return
    if (popoverParameters.show) onClose()
    else { onOpen() }
  }

  const vetLinks = [
    {
      title: `${content.get('vetPatients.title')}`,
      url: '/patients'
    },
    {
      title: `${content.get('requisition_forms_log.title')}`,
      url: '/requisition-form-log'
    },
    {
      title: `${content.get('requisition_forms_log.newform.title')}`,
      url: '/forms/requisition',
      type: 'button'
    }
  ]

  const basepawsLogo = <IonImg alt="Basepaws logo"
                               src="assets/images/Basepaws_vet_logo_2023.svg" />

  return (
    <>
      {(!user || !user.id)
        ? <IonHeader className={`${styles.header_navbar} header-navbar`}>
              {topbarStatus
                ? (
                  <div className={styles.topbar}>
                    <p className={styles.topBarContent}
                       dangerouslySetInnerHTML={{ __html: content.get('navbar.topbar.text') }}/>
                    <span className={styles.topBarBtn}>
                      <IonButton
                        color='black'
                        onClick={() => setTopbarStatus(false)}
                        size='small'
                      >x</IonButton>
                    </span>
                  </div>
                  )
                : <div className={styles.spacer}/>}
          <nav className={`${styles.navbar} ${styles.navbar_expand_lg}`}>
            <a className={`mx-auto ${styles.navbar_brand}`}
               href="#">
              {basepawsLogo}
            </a>
          </nav>
        </IonHeader>
        : <IonHeader className={`${styles.header_navbar} header-navbar`}>
              {topbarStatus
                ? (
                  <div className={styles.topbar}>
                    <p className={styles.topBarContent}
                       dangerouslySetInnerHTML={{ __html: content.get('navbar.topbar.text') }}/>
                    <span className={styles.topBarBtn}>
                      <IonButton
                        color='black'
                        onClick={() => setTopbarStatus(false)}
                        size='small'
                      >x</IonButton>
                    </span>
                  </div>
                  )
                : <div className={styles.spacer}/>}
          <nav className={`${styles.navbar} ${styles.navbar_expand_lg}`}>
            <div className={styles.container}>

              <a className={`${styles.navbar_brand} ${mobileBackButton ? 'no_mobile' : ''}`}
                 href="/vet">
                {basepawsLogo}
              </a>

              {(mobileBackButton) &&
                <div className="no_desktop">
                  <BackButton />
                </div>
              }

              {/* My account button MOBILE */}
              <IonButton aria-controls="navbarSupportedContent"
                         aria-expanded="false"
                         aria-label="Toggle navigation"
                         className={`${styles.navbar_toggler} ${styles.button_popover} no_desktop`}
                         mode="md"
                         onClick={onOpenMenu}
                         shape="round"
                         slot="end">
                <IonAvatar slot="start">
                  <IonImg alt="Cat avatar"
                          src='assets/icon/navbar-user-vet.svg' />
                </IonAvatar>
                Account
                <IonIcon className={`${styles.button_popover_icon} ${popoverParameters.show ? styles.open : ''}`}
                         icon={chevronDown}
                         slot="end" />
              </IonButton>

              <div className={`${styles.backdrop} ${height ? styles.open : ''} ${transition ? styles.opening : ''}`}
                   onClick={onClose}></div>
              <div
                className={`navbar-collapse w-100 ${popoverParameters.show ? styles.show : ''} ${transition ? styles.collapsing : styles.collapse} ${height}`}
                id="navbarSupportedContent"
              >
                {!desktop && <OrganizationData
                  className='no_desktop'
                  organizationActive={user?.organization}
                />
                }
                <ul className={`${styles.navbar_nav}`}>
                  {isUserAnOrganizationUser &&
                    vetLinks.map(
                      (link, index) => {
                        return (
                          <li
                            className={`${styles.nav_item} ${link.type === 'button' ? styles.nav_item_button : 'nav_item'}`}
                            key={index}
                          >
                            {link.type !== 'button'
                              ? <NavLink
                                activeClassName="active"
                                onClick={() => {
                                  setTimeout(() => {
                                    onClose()
                                  }, 1000)
                                }}
                                to={link.url}
                              >
                                {link.title}
                              </NavLink>
                              : <IonButton
                                className="default_button"
                                color="primary"
                                fill="solid"
                                onClick={() => {
                                  setTimeout(() => {
                                    onClose()
                                  }, 1000)
                                }}
                                routerLink="/forms/requisition"
                                shape="round"
                              >
                                {link.title}
                              </IonButton>
                            }
                          </li>
                        )
                      }
                    )
                  }
                  {/* My account button DESKTOP */}
                  <li className={`${styles.nav_item} no_mobile`}>
                    <IonButton
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      className={`${styles.navbar_toggler} ${styles.button_popover} ion-padding-start`}
                      mode="md"
                      onClick={() => onToggle()}
                      shape="round"
                      slot="end"
                    >
                      <IonAvatar slot="start">
                        <IonImg alt="Cat avatar"
                                src='assets/icon/navbar-user-vet.svg' />
                      </IonAvatar>
                      Account
                      <IonIcon className={`${styles.button_popover_icon} ${popoverParameters.show ? styles.open : ''}`}
                               icon={chevronDown}
                               slot="end" />
                    </IonButton>
                    <IonPopover
                      cssClass={`header-popover ${isUserAnOrganizationUser ? '' : 'common_user'}`}
                      event={popoverState.event}
                      isOpen={popoverState.showPopover}
                      mode="md"
                      onDidDismiss={() => {
                        if (popoverState.showPopover) {
                          setPopoverState({ showPopover: false, event: undefined })
                          onToggle()
                        }
                      }}
                    >
                      <NavbarWorkspaces
                        closePopover={() => {
                          onToggle()
                        }}
                        isAdminOrVet={isUserAnOrganizationUser}
                        isDesktop={desktop}
                        onLogout={onLogout}
                        organizationActive={user.organization}
                      />
                    </IonPopover>
                  </li>

                  {/* WORKSPACES MOBILE */}
                  <li className={`${styles.workspaces_wrapper} ${styles.no_desktop} no_desktop`}>
                    <NavbarWorkspaces
                      closePopover={() => {
                        setTimeout(() => {
                          onClose()
                        }, 1000)
                      }}
                      isAdminOrVet={isUserAnOrganizationUser}
                      isDesktop={desktop}
                      onLogout={onLogout}
                      organizationActive={user.organization}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </IonHeader>
      }
    </>
  )
}

export default Navbar
