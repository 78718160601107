import React, { useContext } from 'react'
import { IonIcon } from '@ionic/react'
import { informationCircleSharp } from 'ionicons/icons'
import { FormContext } from '../Form/FormContext'
import styles_ from './Message.module.scss'

export const Message = (props) => {
  const context = useContext(FormContext)

  const {
    children,
    styles = context.styles,
    info = false,
    green = false,
    pink = false,
    accent = false,
    red = false,
    accentSecondary = false,
    className,
    m0 = false,
    mx7 = false,
    icon = informationCircleSharp
  } = props

  return (
      <div className={`${styles_.disclaimer} ${red ? styles_.red : ''} ${m0 ? styles_.m_0 : ''} ${mx7 ? styles_.mx_7 : ''} ${pink ? styles_.pink : ''} ${green ? styles_.green : ''} ${accent ? styles_.blue : ''} ${accentSecondary ? styles_.blue_secondary : ''} ${className || ''}`}>
          {info && <IonIcon src={icon}/>}
          <p className='Body_small_regular text_primary m-0'>{children}</p>
        </div>
  )
}
