import React, { useEffect, useState } from 'react'
import { IonItem, IonCheckbox, IonButton, IonIcon } from '@ionic/react'
import InputSelect2 from 'components/basepaws/utils/InputSelect2'
import styles from './CatFilters.module.scss'
import content from 'util/content'

const FilterButton = (props) => {
  const { options, valuesSelected, setValuesSelected, placeholder, icon, indexOpen, setIndexOpen, index } = props

  const [displayOptions, setDisplayOptions] = useState(false)

  useEffect(() => {
    // if this filter is open, close the other filters
    if (indexOpen !== index) {
      setDisplayOptions(false)
    }
  }, [indexOpen])

  const customValueRender = () => {
    return (
      <IonButton
        className={`default_button icon-start filter_button ${styles.filter_button} ${valuesSelected.length > 0 ? styles.active : ''}`}
        color='dark'
        fill='outline'
        onBlur={(event) => {
          // TODO Still exists an extremely unusual corner case where the user may want to close the options dropdown by
          // clicking another checkbox on the page. In this case, the dropdown won't be closed.
          const blurTriggeredByCheckbox = event.relatedTarget?.localName === 'ion-checkbox'
          if (!blurTriggeredByCheckbox) {
            setDisplayOptions(false)
          }
        }}
        onClick={() => {
          if (!displayOptions) setIndexOpen(index)
          setDisplayOptions(!displayOptions)
        }}
        shape='round'
      >
         <IonIcon
            color={ valuesSelected?.length === 0 ? 'dark' : '' }
            src={icon}
          />
        {placeholder}
      </IonButton>
    )
  }

  const customOptionRender = (metadata, option) => {
    return (
      <IonItem
        button
        className={option.counter === 0 && 'void'}
        onClick={() => {
          if (valuesSelected?.includes(option.value)) {
            setValuesSelected(valuesSelected.filter(value => value !== option.value))
          } else {
            setValuesSelected([...valuesSelected, option.value])
          }
        }}
        value={option.value}
      >
        <IonCheckbox
          checked={valuesSelected?.includes(option.value)}
          color="primary"
          id="contact"
          name="contact"
          slot="start"
        />
        {option.label ? content.get(option.label) : option.name}
        <span className={`${styles.filter_selected_number} ${styles.tag}`}>
          {option.counter}
        </span>
      </IonItem>
    )
  }

  return (
    <>
      <div className={`${styles.select_filter} select_filter`}>
        <InputSelect2
          closeOnSelect={false}
          multiple={true}
          options={options}
          placeholder={placeholder}
          printOptions={(displayOptions) ? 'always' : 'never'}
          renderOption={customOptionRender}
          renderValue={customValueRender}
          value={valuesSelected}
        />
      </div>
    </>
  )
}

export default FilterButton
