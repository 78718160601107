import React, { useState } from 'react'
import { IonRow, IonCol, IonGrid, IonCard, useIonViewWillEnter } from '@ionic/react'
import { closeSharp, lockClosed } from 'ionicons/icons'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import services from 'util/services'
import EditProfileForm from 'components/basepaws/account/EditProfileForm'
import ActionBlock from 'components/basepaws/account/ActionBlock'
import content from 'util/content'
import { DeactivateAccountModal } from './DeactivateAccountModal.js'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'
import styles from './AccountEditProfile.module.scss'

const AccountEditProfile = () => {
  const [modal, setModal] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const user = useSelector(state => state.userInReducer.user)

  const load = async () => {
    const userLocal = await services.user()
    if (!userLocal || !userLocal.id) history.push('/login')
  }

  useIonViewWillEnter(async () => {
    if (!user.id) {
      const query = location.hash
      if (query) {
        const data = query.split('/')
        const mail = data[0].substring(1)
        const code = data[1]
        if (mail && code) { await services.post('/auth/confirm', { mail: mail, code: code }) }
      }
      await load()
    }
  })

  const form = (
    <IonCard className={styles.form_card}>
      <IonRow className="ion-justify-content-center">
        <IonCol size="11"
          size-lg="8"
          size-md="8">
          <EditProfileForm user={user} />
        </IonCol>
      </IonRow>
    </IonCard>
  )

  return (
    <GeneralPage
      actionStyle='left'
      headerStyle='center'
      isNotFixed={true}
      pageClass={`${styles.cat_single}`}
      title={content.get('EDIT_PROFILE.HEADER.TITLE')}
      user={user}
    >
      <IonGrid fixed>
        {form}
        <div className="ion-margin-top">

          <ActionBlock
            item_button_href1={content.get('PROFILE.ACTION_BLOCK.CTA1.LINK')}
            item_button_onclick2={() => setModal(true)}
            item_button_text1={content.get('PROFILE.ACTION_BLOCK.CTA1.TITLE')}
            item_button_text2={content.get('PROFILE.ACTION_BLOCK.CTA2.TITLE')}
            item_disabled1={false}
            item_disabled2={false}
            item_icon1={lockClosed}
            item_icon2={closeSharp}
            item_text1="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel justo sed."
            item_text2="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel justo sed."
            item_title1={content.get('PROFILE.ACTION_BLOCK.TITLE1')}
            item_title2={content.get('PROFILE.ACTION_BLOCK.TITLE2')}
            title={content.get('PROFILE.ACTION_BLOCK.HEADER')}
          />
          <DeactivateAccountModal
            close={() => setModal(false)}
            modalState={modal}
          />
        </div>
      </IonGrid>
    </GeneralPage>
  )
}

export default AccountEditProfile
