export default [
  {
    label: 'report_card.type_dental.name',
    value: 'type_dental'
  },
  {
    label: 'report_card.type_dna_complete.name',
    value: 'type_dna_complete'
  },
  {
    label: 'report_card.type_canine_breed_and_health.name',
    value: 'type_canine_breed_and_health'
  }
]
