import { IonCheckbox, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react'
import { useField } from 'formik'
import { checkmarkCircleSharp, informationCircleSharp } from 'ionicons/icons'
import React, { useContext, useState } from 'react'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'
import { Static } from '../Form/Static'

const Other = (props) => {
  const { name, styles, index, label } = props
  const [custom, setCustom] = useState('')
  const [check, setCheck] = useState(false)
  const [field, { value = [] }, { setValue }] = useField(name)
  return (
    <IonRow key={`${name}-${index}`}>
    <IonCol
        size='12'
        size-md="6"
      >
        <div className={'input-radio-wrapper w-auto'}
             key={`${name}-${index}`}>

          <IonItem className={`${styles.input_radio} input-radio p-0`}>
            <IonCheckbox
              checked={check}
              name={name}
              onIonChange={e => {
                setCheck(e.detail?.checked)
                if (e.detail?.checked) { value.push(custom) } else { setValue(value.filter(itm => itm !== custom)) }
              }}
            />
            <IonLabel className="Body_small_regular">{label}</IonLabel>
            </IonItem>

        </div>
      </IonCol>
      {check &&
        <IonCol
        size='12'
        size-md="6">
          <IonInput
              className='mt-1'
              onIonChange={e => {
                setCustom(e.detail.value)
                const index = value.indexOf(custom)
                if (index < 0) { value.push(e.detail.value) } else { value[index] = e.detail.value }
                setValue(value)
              }}
              value={custom}
          />
        </IonCol>
      }
      </IonRow>)
}

export const CheckList = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, info, editable = context.editable, list = [], name, label, placeholder, type, clarification, multipleValueLabel } = props

  const [field, { value = [] }, { setValue }] = useField(name)

  if (!editable) {
    return (
    <Row label={label}>
        {Array.isArray(list)
          ? <>
          {value.length > 0
            ? <IonRow className="ion-no-padding">
            {(value || []).map((item, index) => (
              <IonCol
                className='ion-no-padding'
                key={`diseases-list-${index}`}
                size='12'
              >
                <Static
                  icon={checkmarkCircleSharp}
                  multiValues={index !== 0 ? multipleValueLabel : false}
                  multipleFirst={index === 0}
                >
                  {list.find((v) => v.value === item)?.label}
                </Static>
              </IonCol>
            ))}
            </IonRow>
            : <Static multipleFirst>No.</Static>
            }
            </>
          : <Static multipleFirst>
              {value === null || value === '' || value.length === 0 ? 'No.' : value}
            </Static>
            }
    </Row>
    )
  }
  if (editable) {
    return (
    <Row
      clarification={clarification}
      info={info}
      label={label}>

        {Array.isArray(list) && <IonRow>
          {(list || []).map((item, index) => (
            <IonCol
              className={item.customInput ? 'col-no-padding' : ''}
              key={`${name}-${index}`}
              size='12'
              size-md={item.customInput ? '12' : '6'}
            >
              {item.customInput
                ? <Other
                index={index}
                label={item.label}
                name={name}
                styles={styles}
                />
                : <div className={'input-radio-wrapper w-auto'}>

                <IonItem className={`${styles.input_radio} input-radio p-0`}>
                  <IonCheckbox
                    checked={value?.includes(item.value)}
                    name={name}
                    onIonChange={e => {
                      if (e.detail?.checked) { setValue([...value, item.value]) } else { setValue(value.filter(itm => itm != item.value)) }
                    }}
                    value={item.value}
                  />
                  <IonLabel className="Body_regular text_grey">{item.label}</IonLabel>
                  </IonItem>

              </div>}
            </IonCol>
          ))}
          </IonRow>}

          {!Array.isArray(list) && type === 'list' && <IonRow>
            <IonCol
              className={list.customInput ? 'col-no-padding' : ''}
              key={`${name}`}
              size='12'
              size-md={list.customInput ? '12' : '6'}
            >
              {list.customInput
                ? <Other
                index={name}
                label={list.label}
                name={name}
                styles={styles}
                />
                : <div className={'input-radio-wrapper w-auto'}>

                <IonItem className={`${styles.input_radio} input-radio p-0`}>
                  <IonCheckbox
                    checked={value === list.value}
                    name={name}
                    onIonChange={e => {
                      if (e.detail?.checked) { setValue(list.value) } else { setValue(null) }
                    }}
                    value={list.value}
                  />
                  <IonLabel className="Body_regular text_grey">{list.label}</IonLabel>
                  </IonItem>

              </div>}
            </IonCol>
          </IonRow>}

          {!Array.isArray(list) && type !== 'list' &&
          <div className={`${styles.input_radio} d-flex no-wrap ion-align-items-start`}>
            <IonCheckbox
            checked={list.value === value}
            name={name}
            onIonChange={e => {
              setValue(e.detail?.checked ? list.value : undefined)
            }}
            value={list.value}
          />
            <p className="Body_regular text_grey m-0">
              {list.label}
            </p>
          </div>
          }

      </Row>)
  }
}
