import React from 'react'
import { IonRow, IonCol, useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage.js'
import ChangePasswordForm from 'components/basepaws/account/ChangePasswordForm'
import services, { servicesNew } from 'util/services'
import content from 'util/content'

const AccountChangePassword = () => {
  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()
  const location = useLocation()

  const load = async () => {
    const userLocal = await services.user()
    if (!userLocal || !userLocal.id) history.push('/login')
  }

  useIonViewDidEnter(() => {
    document.title = ' Change Password - Vet Portal - Basepaws'
  })

  useIonViewWillEnter(async () => {
    if (!user.id) {
      const query = location.hash
      if (query) {
        const data = query.split('/')
        const mail = data[0].substring(1)
        const code = data[1]
        if (mail && code) { await servicesNew.post('/auth/confirm', { mail: mail, code: code }) }
      }
      await load()
    }
  })

  const form = (
    <IonRow className="ion-justify-content-start">
      <IonCol size="12"
              size-lg="7">
        <ChangePasswordForm />
      </IonCol>
    </IonRow>
  )

  return (
    <GeneralPage
      headerStyle='left'
      title={content.get('CHANGEPASSWORD.HEADER.TITLE')}
      user={user}>
        {form}
    </GeneralPage>
  )
}

export default AccountChangePassword
