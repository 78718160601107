import React from 'react'

import { IonRow, IonCol, IonIcon, IonImg } from '@ionic/react'

import styles from './EmptyState.module.scss'

const EmptyState = (props) => {
  const { title, text, asset, assetType, content } = props

  return (
    <>
      <IonRow className="ion-justify-content-center w-100">
        <IonCol className="ion-text-center"
          size='11'
          size-md="10">
            <span className={`${styles.empty_section_circle}`}>
              {(!assetType || assetType === 'icon') && asset && <IonIcon className={`${styles.empty_section_icon}`}
                src={asset} />
              }
              {assetType === 'image' && asset && <IonImg className={`${styles.empty_section_icon}`}
                src={asset} />
              }
            </span>
          <h1 className="Heading_2 text_sad_purple ion-text-center">
            {title}
          </h1>
          <IonRow className="ion-justify-content-center w-100">
              <IonCol className="ion-text-center"
                size="10"
                size-md="10">
                <p className="Lead text_grey mt-0">
                  {text}
                </p>
              </IonCol>
          </IonRow>
          {content}
        </IonCol>
      </IonRow>
    </>
  )
}

export default EmptyState
