import React from 'react'
import { IonIcon } from '@ionic/react'
import { refreshCircleSharp } from 'ionicons/icons'
import services from 'util/services'
import useAdmin from 'components/basepaws/utils/hooks/useAdmin'

class Content {
  // constructor () {
  // this.onClick = this.onClick.bind(this)
  // if (document.location.hash === '#key') {
  //   this.showKey()
  // } else {
  //   this.showLabel()
  // }
  // }

  // onClick (path, result) {
  //   return this.renderPrompt(path, result)
  // }

  // renderPrompt (path, result) {
  //   path = path.toLowerCase().replace(/\./gi, '/')

  //   const text = prompt(`Please enter your text ${path}`, result)
  //   services.content(path, text)
  // }

  // showKey () {
  //   this.key = true
  // }

  // showLabel () {
  //   this.key = false
  // }

  async load () {
    Object.assign(content, await services.content())
  }

  get (path, params, force = false, editableContent = useAdmin()) {
    let result
    // eslint-disable-next-line no-eval
    try { result = eval(`content.${path.toLowerCase()}`) } catch (e) {}

    this.key = (document.location.hash === '#key')

    if (force || !(this.key && editableContent)) {
      let returnVal = result
      if (result) {
        // eslint-disable-next-line no-eval
        returnVal = result.replace(/__(.*?)__/gi, (d) => eval('params.' + d.toLowerCase().replace(/_/gi, '')))
      }
      return returnVal
    }

    let result2 = result || 'no text here'
    if (params && params.each) {
      params.each(element => {
        result2 = result.replace(element.from, element.to)
      })
    }
    return (
      <>
      <span
        contentEditable={editableContent}
        id={`editContent_${path}`}
        suppressContentEditableWarning={true}
      >
      {result2}
      </span>
      { editableContent
        ? <a className="cursor_pointer button_circle"
             data-path={path}
             onClick={(e) => {
               const icon = document.getElementById(`editContent_icon_${path}`)
               icon.classList.add('updating')
               // const text = prompt(`Please enter your text for key: ${path}`, result)
               console.log(e)
               const text = document.getElementById(`editContent_${path}`).innerText
               if (text) {
                 let path = e.target.dataset.path
                 path = path.toLowerCase().replace(/\./gi, '/')
                 console.log(path, text)
                 try {
                   const edited = services.content(path, text).then(
                     (response) => {
                       console.log(response)
                     }
                   )
                   if (edited) {
                     setTimeout(() => {
                       icon.classList.remove('updating')
                     }, 500)
                   }
                 } catch (e) {
                   console.log(e)
                   setTimeout(() => {
                     icon.classList.remove('updating')
                   }, 500)
                 }
               }
             }}
             title={`Click to update the key ${path}`}
        >
          <IonIcon
            data-path={path}
            icon={refreshCircleSharp}
            id={`editContent_icon_${path}`}
            style={{ pointerEvents: 'none' }}
            title={`Click to update the key ${path}`}
          />
        </a>
        : ''
      }
    </>
    )
  }
}
const content = new Content()

export default content
