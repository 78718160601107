import React, { useEffect, useState } from 'react'

import { IonRow, IonCol, IonButton, IonList, IonIcon } from '@ionic/react'

import { Modal } from 'components/basepaws/general/Modal/Modal'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import { checkmarkSharp } from 'ionicons/icons'
import './ReportModals.module.scss'
import content from 'util/content'
import MissingEmailWarning from './MissingEmailWarning'
import PetDetails from './PetDetails'

const ModalReleaseReport = (props) => {
  const { modalState, close, currentVet, patient, report } = props

  // set modal title
  const modalTitle = 'Release Report'

  const [showModalSuccess, setShowModalSuccess] = useState(false)

  useEffect(() => {
    setShowModalSuccess(false)
  }, [currentVet])

  const modalSubTitle = (
    <p className='Body_regular text_grey m-0'>{content.get('MODAL.RELEASE_REPORT.SUBTITLE')}</p>
  )

  const modalSuccess = (
    <>
      <span className='success-section-circle mt-4'>
        <IonIcon color='success'
          src={checkmarkSharp} />
      </span>
      <h1 className='Heading_3 text_primary ion-text-center'>{content.get('MODAL.RELEASE_REPORT.MODAL_SUCCESS.TITLE')}</h1>
      <p className='Lead m-0 text_grey ion-text-center px-3'>{content.get('MODAL.RELEASE_REPORT.MODAL_SUCCESS.REPORT_SEND')}</p>
      <div className='ion-text-center'>
        <div>
          <IonButton
            className='big_button mt-4'
            color='primary'
            fill='solid'
            mode='md'
            routerLink='/patients'
            shape='round'
          >
            {content.get('MODAL.RELEASE_REPORT.MODAL_SUCCESS.BUTTON_BACK')}
          </IonButton>
        </div>
      </div>
    </>
  )

  const modalContents =
    <>
      <IonRow className="ion-justify-content-center ion-justify-content-between">
        <IonCol size="12"
          size-md="12">
          <form>
            {modalSubTitle}

            <IonList className="form mt-3">
              {!patient?.owner?.email && <MissingEmailWarning />}
              <PetDetails
                patient={patient}
                report={report}
                showClientReportLink={true}
                showVetReportLink={true}
              />
            </IonList>

            <div className="d-flex ion-justify-content-end">
              <LoaderButton
                buttonText="Release"
                className="big_button mt-3"
                color="primary"
                type="submit"
              >
              </LoaderButton>
            </div>
          </form>
          {showModalSuccess && modalSuccess}
        </IonCol>
      </IonRow>
    </>

  return (
    <Modal
      {...props}
      close={close}
      headingText={showModalSuccess ? '' : modalTitle}
      modalState={modalState}
    >
      {modalContents}
    </Modal>
  )
}

export default ModalReleaseReport
