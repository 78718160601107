export default {
  cat: [
    {
      label: 'Periodontal disease',
      value: 'periodontal disease'
    },
    {
      label: 'Diabetes Type II',
      value: 'diabetes type ii'
    },
    {
      label: 'Tooth resorption',
      value: 'tooth resorption'
    },
    {
      label: 'GI Lymphoma',
      value: 'gi lymphoma'
    },
    {
      label: 'Stomatitis',
      value: 'stomatitis'
    },
    {
      label: 'IBD',
      value: 'ibd'
    },
    {
      label: 'Chronic Kidney Disease',
      value: 'chronic kidney disease'
    },
    {
      label: 'Food Allergic Dermatitis',
      value: 'food allergic dermatitis'
    },
    {
      label: 'Diabetes Type I',
      value: 'diabetes type i'
    },
    {
      label: 'Atopic Dermatitis',
      value: 'atopic dermatitis'
    },
    {
      label: 'Other',
      value: 'other',
      customInput: true
    }
  ],
  dog: [
    {
      label: 'Periodontal disease',
      value: 'periodontal disease'
    },
    {
      label: 'Chronic Kidney Disease',
      value: 'chronic kidney disease'
    },
    {
      label: 'Diabetes',
      value: 'diabetes'
    },
    {
      label: 'GI lymphoma',
      value: 'gi lymphoma'
    },
    {
      label: 'IBD',
      value: 'ibd'
    },
    {
      label: 'Food Allergic Dermatitis',
      value: 'food allergic fermatitis'
    },
    {
      label: 'Atopic Dermatitis',
      value: 'atopic dermatitis'
    },
    {
      label: 'Other',
      value: 'other',
      customInput: true
    }]
}
