import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'formik'
import { isEqual } from 'lodash'

const usePrevious = value => {
  const ref = useRef()
  useEffect(() => { ref.current = value })
  return ref.current
}
let timer = null
const AutoSave = ({ formik: { values }, onSave, render }) => {
  const previousValues = usePrevious(values)
  const [saving, setSaving] = useState(false)

  function callback (value) {
    setSaving(false)
    return value
  }

  function changed () {
    if (previousValues && Object.keys(previousValues).length && !isEqual(previousValues, values)) {
      clearTimeout(timer)
      timer = setTimeout(() => save(), 500)
    }
  }

  function save () {
    if (previousValues && Object.keys(previousValues).length && !isEqual(previousValues, values)) {
      setSaving(true)
      onSave(values).then(callback, callback)
    }
  }

  useEffect(() => { changed() }, [values])

  return render ? render() : <></>
}

export default connect(AutoSave)
