import React from 'react'
import { IonButton } from '@ionic/react'

const ResourceItem = (props) => {
  const { title, description, buttonText, className, href } = props

  return (
    <div className={`d-flex ion-justify-content-between my-3 ${className}`}>
      <div className='mr-4'>
        <h3 className='Heading_5 text_primary m-0'>{title}</h3>
        <p className='Body_small_regular text_grey m-0'>{description}</p>
      </div>
      <IonButton
        className='default_button'
        color='primary'
        fill='outline'
        href={href}
        mode='md'
        shape='round'
        target='_blank'>
        {buttonText}
      </IonButton>
    </div>
  )
}

export default ResourceItem
