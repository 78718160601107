import React, { IonIcon, IonImg } from '@ionic/react'
import { useContext } from 'react'
import { FormContext } from './FormContext'

import componentStyles from './Static.module.scss'

export const Static = (props) => {
  const context = useContext(FormContext)
  var { icon, styles = context.styles, editable = context.editable, icon, name, label, placeholder, multiValues, multipleFirst } = props

  return (
    <div className={`${styles.precharged_data} ${multiValues && styles.multiple} ${multipleFirst && styles.multiple_first} d-flex ion-align-items-center`}>
     {icon && <>
      {
      (typeof (icon) === 'string') && icon.endsWith('.svg')
        ? <div>
        <IonImg
            className={`mr-2 ${icon.endsWith('vet.svg') ? componentStyles.customIconColor : ''}`}
            src={icon}
      />
        </div>
        : <IonIcon
            className="mr-2"
            icon={icon}
            slot="start"
          />}
      </>
      }
      <p className="Body_regular text_dark my-3">
        {props.children}
      </p>
    </div>
  )
}
