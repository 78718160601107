import React from 'react'
import { IonContent, IonGrid, IonPage, useIonViewDidEnter } from '@ionic/react'
import Navbar from '../Navbar/Navbar'
import Header from '../Header'
import Footer from '../Footer'

const GeneralPage = (props) => {
  const {
    user,
    header,
    actionStyle,
    headerStyle,
    title,
    actions,
    isNotFixed,
    children,
    pageClass,
    scrollCallback,
    scrollEvents
  } = props

  const scrollToTop = () => {
    const size = document.getElementsByTagName('ion-header').length
    for (let index = 0; index < size; index++) {
      document.getElementsByTagName('ion-header')[index].scrollIntoView({
        block: 'start',
        behavior: 'instant'
      })
    }
  }

  useIonViewDidEnter(() => {
    scrollToTop()
  })

  return (
    <IonPage
      className={pageClass}
    >
      <Navbar
        mobileBackButton={actionStyle === 'left'}
        user={user}
      />

      <IonContent
        fullscreen
        id="content"
        onIonScroll={ scrollCallback }
        scrollEvents={ scrollEvents }
      >
        {header !== 'none' &&
          <Header
            actionStyle={actionStyle}
            actions={actions}
            headerStyle={headerStyle}
            title={title}
          />
        }
        <IonGrid fixed={!isNotFixed}>
          <div className='content'>
            {children}
          </div>
        </IonGrid>

        <Footer/>

      </IonContent>
    </IonPage>
  )
}

export default GeneralPage
