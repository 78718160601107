import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonButton, IonListHeader } from '@ionic/react'
import { closeCircle, eye, eyeOff } from 'ionicons/icons'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import content from 'util/content'
import styles from './LoginForm.module.scss'

const LoginForm = () => {
  const [passwordFieldType, setPasswordFieldType] = useState({
    passwordType: 'password',
    passwordIcon: eye
  })

  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    setLoginFailed(false)
    try {
      const user = await services.login(values)
      if (user.id) setSubmitting(false)
      history.push('/patients')
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      setLoginFailed(true)
    }
  }

  const [isLoginFailed, setLoginFailed] = useState(false)

  const changeType = () => {
    const currentType = passwordFieldType.passwordType
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      passwordType: nextType,
      passwordIcon: icon
    })
  }

  const registerSchema = Yup.object().shape()
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        email: '',
        password: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
      validationSchema={registerSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <IonRow className="ion-justify-content-center mt-4">
            <IonCol size="12">
              <IonList className="form p-0">
                <IonItem
                  className="item-no-padding"
                  lines="none"
                >
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Body_bold text_primary">{content.get('LOGIN.FIELDS.EMAIL')}</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem
                  className="item-no-padding"
                  lines="none"
                >
                  <IonInput
                    className={isLoginFailed === true ? 'has-error' : null}
                    name="email"
                    onIonChange={handleChange}
                    onIonInput={handleChange}
                    placeholder={content.get('LOGIN.FIELDS.EMAIL_PLACEHOLDER')}
                    type="email"
                    value={values.email}
                  >
                  </IonInput>
                      {errors.born_on && touched.born_on
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              color="danger"
                              icon={closeCircle}
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                <IonItem
                  className="item-no-padding"
                  lines="none"
                >
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Body_bold text_primary">{content.get('LOGIN.FIELDS.PASSWORD')}</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem
                  className="item-no-padding"
                  lines="none"
                >
                  <IonInput
                    className={`input-w-icon ${isLoginFailed === true ? 'has-error' : null}`}
                    clearOnEdit={false}
                    name="password"
                    onIonChange={handleChange}
                    onIonInput={handleChange}
                    placeholder={content.get('LOGIN.FIELDS.PASSWORD_PLACEHOLDER')}
                    type={passwordFieldType.passwordType}
                    value={values.password}
                  >
                    <IonButton
                      fill="clear"
                      mode="md"
                      onClick={() => changeType()}
                      slot="end"
                      tabindex="-1"
                    >
                      <IonIcon
                        color="medium"
                        icon={passwordFieldType.passwordIcon}
                        slot="icon-only"
                      >
                      </IonIcon>
                    </IonButton>
                  </IonInput>
                </IonItem>
                {errors.currentPassword && touched.currentPassword
                  ? (
                  <IonItem className="input-error-message"
                           lines='none'>
                    <div className="input-error-label">
                      <IonIcon
                        color="danger"
                        icon={closeCircle}
                        slot="start"
                      />
                      {errors.born_on}
                    </div>
                  </IonItem>
                    )
                  : null}
                  {isLoginFailed === true &&
                    <IonItem className="item-no-padding input-error-message"
                             lines='none'>
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon
                              color="danger"
                              icon={closeCircle}
                              slot="start"
                            />
                            {content.get('LOGIN.FIELDS.ERROR')}
                          </div>
                        </IonCol>
                      </IonRow>
                  </IonItem>
                  }
              </IonList>
            </IonCol>
          </IonRow>
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              buttonText={content.get('LOGIN.FIELDS.SUBMIT_TEXT')}
              className="big_button loader_button mt-3"
              color="primary"
              disabled={isSubmitting || values.password === '' || values.email === ''}
              isSubmitting={isSubmitting}
              type="submit"
            ></LoaderButton>
          </div>
          <p className="ion-text-center Body_regular">
            {content.get('LOGIN.FORGOT_PASSWORD_TEXT')}
            <NavLink
              className="ml-2 Body_bold"
              to="/recoverPasswordEmail"
            >
              {content.get('LOGIN.FORGOT_PASSWORD_CTA')}
            </NavLink>
          </p>
          <p className="ion-text-center Body_regular">
            {content.get('LOGIN.LEGAL')}
            <a
              className="ml-2 Body_bold"
              href={content.get('footer.externals.link_url2')}
            >
              Terms of Service
            </a>
            {' and'}
            <a
              className="ml-2 Body_bold"
              href={content.get('footer.externals.link_url3')}
            >
              Privacy Policy
            </a>.
          </p>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
