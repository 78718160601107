import React from 'react'
import { useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import services from 'util/services'
import content from 'util/content'

import RequisitionFormLogList from './RequisitionFormLogList'

const RequisitionFormLog = () => {
  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  useIonViewDidEnter(() => {
    document.title = 'Req. Logs - Vet Portal - Basepaws'
  })

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
  })

  return (
    <GeneralPage
      headerStyle='left'
      isNotFixed={true}
      pageClass="page-no-margin"
      title={content.get('requisition_forms_log.title')}
      user={user}
    >
      <RequisitionFormLogList />
    </GeneralPage>
  )
}

export default RequisitionFormLog
