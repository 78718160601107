import React, { useEffect, useState } from 'react'
import FilterButton from './FilterButton'
import styles from './CatFilters.module.scss'

const FilterByButton = (props) => {
  const { buttons, patients, setPatients, statusSelected, setStatusSelected, options } = props

  useEffect(() => {
    if (patients?.length > 0) {
      buttons.options.forEach(btnOption => {
        btnOption.options = btnOption.options.map(type => {
          type.counter = patients?.reduce((acc, patient) => {
            return acc + btnOption.functionGetAmount(type.value, patient)
          }, 0)

          return type
        })
      })
    }
    if (options) {
      const statusButton = options.find(option => option.label === 'Status')
      const noMoreOptionsInSelectedStatus = statusButton?.options?.find(el => statusSelected.includes(el.name) && el.counter === 0)
      if (noMoreOptionsInSelectedStatus) {
        statusSelected.splice(statusSelected.indexOf(noMoreOptionsInSelectedStatus.name), 1)
        setStatusSelected(statusSelected)
      }
    }
  }, [patients, buttons])

  useEffect(() => {
    const result = patients?.filter(patient => {
      const activeFilters = new Array(buttons.options.length).fill(true)

      buttons.options.forEach((opt, index) => {
        if (opt.valuesSelected?.length > 0) {
          activeFilters[index] = opt.functionFilter(patient, opt.valuesSelected)
        }
      })

      return activeFilters.every((e) => e === true)
    })

    setPatients(result)
  }, [buttons.options])

  const [indexOpen, setIndexOpen] = useState()

  return (
    <>
      <div className={`d-flex ${styles.mb_sm_2}`}>
        {
          buttons?.options?.map((v, i) => {
            let toRender = null
            if (v.options?.length > 0) {
              const vOpts = v.options.filter(o => o.counter > 0)
              toRender = <FilterButton
                icon={v.icon}
                index={i}
                indexOpen={indexOpen}
                key={`filter-multiple-${i}`}
                options={vOpts}
                placeholder={v.label}
                setIndexOpen={setIndexOpen}
                setValuesSelected={v.setValueSelected}
                valuesSelected={v.valuesSelected}
              />
            }
            return toRender
          })
        }
      </div >
    </>
  )
}

export default FilterByButton
