import { withIonLifeCycle, IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonButton } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import React from 'react'

import { Formik } from 'formik'
import * as Yup from 'yup'

import services from 'util/services'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'

const registerSchema = Yup.object().shape({
  email: Yup.string().email('Please input a valid email').required('Please input your email'),
  first_name: Yup.string().required('Please input a name'),
  last_name: Yup.string().required('Please input a last name'),
  phone: Yup.string().required('Please input a valid phone number'),
  org_name: Yup.string().required('Please input the organization name'),
  org_address: Yup.string().required('Please input the organization address'),
  org_phone: Yup.string().required('Please input the organization phone')
})

class PointContactRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      postError: false,
      postLoading: false
    }
  }

  async onSubmit (values, setSubmitting) {
    this.setState({
      postLoading: true
    })
    try {
      const organization = {
        name: values.org_name,
        phone: values.phone,
        website: values.org_url,
        address: {
          address: values.org_address
        }
      }
      await services.post('/organization', organization)
      this.props.history.push('/organization/thanks')
    } catch (err) {
      this.setState({
        postError: true
      })
    }
    this.setState({
      postLoading: false
    })
    setSubmitting(false)
  }

  render () {
    const error =
      <div className="alert-error-message">
        <IonLabel className="subtitle-s2"><IonIcon icon={closeCircle} color="danger" slot="start" className="ion-margin-end"/>Form not submitted</IonLabel>
        <p>We are having problems on our side and we were unable to submit your request. Please, try again.</p>
      </div>

    const form = <Formik
                    initialValues={{
                      email: '',
                      first_name: '',
                      last_name: '',
                      phone: '',
                      org_name: '',
                      org_address: '',
                      org_phone: '',
                      org_url: ''
                    }}
                    validationSchema={registerSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      this.onSubmit(values, setSubmitting)
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <IonList className="form ion-margin-top">
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="email">E-mail</IonLabel>
                            <IonInput
                              id="email"
                              type="email"
                              name="email"
                              placeholder="Enter your e-mail address"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.email}
                              className={errors.email && touched.email ? 'has-error' : null}
                              />
                              {errors.email && touched.email
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.email}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="first_name">First Name</IonLabel>
                            <IonInput
                              id="first_name"
                              type="text"
                              name="first_name"
                              placeholder="Enter your first name"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.first_name}
                              className={errors.first_name && touched.first_name ? 'has-error' : null}
                              />
                              {errors.first_name && touched.first_name
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.first_name}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="last_name">Last Name</IonLabel>
                            <IonInput
                              id="last_name"
                              type="text"
                              name="last_name"
                              placeholder="Enter your last name"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.last_name}
                              className={errors.last_name && touched.last_name ? 'has-error' : null}
                              />
                              {errors.last_name && touched.last_name
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.last_name}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="first_name">Phone Number</IonLabel>
                            <IonInput
                              id="phone"
                              type="text"
                              name="phone"
                              placeholder="Enter your phone number"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.phone}
                              className={errors.phone && touched.phone ? 'has-error' : null}
                              />

                              {errors.phone && touched.phone
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.phone}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <div>
                            <hr />
                          </div>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="org_name">Organization Name</IonLabel>
                            <IonInput
                              id="org_name"
                              type="text"
                              name="org_name"
                              placeholder="Enter the name"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.org_name}
                              className={errors.org_name && touched.org_name ? 'has-error' : null}
                              />
                              {errors.org_name && touched.org_name
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.org_name}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="org_address">Address</IonLabel>
                            <IonInput
                              id="org_address"
                              type="text"
                              name="org_address"
                              placeholder="Enter the address"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.org_address}
                              className={errors.org_address && touched.org_address ? 'has-error' : null}
                            />
                              {errors.org_address && touched.org_address
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.org_address}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="org_phone">Phone</IonLabel>
                            <IonInput
                              id="org_phone"
                              type="text"
                              name="org_phone"
                              placeholder="Enter the phone number"
                              onIonChange={handleChange}
                              onIonBlur={handleBlur}
                              value={values.org_phone}
                              className={errors.org_phone && touched.org_phone ? 'has-error' : null}
                              />
                              {errors.org_phone && touched.org_phone
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.org_phone}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                          <IonItem lines="none" className="ion-margin-bottom">
                            <IonLabel position="stacked" htmlFor="org_url">Website</IonLabel>
                            <IonInput
                            id="org_url"
                            type="text"
                            name="org_url"
                            placeholder="Enter the website"
                            onIonChange={handleChange}
                            onIonBlur={handleBlur}
                            value={values.org_url}
                            className={errors.org_url && touched.org_url ? 'has-error' : null}
                            />
                              {errors.org_url && touched.org_url
                                ? (
                                <IonItem className="ion-margin-bottom input-error-message">
                                  <div className="input-error-label"><IonIcon icon={closeCircle} color="danger" slot="start"/>{errors.org_url}</div>
                                </IonItem>
                                  )
                                : null}
                          </IonItem>
                        </IonList>
                        <div className="ion-text-center">
                          <IonButton color="primary" shape="round" fill="solid" class="default_button" type="submit" disabled={isSubmitting}>
                            {'Let\'s start!'}
                          </IonButton>
                        </div>
                      </form>
                    )}
                  </Formik>

    return (
      <GeneralPage
        header="none"
      >
        <IonRow className="register-point-contact">
          <IonCol size-lg="3" size-md="5" size="12">
            <img src="https://cdn.shopify.com/s/files/1/0087/3798/0475/files/logo_different_face_300x300_68daa56d-0c73-4155-8cae-b3222e508d0f_300x300.png?v=1603721507" alt="Basepaws logo" width="73" height="52" className="d-inline-block align-top"/>
            <h2 className="mt-4">Submit your organization to start working with Basepaws</h2>
          </IonCol>
          <IonCol size-lg="6" size-md="8" size="12">
            <div className="card form-card">
              <IonRow className="ion-justify-content-center">
                <IonCol size-md="11" size="12">
                  {(this.state.postError) ? error : null}
                </IonCol>
              </IonRow>
                <IonRow className="ion-justify-content-center">
                  <IonCol size-md="10" size="12">
                    <h2 className="ion-margin-top">Point of Contact</h2>
                    {form}
                  </IonCol>
              </IonRow>
            </div>
          </IonCol>
        </IonRow>
      </GeneralPage>
    )
  }
}

export default (withIonLifeCycle(PointContactRegister))
