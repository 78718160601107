import React from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonIcon } from '@ionic/react'
import { addCircleSharp, chevronForwardOutline } from 'ionicons/icons'
import content from 'util/content'
import ProfileCatCard from './ProfileCatCard'
import ProfileCatCardEmpty from './ProfileCatCardEmpty'
import styles from './ProfileMyCatsCard.module.scss'
import { NavLink } from 'react-router-dom'

const ProfileMyCatsCard = (props) => {
  const { user } = props
  const emptyPets = (user?.pets?.length === 0)

  const cardContents = () => {
    if (!user || emptyPets) {
      return <ProfileCatCardEmpty />
    } else {
      return user?.pets?.filter((p) => !p.death_on).map((pet, index) => <ProfileCatCard key={index} pet={pet} />)
    }
  }

  return <IonCard className={`${styles.my_profile_welcome_card} ${emptyPets ? styles.my_profile_welcome_card_empty : ''} card`}>
    <IonCardHeader>
      <div className="d-flex ion-align-items-center ion-justify-content-between">
        <NavLink
          to="/cats"
        >
          <IonCardTitle className="Running_head_1 text_dark_grey mr-2">
            {content.get('DASHBOARD.MY_CATS.TITLE')}
          </IonCardTitle>
          <IonIcon slot="end" icon={chevronForwardOutline} />
        </NavLink>
        { !emptyPets &&
        <IonButton
          mode="md"
          slot="end"
          shape="round"
          color="primary"
          fill="clear"
          // routerLink={content.get('DASHBOARD.MY_CATS.CTA_ADDCAT')}
          routerLink={content.get('DASHBOARD.MY_CATS.CTA_ADDCAT_LINK')}
          className="default_button"
          >
            <IonIcon slot="end" icon={addCircleSharp} />
            {content.get('DASHBOARD.MY_CATS.CTA_ADDCAT_TEXT')}
          </IonButton>
        }
      </div>
      { !emptyPets &&
      <div className={`${styles.message} alert_message bg_grey`}>
        <p>{content.get('DASHBOARD.MY_CATS.DESCRIPTION')}</p>
      </div>
      }
    </IonCardHeader>
    <IonCardContent>
        { cardContents() }
    </IonCardContent>
    { emptyPets &&
      <>
        <div className={styles.card_footer}>
          <IonButton
            mode="md"
            slot="end"
            shape="round"
            color="primary"
            fill="solid"
            routerLink={content.get('DASHBOARD.MY_CATS.CTA_ADDCAT_LINK')}
            className="mr-2 default_button"
            >
              {content.get('DASHBOARD.MY_CATS.CTA_ADDFIRSTCAT_TEXT')}
            </IonButton>
        </div>
        <img src="assets/images/basepaws-mycats-empty.png" alt="Cat spying photo" />
      </>
    }
  </IonCard>
}

export default ProfileMyCatsCard
