import { servicesNew } from 'util/services'

interface IReplaceRequisitionProps {
  oldRequisitionId: string;
  newBarcode: string;
}

export async function replaceRequisition ({ oldRequisitionId, newBarcode }:IReplaceRequisitionProps):Promise<boolean> {
  const oldReqResponse = await servicesNew.get(`/requisitionForm/${oldRequisitionId}`)
  const isOldReqOK = typeof oldReqResponse === 'object' && oldReqResponse.id

  const newKit = await servicesNew.get(`/kit/${newBarcode}`)
  const isNewKitOk = !newKit.user && newKit.user_type === 'vet'

  let error = false
  if (isOldReqOK && isNewKitOk) {
    try {
      await servicesNew.put(`/requisitionForm/${oldRequisitionId}/replacementKit/${newBarcode}`)
    } catch (__error) {
      error = true
    }
  } else {
    error = true
  }
  return !error
}
