import React from 'react'

import OrganizationData from './OrganizationData'
import OrganizationLinks from './OrganizationLinks'

const NavbarWorkspaces = (props) => {
  const { organizationActive, onLogout, isAdminOrVet, closePopover, isDesktop } = props

  return (
    <>
      {isAdminOrVet &&
        <>
          {isDesktop && <>
            <OrganizationData organizationActive={organizationActive} />
            <hr />
          </>
          }
        </>
      }


      <OrganizationLinks closePopover={closePopover}
        onLogout={onLogout} />
    </>
  )
}

export default NavbarWorkspaces
