import { IonCol, IonInput, IonItem, IonRow } from '@ionic/react'
import Toaster from 'components/basepaws/utils/Toaster'
import { Formik, Form, Field } from 'formik'
import React, { useState } from 'react'
import { FormContext } from './FormContext'

const NewForm = (props) => {
  return (
    <FormContext.Provider value={{ styles: props.styles, editable: props.editable }}>
      <Formik {...props}
        enableReinitialize={true} >
        {(formik) => (
        <Form>
              {props.children(formik)}
        </Form>
        )}
      </Formik>
    </FormContext.Provider>

  )
}

export default NewForm

export const yupAdapter = yup => {
  if (!yup) return undefined
  return async value => {
    try {
      await yup.validate(value)
    } catch (e) {
      return e.message
    }
  }
}
