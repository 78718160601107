import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonButton, IonListHeader, IonCardContent } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'

import content from 'util/content'
import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import styles from './EditProfileForm.module.scss'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import Toaster from 'components/basepaws/utils/Toaster'

const EditProfileForm = ({ user }) => {
  const registerSchema = Yup.object().shape({
    //   gender: Yup.string().required('Please input a name')
  })

  const [edited, setEdited] = useState(false)
  const [isChanged, setIsChanged] = useState(false)

  const [phoneValid, setPhoneValid] = useState(true)

  const history = useHistory()

  const onCancel = () => {
    history.goBack()
  }

  const onSubmit = async (values, setSubmitting) => {
    setSubmitting(true)

    const userData = {
      ...user,
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number: values.phone_number,
      vet: {
        clinic: values.vet_clinic,
        name: values.vet_name,
        email: values.vet_email,
        phone: values.vet_phone
      }
    }

    try {
      console.log('updating user as: ', userData)
      await services.setUser(userData)
      setSubmitting(false)
      setIsChanged(false)
    } catch (e) {
      console.log(e)
      setSubmitting(false)
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: user?.id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number,
        vet_clinic: user?.vet?.clinic,
        vet_name: user?.vet?.name,
        vet_email: user?.vet?.email,
        vet_phone: user?.vet?.phone
      }}
      onSubmit={(values, { setSubmitting }) => {
        setEdited(true)
        onSubmit(values, setSubmitting)
      }}
      validationSchema={registerSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Toaster
            header={content.get('EDIT_PROFILE.SUCCESS.TITLE')}
            message={content.get('EDIT_PROFILE.SUCCESS.MESSAGE')}
            onDidDismiss={() => {
              setEdited(false)
              // history.push('/profile')
            }}
            status={edited}
          />
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center ion-justify-content-between">
              <IonCol size="12"
                size-lg="5"
                size-md="12">
                <h3 className="Heading_3 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.TITLE')}</h3>
                <p className="Body_1 text_grey">{content.get('EDIT_PROFILE.PERSONAL.CONTENT')}</p>
              </IonCol>
              <IonCol size="12"
                size-lg="6"
                size-md="12">
                <IonList className="form ion-margin-top">
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD1')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      name="first_name"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                      }}
                      type="text"
                      value={values.first_name}
                    >
                    </IonInput>
                    {errors.first_name && touched.first_name
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.first_name}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD2')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      name="last_name"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                      }}
                      type="text"
                      value={values.last_name}
                    >
                    </IonInput>
                    {errors.last_name && touched.last_name
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.last_name}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD3')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    {/* Disabled input */}
                    <IonInput
                      disabled
                      name="email"
                      type="email"
                      value={values.email}
                    >
                    </IonInput>
                    {errors.email && touched.email
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.email}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.PERSONAL.FIELD4')} <span className="Lead_2 text_dark_grey">  •  Optional</span></h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      inputmode="numeric"
                      name="phone_number"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                        setPhoneValid((!isNaN(values.phone_number) && !isNaN(values.vet_phone)) || values.phone_number === '')
                      }}
                      type="text"
                      value={values.phone_number}
                    >
                    </IonInput>
                    {errors.phone_number && touched.phone_number
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.phone_number}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  {/* {(newValues.phone_number && isNaN(newValues.phone_number)) &&
                    <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon icon={closeCircle} color="danger" slot="start"/>
                            Must be a number!
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                    } */}
                </IonList>
              </IonCol>
            </IonRow>
            <IonRow className="ion-margin-vertical ion-justify-content-between">
              <IonCol size="12"
                size-lg="5"
                size-md="12">
                <h3 className="Heading_3 text_sad_purple">{content.get('PROFILE.FORM.VET.TITLE')}</h3>
                <p className="Body_1 text_grey">{content.get('EDIT_PROFILE.VET.CONTENT')}</p>
              </IonCol>
              <IonCol size="12"
                size-lg="6"
                size-md="12">
                <IonList className="form ion-margin-top">
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELD0')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      name="vet_clinic"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                        handleBlur(event)
                      }}
                      type="text"
                      value={values.vet_clinic}
                    >
                    </IonInput>
                    {errors.vet_clinic && touched.vet_clinic
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.vet_clinic}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELD1')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      name="vet_name"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                        handleBlur(event)
                      }}
                      type="text"
                      value={values.vet_name}
                    >
                    </IonInput>
                    {errors.vet_name && touched.vet_name
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.vet_name}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELD2')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      inputmode="numeric"
                      name="vet_phone"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                        handleBlur(event)
                        setPhoneValid((!isNaN(values.phone_number) && !isNaN(values.vet_phone)) || values.vet_phone === '')
                      }}
                      type="text"
                      value={values.vet_phone}
                    >
                    </IonInput>
                    {errors.vet_phone && touched.vet_phone
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.vet_phone}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                  {/* {newValues.vet.phone && isNaN(newValues.vet.phone) &&
                    <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon icon={closeCircle} color="danger" slot="start"/>
                            Must be a number!
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                    } */}
                  <IonItem className="item-no-padding"
                    lines="none">
                    <IonListHeader className="ion-no-padding">
                      <IonLabel>
                        <h4 className="Heading_5 text_sad_purple">{content.get('PROFILE.FORM.VET.FIELD3')}</h4>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding"
                    lines="none">
                    <IonInput
                      name="vet_email"
                      onIonBlur={handleBlur}
                      onIonChange={handleChange}
                      onIonInput={(event) => {
                        setIsChanged(true)
                        handleChange(event)
                        handleBlur(event)
                      }}
                      type="email"
                      value={values.vet_email}
                    >
                    </IonInput>
                    {errors.vet_email && touched.vet_email
                      ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label"><IonIcon color="danger"
                            icon={closeCircle}
                            slot="start" />{errors.vet_email}</div>
                        </IonItem>
                      )
                      : null}
                  </IonItem>
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-between`}>
            <IonButton
              className="default_button"
              color="primary"
              fill="outline"
              mode="md"
              onClick={onCancel}
              shape="round"
            >
              {content.get('EDIT_PROFILE.CTA.CANCEL')}
            </IonButton>
            <LoaderButton
              buttonText={content.get('EDIT_PROFILE.CTA.SAVE')}
              className="default_button loader_button"
              color="primary"
              disabled={isSubmitting || !isChanged || !phoneValid}
              isSubmitting={isSubmitting}
              type="submit"
            ></LoaderButton>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default EditProfileForm
