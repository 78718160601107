import React from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react'
import { calendarNumberSharp, flashSharp, clipboardSharp } from 'ionicons/icons'
import content from 'util/content'
import PendingTaskCardEmpty from './PendingTaskCardEmpty'
import PendingTaskCard from './PendingTaskCard'
import styles from './ProfilePendingTasksCard.module.scss'
import services from 'util/services'

const ProfilePendingTasksCard = (props) => {
  const { pets } = props

  const categories = {
    phenotype_survey: {
      mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.SUBHEAD'),
      category: 'phenotype_survey',
      text: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.MESSAGE', { surveytype: 'Phenotype Survey', name: '__NAME__', action: '__ACTION__' }),
      ctaLink: 'ctaLink',
      ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.CTA_TEXT'),
      icon: clipboardSharp,
      mappingIds: [42]
    },
    flash_survey: {
      mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.SUBHEAD'),
      category: 'flash_survey',
      text: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.MESSAGE', { surveytype: 'Flash Survey', name: '__NAME__', action: '__ACTION__' }),
      ctaLink: 'ctaLink',
      ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.CTA_TEXT'),
      icon: flashSharp,
      mappingIds: []
    },
    recurring_survey: {
      mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.SUBHEAD'),
      category: 'recurring_survey',
      text: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.MESSAGE', { surveytype: 'Recurring Survey', name: '__NAME__', action: '__ACTION__' }),
      ctaLink: 'ctaLink',
      ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.SURVEY.CTA_TEXT'),
      icon: calendarNumberSharp,
      mappingIds: [33]
    },
    complete_data: {
      mainCategory: content.get('DASHBOARD.PENDING_TASKS.TYPE.EMPTYDATA.SUBHEAD'),
      category: 'complete_data',
      text: content.get('DASHBOARD.PENDING_TASKS.TYPE.EMPTYDATA.MESSAGE', { name: '__NAME__' }),
      ctaLink: '/cats/__HANDLE__/edit',
      ctaText: content.get('DASHBOARD.PENDING_TASKS.TYPE.EMPTYDATA.CTA_TEXT'),
      icon: clipboardSharp,
      mappingIds: []
    }
  }

  const pendingTasksArray = []

  const requiredFields = [
    'name',
    'born_on',
    'gender',
    'birth_location.city',
    'birth_location.adopted',
    'description',
    'images.0'
  ]

  const catHasMissingFields = (cat) => {
    let isMissingField = false
    requiredFields.forEach((field) => {
      let catField = cat
      field.split('.').forEach((property) => {
        if (catField) { catField = catField[property] }
      })
      if (catField == null || catField === '') {
        isMissingField = true || isMissingField
      }
    })
    return isMissingField
  }

  const catHasPendingSurveys = (cat) => {
    const pendingSurvey = []
    if (cat.surveys.length > 0) {
      cat.surveys.forEach((survey) => {
        if (!survey.complete) {
          const pendingCategory = Object.values(categories).find((c) => c.mappingIds.includes(survey.id))
          pendingSurvey.push({
            category: pendingCategory.category,
            url: survey.url
          })
        }
      })
    }
    return pendingSurvey
  }

  pets?.map((cat) => {
    if (catHasMissingFields(cat)) {
      pendingTasksArray.push({
        category: 'complete_data',
        petHandle: cat.handle,
        action: 'complete'
      })
    }

    const pendingSurveys = catHasPendingSurveys(cat)
    if (pendingSurveys.length > 0) {
      pendingSurveys.forEach(element => {
        pendingTasksArray.push({
          category: element.category,
          petHandle: cat.handle,
          ctaLink: element.url,
          action: 'answer'
        })
      })
    }
    return true
  })

  const emptyTasks = (pendingTasksArray.length === 0)

  const taskContents = () => {
    if (emptyTasks) {
      return <PendingTaskCardEmpty />
    } else {
      return pendingTasksArray.map((task, index) => {
        const category = categories[task.category]
        const pet = pets?.find(pet => pet.handle === task.petHandle)
        if (pet) {
          task.petName = pet.name
          task.petAvatar = services.thumb(pet.images?.find((i) => i.hit === 0))
        }
        return <PendingTaskCard
          key={index}
          task={task}
          category={category}
        />
      })
    }
  }

  return (
    <IonCard className={`${styles.my_profile_pendingtask_card} ${emptyTasks ? styles.my_profile_pendingtask_card_empty : ''} card`}>
      <IonCardHeader>
        <IonCardTitle className="Running_head_1 text_dark_grey">{content.get('DASHBOARD.PENDING_TASKS.SUBHEAD')}</IonCardTitle>
        { !emptyTasks &&
        <div className={`${styles.message} alert_message bg_grey`}>
          <p>{content.get('DASHBOARD.PENDING_TASKS.DESCRIPTION')}</p>
        </div>
        }
      </IonCardHeader>
      <IonCardContent>
        { taskContents() }
      </IonCardContent>
      { emptyTasks &&
        <>
        <div className={styles.card_footer}>
        </div>
        <img src="assets/images/basepaws-myprofile-illustration-cut.svg" alt="Cat illustration" />
        </>
      }
    </IonCard>
  )
}

export default ProfilePendingTasksCard
