import React from 'react'
import { IonCard, IonRow, IonIcon, IonImg, IonButton, IonItem } from '@ionic/react'
import moment from 'moment'

import { downloadSharp } from 'ionicons/icons'
import content from 'util/content'

import styles from './ReportModals.module.scss'

const PetDetails = (props) => {
  const { patient, report, showClientReportLink, showVetReportLink } = props

  if (!report?.id) { return null }

  return (
    <>
      <IonCard className={styles.card_information}>
        <IonRow class='ion-align-items-center ion-justify-content-between mb-3'>
          <div className='d-flex'>
            <IonImg
              className='mr-1'
              src={`assets/icon/tests/${report?.requisition?.kit?.productName}.svg`} />
            <p className='Body_regular text_primary m-0'>{content.get(`report_card.${report?.requisition?.kit?.productName}.name`)}</p>
          </div>
          <span className={`${styles.tags_id} Running_head text_sad_purple`}>
            ID {report?.kit_code}
          </span>
        </IonRow>
        <h6 className='Body_bold text_primary m-0 mb-1'>{patient?.name}</h6>
        <div className='d-flex mb-1'>
          <p className='Body_small_regular text_light m-0 mr-2'>Pet Owner</p>
          <p className='Body_small_regular text_grey m-0'>{patient?.owner?.first_name} {patient?.owner?.last_name}</p>
        </div>
        {patient?.owner?.email &&
          <div className='d-flex mb-1'>
            <p className='Body_small_regular text_light m-0 mr-2'>Email</p>
            <p className='Body_small_regular text_grey m-0'>{patient?.owner?.email}</p>
          </div>
        }
        {report.reviewed &&
          <div className='d-flex mb-1'>
            <p className='Body_small_regular text_light m-0 mr-2'>Reviewed</p>
            <p className='Body_small_regular text_grey m-0'>{moment(report.reviewed).format('MM/DD/YYYY')}</p>
          </div>
        }

        {showVetReportLink && <IonButton
          className='default_button icon-start mt-4'
          color='primary'
          fill='outline'
          href={`https://pdf.basepaws.com/vet_report?id=${report.id}`}
          mode='md'
          shape='round'
        >
          <IonIcon color='primary'
                   src={downloadSharp}
          />
          Read Vet Report
        </IonButton>
        }
      </IonCard>
      {showClientReportLink && <IonItem className={`${styles.box} mt-3 item-no-padding`}
                                        lines="none">
          <IonButton
            className='default_button icon-start'
            color='dark'
            fill='clear'
            mode='md'
            shape='round'
          >
            <IonIcon src={downloadSharp}/>
            Optional: Read Client Report
          </IonButton>
        </IonItem>
      }
    </>
  )
}

export default PetDetails
