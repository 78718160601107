import React from 'react'

import styles from './Loading.module.scss'

interface ILoadingProps {
  className?: string;
}

export default function Loading ({ className = '' }: ILoadingProps) {
  return (
    <div aria-label="loading"
         className={`${styles.loadingWrapper} ${className}`}>
      <div className={`${styles.loading}`} />
    </div>
  )
}
