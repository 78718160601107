import { IonIcon, IonInput } from '@ionic/react'
import { useField } from 'formik'
import React, { useContext } from 'react'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'

const Input = (props) => {
  const context = useContext(FormContext)
  icon = { icon }
  var { editable = context.editable, icon, name, placeholder } = props
  const [field, { value }, { setValue }] = useField({ name: name })
  return (
     <IonInput
        className={`${editable ? '' : 'data_disabled'} mb-2`}
        data-qa={name}
        disabled={!editable}
        name={name}
        onIonChange={e => e.detail.value && setValue(e.detail.value)}
        placeholder={placeholder}
        value={value}
      >
       {icon && <IonIcon
          className="ion-padding-start"
          color="dark"
          icon={icon}
          slot="start"
        />}
      </IonInput>
  )
}
export const Text = (props) => {
  const { label, editable, frame, value, small } = props

  if (!editable) {
    return (
    <Row label={label}>{value}</Row>
    )
  }

  if (editable && !frame) {
    return (
      <Input
        {...props}
      />
    )
  }

  if (editable && frame) {
    return (
    <Row label={label}
         small={small}>
      <Input
        {...props}
      />

  </Row>)
  }
}
