import React, { useContext, useRef } from 'react'
import { IonButton, IonIcon, IonImg, IonInput, IonLabel } from '@ionic/react'
import InputSelect from 'components/basepaws/utils/InputSelect'
import { useField } from 'formik'
import { closeSharp } from 'ionicons/icons'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'
import { UserFind } from '../UserFind/UserFind'
import { yupAdapter } from '../Form/Form'
import { string } from 'yup'
import { Static } from '../Form/Static'

export const AnimalFind = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, frame = true, required, options, name, label, placeholder, components, bold, onChange } = props

  let validate = string()
  if (required) { validate = validate.required('required') }

  const [field, { value }, { setValue }] = useField({ name: name })
  const [{ name: animal_name_name }, { value: animal_name }, { setValue: set_animal_name }] = useField({ name: name + '.name', validate: yupAdapter(validate) })

  const newAnimal = value && value?.id == null
  const newOwner = value?.owner?.id == null

  const inputRefFocus = useRef()

  if (!editable) {
    return (
      <Row bold={bold}
           label={label}>
        {animal_name && <Static
          icon="/assets/icon/data/pet.svg"
          multipleFirst>
            {animal_name}
          </Static>}
        <UserFind
          {...props}
          components={components}
          editable={false}
          frame={false}
          icons={{ first_name: '/assets/icon/data/pet-owner.svg' }}
          label='Owner'
          name={`${name}.owner`}
          noValidate={['email']}
          patientOwner
          placeholder='Owner'
          required={required}/>
      </Row>)
  }

  if (editable) {
    return (

  <Row
    bold={bold}
    label={label}
  >
      {!newAnimal
        ? <InputSelect
        {...props}
        createLabel={data => (
          <div className='new'>
            <IonImg
              className='icon'
              slot='start'
              src='/assets/icon/data/pet_add.svg'
            />
            <IonLabel>
              Add new patient
            </IonLabel>
          </div>
        )}
        name={name}
        onChange={value => {
          const isnew = !value.id
          if (isnew)setValue(null)
          else setValue(value)
          if (!isnew) onChange && onChange(value)
        }}
        onCreate={text => {
          setValue({ name: text })
          setTimeout(() => { // set focus on animal_name input
            inputRefFocus?.current?.setFocus()
          }, 10)
        }}
        options={options.animal}
        placeholder={`Search a ${placeholder}`}
        value=""
        />
        : <IonButton
        className="default_button icon-start button-remove"
        color="primary"
        fill="clear"
        mode='md'
        onClick={() => {
          setValue(null)
        }}
        shape="round"
        type='button'
      >
        <IonIcon color='primary'
                 src={closeSharp}/>
        Discard New {label}
      </IonButton>}

      <IonInput
        className={'mb-2'}
        data-qa={animal_name_name}
        disabled={!newAnimal}
        name={animal_name_name}
        onIonChange={e => e.detail.value && set_animal_name(e.detail.value)}
        placeholder={`${placeholder} Name`}
        ref={inputRefFocus}
        value={animal_name}
      >
         <IonImg
            className="ion-padding-start"
            src="/assets/icon/data/pet.svg"
          />
      </IonInput>
      <UserFind
        {...props}
        components={components}
        createLabel={data => (
                  <div className='new'>
                    <IonImg
                      className='icon'
                      slot='start'
                      src='/assets/icon/data/pet-owner_add.svg'/>
                    <IonLabel>
                      Add new pet owner
                    </IonLabel>
                  </div>)
                }
        disabled={!newOwner}
        editable={editable}
        frame={false}
        icons={{
          first_name: '/assets/icon/data/pet-owner.svg',
          last_name: '/assets/icon/data/pet-owner.svg'
        }}
        label='Owner'
        name={`${name}.owner`}
        noValidate={['email']}
        options={options.owner}
        placeholder='Owner'
        required={required}/>
    </Row>
    )
  }
}
