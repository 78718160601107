import React from 'react'
import { IonIcon, IonImg } from '@ionic/react'

const IconTitle = (props) => {
  const { img, icon, title } = props

  return (
    <div className='d-flex ion-align-items-center'>
      {icon && <IonIcon
        className='mr-2'
        src={icon} />}
      {img && <IonImg
        className='mr-2'
        src={img}/>}
      <h2 className='Heading_4 text_primary m-0'>{title}</h2>
    </div>
  )
}

export default IconTitle
