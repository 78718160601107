import store from '../store/store'

import Resizer from 'react-image-file-resizer'

let _user
class Services {
  constructor (gateway, ws) {
    this.gateway = gateway
    this.ws = ws
  }

  async get (url) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'GET',
      credentials: 'include'
    })
    if (result.status !== 200) { throw await result.json() }

    return await result.json()
  }

  async delete (url) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'DELETE',
      credentials: 'include'
    })
    if (result.status !== 200) { throw await result.json() }

    // reload the user
    this.user()

    return await result.json()
  }

  async put (url, data, options = {}) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      ...options
    })

    // reload the user
    this.user()

    if (result.status !== 200) { throw await result.json() }

    return await result.json()
  }

  async post (url, data) {
    if (url.startsWith('/')) url = `${this.gateway}${url}`
    const result = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })

    if (result.status !== 200) { throw await result.json() }

    return await result.json()
  }

  async logout () {
    return this.get('/auth/logout').then(() => {
      _user = null
      store.setUser(null)
      store.setVets(null)
      store.setPatients(null)
      store.setOrganization(null)
      store.setResources(null)
      // Clear cache on each service
      services.clearCache()
      servicesNew.clearCache()
    })
  }

  reloadUser () {
    _user = null
    store.setUser(_user)
  }

  async updateUser () {
    _user = await this.user()
    store.setUser(_user)
  }

  getUser () {
    if (!_user) {
      _user = this.user()
    }
    return _user
  }

  async content (key, value) {
    if (!key && !value) {
      return servicesNew.get('/content/ui-organization')
    } else {
      return servicesNew.put(`/content/ui-organization/${key}`, value, { headers: { 'Content-Type': 'text/plain' } })
    }
  }

  async contentDeleteKey (key) {
    if (key) {
      return servicesNew.delete(`/content/ui-organization/${key}`)
    } else {
      return false
    }
  }

  async login (data) {
    // data {
    //   email: string
    //   password: string
    // }
    await services.post('/auth/login', data)
    _user = null
    _user = await this.user()
    return _user
  }

  async user (forced = false) {
    if (_user && _user.id && !forced) return _user
    try {
      _user = await servicesNew.get('/organization/me')

      if (_user?.id) {
        _user.lastOrganization = _user.organization
      } else {
        const noAuthNeededPages = ['/login', '/vet/mail/confirmed', '/error', '/changePassword']
        if (!noAuthNeededPages.includes(window.location.pathname)) {
          window.location.href = '/login'
        }
      }
    } finally {
      if (_user) {
        try {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'authentication',
            userId: _user.id
          })
        } catch (e) { }

        const _preferences = {
          showManageVetsBanner: _user?.preferences?.showManageVetsBanner ?? true,
          passwordChange: _user?.preferences?.passwordChange ?? true
        }
        _user.preferences = _preferences

        store.setUser(_user)
      }
    }
    return _user
  }

  async getRequisitionForms () {
    if (this._requisition) return this._requisition
    const user = await this.user()
    if (!user?.organization?.id) return
    this._requisition = await servicesNew.get(`/requisitionForm?pageSize=2000&organization_id=${user.organization.id}`)
    return this._requisition
  }

  async putRequisitionForm (requisitionId, data) {
    const uRF = await servicesNew.put(`/requisitionForm/${requisitionId}`, data)
    this.getRequisitionForms(_user?.organization?.id)
    this.clearCache('patients')
    this.getPatients(_user?.organization?.id)
    return uRF
  }

  async setActiveOrganization (organization) {
    try {
      return organization
    } catch (e) {
      return null
    }
  }

  async getSummary (organizationId) {
    try {
      const orgSummary = await services.get(`/organization/${organizationId}`)

      // this._organizationActiveData.patients = orgSummary.patients
      if (Object.keys(orgSummary.kitsInfo).length === 0) {
        orgSummary.kitsInfo = {
          available: 0,
          activated: 0,
          progress: 0,
          done: 0
        }
      }

      store.setOrganization(orgSummary)
      store.setKitsInfo(orgSummary.kitsInfo)

      return orgSummary
    } catch (e) {
      return false
    }
  }

  async getCountries () {
    const countryList = await servicesNew.get('/geographic')
    countryList.forEach((country, index) => {
      countryList[index] = {
        ...country,
        label: country.name,
        value: country.id
      }
    })

    return countryList
  }

  async getStates (country) {
    const stateList = await servicesNew.get(`/geographic/${country}/states`)
    stateList.forEach((state, index) => {
      stateList[index] = {
        ...state,
        label: state.name,
        value: state.id
      }
    })

    return stateList
  }

  async getCities (country, state) {
    const cityList = await servicesNew.get(`/geographic/${country}/${state}/cities`)
    cityList.forEach((city, index) => {
      cityList[index] = {
        ...city,
        label: city.name,
        value: city.id
      }
    })

    return cityList
  }

  async getReportsToReview (organizationId) {
    const reportsToReview = await servicesNew.get(`/organization/${organizationId}/pendingAction`)
    return reportsToReview
  }

  async setReportReviewed (report) {
    const response = await servicesNew.put(`/report/${report.id}`, report)
    return response
  }

  async getPatients (organizationId) {
    try {
      if (!organizationId) return

      const vetPatients = await this.getOrganizationPatients(organizationId)

      store.setPatients(vetPatients)
      return vetPatients
    } catch (e) {
      console.log(e)
      return []
    }
  }

  async getPatient (handle) {
    try {
      const patient = await services.get(`/animal/${handle}`)
      return patient
    } catch (e) {
      return null
    }
  }

  async getResources (organizationId) {
    try {
      let base = ''
      const mockedApi = true
      if (mockedApi) {
        base = 'http://localhost:8000/mockedApi'
      }
      const vetResources = await services.get(`${base}/organization/${organizationId}/resources/`)
      store.setResources(vetResources)
      return true
    } catch (e) {
      return null
    }
  }

  async getKitsInfo (
    organizationId,
    listParams = {
      itemsPerPage: 10,
      cursor: 0,
      search: '',
      orderBy: 'created_des'
    }) {
    if (!listParams.itemsPerPage) listParams.itemsPerPage = 10
    if (!listParams.cursor) listParams.cursor = 0
    if (!listParams.orderBy) listParams.orderBy = 'created_des'
    if (!listParams.search) listParams.search = ''

    let urlParams = `?p=${listParams.cursor}&n=${listParams.itemsPerPage}`
    urlParams += `&o=${listParams.orderBy}`

    if (listParams.search) {
      const search = encodeURIComponent(listParams.search)
      urlParams += `&q=${search}`
    }

    try {
      const vetKits = await services.get(`/organization/${organizationId}/kit${urlParams}`)

      const returnObject = {
        orderBy: listParams.orderBy,
        search: listParams.search,
        cursor: listParams.search !== 'NO_MORE' ? vetKits.next : 'NULL',
        itemsPerPage: vetKits.items_per_page
      }
      const kitsInfo = {
        available: vetKits.kitsInfo.available, // old one was in its own endpoint and saved in vetKitsInfo
        activated: vetKits.kitsInfo.activated,
        progress: vetKits.kitsInfo.progress,
        done: vetKits.kitsInfo.done
      }

      store.setKitsInfo(kitsInfo)
      store.setKits(vetKits.kits)

      return returnObject
    } catch (e) {
      return {
        orderBy: '',
        search: '',
        cursor: '',
        itemsPerPage: ''
      }
    }
  }

  async getContributors (
    organizationId,
    listParams = {
      itemsPerPage: 10,
      cursor: 0,
      search: '',
      orderBy: 'created_des'
    }
  ) {
    if (!listParams.itemsPerPage) listParams.itemsPerPage = 10
    if (!listParams.cursor) listParams.cursor = 0
    if (!listParams.orderBy) listParams.orderBy = 'created_des'
    if (!listParams.search) listParams.search = ''

    let urlParams = `?p=${listParams.cursor}&n=${listParams.itemsPerPage}`
    urlParams += `&o=${listParams.orderBy}`

    if (listParams.search) {
      const search = encodeURIComponent(listParams.search)
      urlParams += `&q=${search}`
    }
    try {
      const vetContributors = await services.get(`/organization/${organizationId}/invites${urlParams}`)

      store.setContributors(vetContributors.contributors)
      return {
        orderBy: listParams.orderBy,
        search: listParams.search,
        cursor: listParams.search !== 'NO_MORE' ? vetContributors.next : 'NULL',
        itemsPerPage: vetContributors.items_per_page
      }
    } catch (e) {
      return null
    }
  }

  async setContributors (organizationId, emails) {
    // eslint-disable-next-line prefer-const
    let emailsStrings = '?'

    emails.forEach(
      (email) => {
        emailsStrings += `email[]=${email}&`
      }
    )
    const emailsStringsClean = emailsStrings.slice(0, -1)
    console.log(`/organizations/${organizationId}/contributors${emailsStringsClean}`)
    const response = await services.post(`/organizations/${organizationId}/contributors${emailsStringsClean}`)
    console.log(response)
    console.log(store.contributors)
    if (response.length >= 1) {
      store.setContributors(response.contributors)
      return 'success'
    } else {
      return 'error'
    }
  }

  async setDeceased (pet, data) {
    let response
    try {
      pet.death_on = data.death_on
      pet.contact = data.contact
      if (pet.id) {
        response = await servicesNew.put(`/animal/${pet.id ?? ''}`, pet)
      }
    } catch (e) {
      console.log(e)
      response = e
    }

    return response
  }

  async setVisible (pet, visible) {
    let response
    try {
      pet.visible = visible
      pet.hidden = !visible // backwards compatibility
      if (pet.id) {
        response = await servicesNew.put(`/animal/${pet.id ?? ''}`, pet)
      }
    } catch (e) {
      console.log(e)
      response = e
    }

    return response
  }

  async setPets (pet) {
    let retStatus = false
    try {
      if (pet.profilePic !== null) {
        const avatar = await this.toBase64(pet.profilePic, 300, 300, 100)
        if (!pet.images) {
          pet.images = []
        }
        if (avatar) {
          pet.images[0] = { src: avatar, hit: 0 }
        }
      }

      if (pet.id) {
        await services.put(`/animal/${pet.id ?? ''}`, pet)
      } else {
        await services.post('/animal', pet)
      }
      retStatus = true
    } catch (e) {
      console.log(e)
    }

    return retStatus
  }

  async setUser (User) {
    let retState = false
    try {
      await servicesNew.put(`/user/${User.id}`, User)
      retState = true
    } catch (e) { console.log(e) }
    return retState
  }

  async activateKit (kit, pet) {
    let retState = false
    try {
      // /kit/<kit id or barcode>/active/<animal id or handle>
      retState = await services.get(`/kit/${kit.id}/active/${pet.id}`)
    } catch (e) {
      console.log(e)
    }
    // reload the user
    this.user()
    console.log('user reloaded')
    return retState
  }

  async setGallery (pet, images) {
    let avatar
    let response = null
    try {
      for (let index = 0; index < images.length; index++) {
        avatar = await this.toBase64(images[index], 1200, 1200, 90)
        pet.images.push({ src: avatar })
      }

      response = await services.put(`/animal/${pet.id}`, pet)
    } catch (e) {
      console.log(e)
      response = e
    }

    return response
  }

  async addPet (pet) {
    let response = null
    try {
      if (pet.profilePic) {
        if (pet.profilePic !== null) {
          const avatar = await this.toBase64(pet.profilePic, 300, 300, 100)
          if (!pet.images) {
            pet.images = []
          }
          if (avatar) {
            pet.images[0] = { src: avatar, hit: 0 }
          }
        }
      }

      response = await services.post('/animal', pet)
      console.log(response)
    } catch (e) {
      console.log(e)
      response = e
    }

    return response
  }

  toBase64 (file, maxHeight, maxWidth, compression) {
    if (!file) return

    return new Promise((resolve) => {
      Resizer.imageFileResizer(file, maxHeight, maxWidth, 'JPEG', compression, 0,
        uri => {
          resolve(uri)
        }, 'base64')
    })
  }

  async addContributor (organizationId, contributor) {
    const response = await services.post(`/organizations/${organizationId}/contributors`, contributor)
    if (response) {
      return true
    } else {
      return false
    }
  }

  async removeContributor (invitationId, organizationId) {
    store.removeContributor(invitationId)

    const response = await services.delete(`/organizations/${organizationId}/contributors/${invitationId}`)
    if (response) {
      return 'success'
    } else {
      return 'error'
    }
  }

  async resendInvitation (invitationId, organizationId) {
    try {
      const response = await services.post(`/organizations/${organizationId}/contributors/${invitationId}/send`)

      if (response.contributors) {
        return 'success'
      }
    } catch (error) {
      return 'error'
    }
  }

  async cancelInvitation (invitationId, organizationId) {
    store.cancelContributorInvitation(invitationId)
    const response = await services.post(`/organizations/${organizationId}/contributors/${invitationId}/cancel`)
    if (response) {
      return 'success'
    } else {
      return 'error'
    }
  }

  async clearCache (key = null) {
    if (!key) {
      this._vets = null
      this._patients = null
      this._owners = null
      this._requisition = null
    } else {
      this[`_${key}`] = null
    }
  }

  async getOrganizationVets (organizationId) {
    if (this._vets) return this._vets
    this._vets = []
    this._vets = await servicesNew.get(`/organization/${organizationId}/vets`)
    store.setVets(this._vets)
    return this._vets
  }

  async getOrganizationPatients (organizationId) {
    if (this._patients) return this._patients
    this._patients = []
    this._patients = await servicesNew.get(`/organization/${organizationId}/patients`)
    return this._patients
  }

  async getOrganizationOwners (organizationId) {
    if (this._owners) return this._owners
    this._owners = []
    this._owners = (await this.getOrganizationPatients(organizationId))
      .map(patient => patient.owner)
      .filter((v, i, a) => a.findIndex(v2 => (v2?.id === v?.id)) === i)
      .filter(item => item)
    return this._owners
  }

  async getOrganization (organizationId) {
    try {
      const response = await servicesNew.get(`/organization/${organizationId}`)
      return response
    } catch (e) {
      return false
    }
  }

  async removeVetFromOrganization (organizationId, vetId) {
    const response = await servicesNew.delete(`/organization/${organizationId}/vets/${vetId}`)
    if (response) {
      return response
    } else {
      return 'error'
    }
  }

  async addVetToOrganization (organizationId, vet) {
    const response = await servicesNew.post(`/organization/${organizationId}/vet`, vet)
    if (response) {
      return response
    } else {
      return 'error'
    }
  }

  async UpdateVetToOrganization (vet) {
    const response = await this.setUser(vet)
    if (response) {
      return response
    } else {
      return 'error'
    }
  }

  async validateEmail (id) {
    const response = await servicesNew.get(`/vetPortal/hubspot/webhook/confirm/${id}`)
    if (response.confirm) {
      return 'success'
    } else {
      throw new Error(response?.error)
    }
  }

  link (url) {
    if (url.startsWith('/')) return `${this.gateway}${url}`
    return url
  }

  thumb (img) {
    if (!img) return this.DEFAULT_CAT_PLACEHOLDER
    if (img.src?.startsWith('data:') || img.src?.startsWith('http')) return img.src
    return `${this.image(img.src)}?size=small`
  }

  big (img) {
    if (!img) return this.DEFAULT_CAT_PLACEHOLDER
    if (img.src.startsWith('data:') || img.src.startsWith('http')) return img.src
    return this.image(img.src) + '?size=large'
  }

  image (url) {
    if (!url) return this.DEFAULT_CAT_PLACEHOLDER
    if (url.startsWith('data:') || url.startsWith('http')) return url
    return `${this.gateway}${url}`
  }

  DEFAULT_CAT_PLACEHOLDER = '/assets/images/basepaws-mycats-cat-picture.svg'
}

const services = new Services('/2020-09', '')
const servicesNew = new Services('/2021-08', '')
export default services
export { services, servicesNew }
