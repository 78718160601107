import React from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { IonGrid, IonRow, IonCol, IonIcon } from '@ionic/react'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import { checkmarkSharp } from 'ionicons/icons'
import content from 'util/content'

const AccountRecoverPasswordFormEmailConfirmation = () => {
  const user = useSelector(state => state.userInReducer.user)
  const location = useLocation()

  const emailCalled = { email: location.state?.detail }

  return (
    <GeneralPage
      isNotFixed={true}
      user={user}
    >
      <IonGrid fixed>
        <IonRow className="ion-justify-content-center w-100">
          <IonCol className="ion-text-center"
                  size='11'
                  size-md="10">
            <span className='success-section-circle'>
              <IonIcon className="empty-section-icon"
                       color='success'
                       src={checkmarkSharp} />
            </span>
            <h1 className="Heading_2 text_primary ion-text-center">
              {content.get('RECOVERPASSWORD.EMAIL.SUCCESS.TITLE')}
            </h1>
            <IonRow className="ion-justify-content-center w-100">
              <IonCol className="ion-text-center"
                      size="10"
                      size-md="10">
                <p className="Lead text_grey mt-0">
                  If the email address <span className='Heading_4'>{emailCalled.email}</span> exists in our database, it will receive an email with further instructions.
                </p>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </GeneralPage>
  )
}

export default AccountRecoverPasswordFormEmailConfirmation
