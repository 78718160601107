import React, { useEffect, useState } from 'react'
import { IonCard, IonRow, IonCol, IonButton, IonIcon, IonImg, isPlatform } from '@ionic/react'
import Moment from 'react-moment'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { alertCircleSharp, calendarClearSharp, checkmarkCircleSharp, createSharp, documentSharp, helpCircleSharp, refreshCircleSharp } from 'ionicons/icons'

import Accordion from 'components/basepaws/utils/Accordion'
import content from 'util/content'

import diseases from '../../../../pages/RequisitionFormPage/deceases'
import ModalReleaseReport from '../ReportModals/ModalReleaseReport'
import ModalReviewReport from '../ReportModals/ModalReviewReport'
import ModalViewReport from '../ReportModals/ModalViewReport'

import StateLabel from './StateLabel'
import MissingEmailWidget from './MissingEmailWidget'
import MissingEmailIcon from './MissingEmailIcon'
import RequisitionReplacement from './RequisitionReplacement'

import styles from './PatientListItem.module.scss'
import { exception, processing, ready, received, registered, released, reviewed, waiting } from '../constants'

const PatientListItem = (props) => {
  const { currentPatient, opened, setOpened } = props

  const [patient, setPatient] = useState(currentPatient)
// TODO: remove useEffect / check if we need state here for the patient
  useEffect(() => {
    setPatient(currentPatient)
  }, [currentPatient])

  const [showMoreData, setShowMoreData] = useState(false)

  useEffect(() => {
    setShowMoreData(false)
  }, [opened])

  const desktop = isPlatform('desktop')

  const reportStateData = {
    waiting: {
      text: 'Processing',
      icon: refreshCircleSharp,
      style: styles.processing
    },
    processing: {
      text: 'Processing',
      icon: refreshCircleSharp,
      style: styles.processing
    },
    registered: {
      text: 'Registered',
      icon: refreshCircleSharp,
      style: styles.registered
    },
    exception: {
      text: 'Exception',
      icon: alertCircleSharp,
      style: styles.exception
    },
    received: {
      text: 'Kit Received',
      icon: refreshCircleSharp,
      style: styles.received
    },
    ready: {
      text: 'Ready to Review',
      icon: helpCircleSharp,
      style: styles.ready_to_review
    },
    reviewed: {
      text: 'Reviewed',
      icon: checkmarkCircleSharp,
      style: styles.ready_to_release
    },
    released: {
      text: 'Released',
      icon: checkmarkCircleSharp,
      style: styles.released
    }
  }

  const [openModalReleaseReport, setOpenModalReleaseReport] = useState(false)
  const [modalReviewReport, setModalReviewReport] = useState(false)
  const [modalViewReport, setModalViewReport] = useState(false)
  const [reportToModal, setReportToModal] = useState()

  const age = patient?.born_on &&
    <Moment
      ago
      fromNow
    >
      {patient.born_on}
    </Moment>

  const patientExtraFields = (<>
    <div className='d-flex ion-align-items-center'>
      <p className='Body_small_regular text_light'>
        {content.get('patient.species.label')}
        <span className='Body_small_regular text_grey ml-2'>
          {content.get(`patient.species.${patient?.species}.title`)}
        </span>
      </p>
    </div>
    <div className='d-flex ion-align-items-center'>
      <p className={`${styles.textCapitalized} Body_small_regular text_light`}>
        {content.get('patient.spayed.label')} {' '} {/* We need this extra space to capitalize to work. Why? Don't know */}
        <span className={'Body_small_regular text_grey ml-2'}>{patient.spayed}</span>
      </p>
    </div>
    <div className='d-flex ion-align-items-center'>
      <p className='Body_small_regular text_light'>
        {content.get('patient.birthdate.label')}
        <span className='Body_small_regular text_grey ml-2'>
          {patient.born_on &&
            <Moment format='MM/DD/YYYY'>
              {patient.born_on}
            </Moment>
          }
        </span>
      </p>
    </div>
    <div className='d-flex ion-align-items-center'>
      <p className='Body_small_regular text_light'>
        {content.get('patient.age.label')}
        <span className='Body_small_regular text_grey ml-2'>{age}</span>
      </p>
    </div>

    {patient.diagnosed_diseases && patient.diagnosed_diseases.length > 0 &&
      <div className='d-flex ion-align-items-center'>
        <p className='Body_small_regular text_light'>
          {content.get('patient.diseases.label')}
          <span className='Body_small_regular text_grey ml-2'>
            {patient.diagnosed_diseases?.map((d, i) => {
              let dis = diseases[patient.species || 'cat'].find((v) => v.value === d)?.label
              if (i !== patient.diagnosed_diseases.length - 1) {
                dis += ', '
              }
              return dis
            }
            )}
          </span>
        </p>
      </div>
    }
  </>)

  const viewMoreLessButton = (
    <IonButton
      className={`default_button button_underline ${desktop ? '' : 'button-cero-padding'}`}
      color='dark'
      fill='clear'
      mode='md'
      shape='round'
    >
      {showMoreData ? 'View less' : 'View more'}
    </IonButton >
  )

  const PatientDataExpandable = <>
    <div className={`${styles.patient_list_item_expandable_cat_data} mt-3`}>
      {!patient?.owner?.email &&
        <MissingEmailWidget
          pet={patient}
          requisitionForm={patient?.reports[0]?.requisition}
          setPatient={setPatient}
          toastMessage={{
            error: 'Error when trying to update email',
            success: 'Email changed successfully!'
          }}
        />
      }
      <div className='d-flex ion-align-items-center'>
        <p className={`${styles.textCapitalized} Body_small_regular text_light`}>
          {content.get('patient.gender.label')}{' '} {/* We need this extra space to capitalize to work. Why? Don't know */}
          <span className='Body_small_regular text_grey ml-2'>{patient?.gender}</span>
        </p>
      </div>
      <div className='d-flex ion-align-items-center'>
        <p className='Body_small_regular text_light'>
          {content.get('patient.pet_owner.label')}
          <span className='Body_small_regular text_grey ml-2'>
            {patient?.owner?.first_name} {patient?.owner?.last_name}
          </span>
        </p>
      </div>
      {patient?.owner?.email &&
        <div className='d-flex ion-align-items-center'>
          <p className='Body_small_regular text_light'>
            {content.get('patient.email.label')}
            <span className='Body_small_regular text_grey ml-2'>
              {patient?.owner?.email}
            </span>
          </p>
        </div>
      }
      <Accordion
        className='view_more_button'
        list={[patient]}
        renderHeader={() => viewMoreLessButton}
        renderOrder='HeaderAfterPanel'
        renderPanel={() => patientExtraFields}
        showIcons={false}
        toggleFunction={() => { setShowMoreData(!showMoreData) }}
        toggleVar={showMoreData}
      />
    </div>
  </>

  const PatientData = (props) => {
    const { patient } = props
    return (
      <Accordion
        className='patient_list'
        list={[patient]}
        renderHeader={(item) =>
          <div className={`d-flex no-wrap ion-align-items-center ${styles.overflow_text}`}>
            {!patient?.owner?.email &&
              <MissingEmailIcon />
            }
            <p className={'Body_bold text_primary mb-0 mt-0 ml-0'}>
              {item?.name}
            </p>
            <p className={'Body_regular text_light mb-0 mt-0 ml-2'}>
              {patient?.owner?.first_name} {patient?.owner?.last_name}
            </p>
          </div>
        }
        renderPanel={() =>
          PatientDataExpandable
        }
        toggleFunction={() => setOpened(patient.id)}
        toggleIconPosition='before'
        toggleVar={opened}
      />
    )
  }

  const RequisitionDataExpandableGeneralData = (props) => {
    const { requisition } = props
    return (
      <IonCol
        className={`${styles.patient_list_item_expandable_info_report_col1} mt-4`}
        size="12"
        size-md="6">
        {requisition.frontId && <div className='d-flex ion-align-items-center no-wrap mb-3 pl-2'>
          <IonIcon icon={documentSharp} />
          <NavLink
            className="link ml-3 button_underline Body_small_regular text_light"
            to={`/forms/requisition/${requisition?.frontId}`}
          >
            {requisition?.frontId}
          </NavLink>
        </div>}
        {requisition.date && <div className='d-flex ion-align-items-center no-wrap mb-3 pl-2'>
          <IonIcon icon={calendarClearSharp} />
          <p className='Body_small_regular text_light m-0 ml-3'>
            {requisition?.date && <Moment format="MM/DD/YYYY">{requisition?.date}</Moment>}
          </p>
        </div>}
        {requisition.responsible && <div className={`d-flex ion-align-items-center no-wrap mb-3 pl-2 ${styles.customIconColor}`}>
          <IonImg src='/assets/icon/data/vet.svg' />
          <p className='Body_small_regular text_light m-0 ml-3'>
            {requisition?.responsible}
          </p>
        </div>}
        {requisition.note && <div className='d-flex ion-align-items-start no-wrap mb-3 pl-2'>
          <IonIcon
            className='mt-1'
            icon={createSharp}
          />
          <p className='Body_small_regular text_light m-0 ml-3 pr-3'>
            {requisition?.note}
          </p>
        </div>}
      </IonCol>
    )
  }

  const ReportDataExpandableStateData = (props) => {
    const { report } = props

    let statusCode = ''
    switch (report.status) {
      case ready:
        statusCode = (
          <div className={`${styles.patient_list_item_expandable_share_banner} ${styles.review}`}>
            <h6 className='Heading_5 text_primary mb-1 mt-0'>
              Read and validate the report
            </h6>
            <p className='Body_small_regular text_grey mt-0'>
              Once you confirm that you reviewed it, you will be able to release it.
            </p>
            <div className='d-flex ion-justify-content-between'>
              <IonButton
                className='default_button icon-start'
                color='primary'
                fill='solid'
                mode='md'
                onclick={() => {
                  setReportToModal(report)
                  setModalReviewReport(true)
                }}
                shape='round'
              >
                Review
              </IonButton>
            </div>
          </div>
        )
        break
      case released:
      case reviewed:
        statusCode = (
          <div className={`${styles.patient_list_item_expandable_share_banner} ${styles.released}`}>
            <p className='Body_small_bold text_grey mb-1 mt-0'>{content.get('vet.reportsList.reviewed.title')} <span className="Body_small_regular text_grey ml-1">{moment(report.reviewed).format('MM/DD/YYYY')}</span></p>
            <p className='Body_small_regular text_grey mt-0'
               dangerouslySetInnerHTML={{ __html: content.get('vet.reportsList.reviewed.content') }}
            />
            <div className='d-flex'>
              <IonButton
                className='default_button'
                color='primary'
                fill='solid'
                mode='md'
                onclick={() => {
                  setReportToModal(report)
                  setModalViewReport(true)
                }}
                shape='round'
              >
                {content.get('vet.reportsList.reviewed.cta')}
              </IonButton>
            </div>
          </div>
        )
        break
      case exception:
        statusCode = (
          <div className={`${styles.patient_list_item_expandable_share_banner} ${styles.exception}`}>
            <RequisitionReplacement
              oldKitCode={report.kit_code}
              oldRequisitionId={report.requisition.id}
            />
          </div>
        )
        break
      case registered:
      case received:
      case waiting:
      case processing:
      default:
        break
    }

    return (
      <IonCol
        className={styles.patient_list_item_expandable_info_report_col2}
        size="12"
        size-md="6">
        {statusCode}
      </IonCol>
    )
  }

  const RequisitionData = (props) => {
    const { requisition } = props
    const req = requisition.requisition

    return (
      <IonRow className={`${styles.patient_list_item_report_row} ion-align-items-center`}
              data-reportId={requisition.id}>
        <IonCol size="12"
                size-lg="6">
          <div className={'d-flex ion-align-items-center no-wrap'}>
            <IonImg
              className='mr-3'
              src={`/assets/icon/tests/${req.kit?.productName}.svg`}
            />
            <p className={`Body_regular text_primary mb-0 mt-0 ${styles.overflow_text}`}>{content.get(`report_card.${req.kit?.productName}.name`)}</p>
          </div>
        </IonCol>
        <IonCol size="12"
                size-lg="6">
          <IonRow>
            <IonCol size="6"
                    size-md="6">
              <span className={`${styles.patient_list_item_tags_id} Running_head text_primary`}>
                ID {req.kit?.code}
              </span>
            </IonCol>
            <IonCol size="6"
                    size-md="6">
              <StateLabel reportStatus={reportStateData[props.requisition?.status]} />
            </IonCol>
          </IonRow>
        </IonCol>
        <IonCol size='12'>
          <IonRow className={`${styles.patient_list_item_expandable_report_data}`} >
            {opened && <RequisitionDataExpandableGeneralData requisition={req} />}
            {opened && <ReportDataExpandableStateData report={requisition} />}
          </IonRow>
        </IonCol>
      </IonRow>
    )
  }

  return (
    <>
      <IonCard className={`${styles.patient_list_item} card ${opened ? styles.active : ''}`}>
        <IonRow>
          <IonCol
            className={`${styles.right_line}`}
            size='12'
            size-lg='4'>
            <PatientData patient={patient} />
          </IonCol>
          <IonCol
            size='12'
            size-lg="8"
          >
            {/* This should be used after BE is ready */}
            {/* {patient.requisitions?.map((requisition) => { */}
            {patient.reports?.filter((requisition) => !requisition?.requisition?.kit?.replacement).map((requisition) => {
              return <RequisitionData
                key={`requisition-${requisition.id}`}
                requisition={requisition} />
            })}
          </IonCol>
        </IonRow>
      </IonCard>

      <ModalReleaseReport
        close={() => setOpenModalReleaseReport(false) && setReportToModal(null)}
        modalState={openModalReleaseReport}
        patient={patient}
        report={reportToModal}
      />
      <ModalReviewReport
        close={() => setModalReviewReport(false) && setReportToModal(null)}
        modalState={modalReviewReport}
        patient={patient}
        reloadList={props.reloadList}
        report={reportToModal}
      />
      <ModalViewReport
        close={() => setModalViewReport(false) && setReportToModal(null)}
        modalState={modalViewReport}
        patient={patient}
        report={reportToModal}
      />
    </>
  )
}

export default PatientListItem
