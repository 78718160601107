import React from 'react'

import { IonIcon, IonRow, IonCol, IonButton } from '@ionic/react'
import { alertCircleSharp, mailSharp, callSharp } from 'ionicons/icons'

import content from 'util/content'
import { Modal } from 'components/basepaws/general/Modal/Modal'

export const DeactivateAccountModal = (props) => {
  const { modalState, close } = props

  const buttons =
  <>
    <IonButton
      mode="md"
      fill="solid"
      shape="round"
      color="primary"
      href="mailto:help@basepaws.com?subject=Deactivate account"
      className="big_button icon-start ion-margin-top mb-2"
    >
      <IonIcon color="light" icon={mailSharp}/>
      {content.get('PROFILE.DEACTIVATE_MODAL.CTA1.EMAIL')}
    </IonButton>
    <IonButton
      mode="md"
      fill="outline"
      shape="round"
      color="primary"
      href="tel:888-636-4691"
      className="big_button icon-start"
    >
      <IonIcon color="primary" icon={callSharp}/>
      {content.get('PROFILE.DEACTIVATE_MODAL.CTA1.PHONE')}
    </IonButton>
  </>

  const alertDiv = (
      <div className="alert_message alert_form warning w-100">
        <div className="input-error-label Body_2_bold">
          <IonIcon icon={alertCircleSharp} color="warning" slot="start"/>
          {content.get('PROFILE.DEACTIVATE_MODAL.BOX.TITLE')}
        </div>
        <p className="Body_2 text_dark_grey">
          {content.get('PROFILE.DEACTIVATE_MODAL.BOX.DESCRIPTION')}
        </p>
      </div>
  )

  const modalContent =
  <>
    <IonRow className="ion-justify-content-center ion-justify-content-between">
      <IonCol size-md="12" size="12">
        {alertDiv}
        {buttons}
      </IonCol>
    </IonRow>
  </>

  return (
    <Modal
      {...props}
      modalState={modalState}
      headingText={content.get('PROFILE.DEACTIVATE_MODAL.HEADER')}
      close={close}
    >
      {modalContent}
    </Modal>
  )
}
