import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IonRow, IonCol, IonIcon, useIonViewWillEnter } from '@ionic/react'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import { alertSharp, checkmarkSharp } from 'ionicons/icons'
import servicesNew from 'util/services'
import content from 'util/content'

import styles from './VetEmailValidation.module.scss'

const VetEmailValidation = () => {
  const history = useHistory()

  const user = useSelector(state => state.userInReducer.user)
  const [emailIsValid, setEmailIsValid] = useState()
  const [validatingEmail, setValidatingEmail] = useState(true)
  const [codeError, setCodeError] = useState(0)

  const codeErrorContent = [
    {
      code: 0,
      subtitle: content.get('vet_email_validation.somethingFailed0.subtitle'),
      description: content.get('vet_email_validation.somethingFailed0.description')
    },
    {
      code: 1,
      subtitle: content.get('vet_email_validation.noIdFound1.subtitle'),
      description: content.get('vet_email_validation.noIdFound1.description')
    },
    {
      code: 2,
      subtitle: content.get('vet_email_validation.accountAlreadyCreated2.subtitle'),
      description: content.get('vet_email_validation.accountAlreadyCreated2.description')
    },
    {
      code: 3,
      subtitle: content.get('vet_email_validation.userCreationError3.subtitle'),
      description: content.get('vet_email_validation.userCreationError3.description')
    },
    {
      code: 4,
      subtitle: content.get('vet_email_validation.noUserFound4.subtitle'),
      description: content.get('vet_email_validation.noUserFound4.description')
    },
    {
      code: 5,
      subtitle: content.get('vet_email_validation.organizationCreationError5.subtitle'),
      description: content.get('vet_email_validation.organizationCreationError5.description')
    }
  ]

  useIonViewWillEnter(async () => {
    await validateHashUrl()
  })

  const validateHashUrl = async () => {
    setValidatingEmail(true)
    const hash = window.location.hash?.substring(1)

    if (hash) {
      try {
        await servicesNew.validateEmail(hash)
        setEmailIsValid(true)
      } catch (error) {
        if (error?.error) {
          setCodeError(error.code)
        } else {
          setCodeError(0)
        }
        setEmailIsValid(false)
      }
    } else {
      history.push('/error')
    }
    setValidatingEmail(false)
  }
  const currentErrorContent = codeErrorContent.find((item) => item.code === codeError)

  return (
    <GeneralPage
      headerStyle='center'
      title={!validatingEmail ? content.get(emailIsValid ? 'vet_email_validation.title.success' : 'vet_email_validation.title.error') : ''}
      user={user}
    >
      <IonRow className='ion-justify-content-center mt-5'>
        <IonCol className='ion-text-center'
                size="10"
                size-md="8">
          {/* Success */}
          {(!validatingEmail && emailIsValid) && <>
            <span className={`${styles.circle} ${styles.success}`}>
              <IonIcon color='success'
                       src={checkmarkSharp} />
            </span>
            <h1 className='Heading_2 text_primary ion-text-center'>{content.get('vet_email_validation.email_valid.title')}</h1>
            <p className='Lead text_grey ion-text-center'>{content.get('vet_email_validation.email_valid.text')}</p>
          </>
          }

          {/* Error */}
          {(!validatingEmail && !emailIsValid) && <>
            <span className={`${styles.circle} ${styles.error}`}>
              <IonIcon color='danger'
                       src={alertSharp} />
            </span>
            <h1 className='Heading_2 text_primary ion-text-center'>{currentErrorContent.subtitle}</h1>
            <p className='Lead text_grey ion-text-center'
               dangerouslySetInnerHTML={{ __html: currentErrorContent.description }}
            />
          </>
          }
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default VetEmailValidation
