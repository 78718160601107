import React from 'react'
import { IonCard, IonButton, IonCardContent, IonCardHeader, IonCardTitle, IonRow, IonCol } from '@ionic/react'
// import routes from '../../../../constants/routes.json'
import content from 'util/content'
import styles from './ProfileAdvertisingCard.module.scss'

const AdvertisingCardItem = () => {
  return (
    <IonCard className={`${styles.my_profile_welcome_card} card`}>
    <IonCardHeader>
      <IonCardTitle className="Running_head_1 text_white">{content.get('DASHBOARD.BANNER.TITLE')}</IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonRow>
        <IonCol size-lg="8" size-md="10" size="9">
          <h2 className="Heading_3 text_white">{content.get('DASHBOARD.BANNER.SUBHEAD')}</h2>
          <p className="Body_1 text_white">{content.get('DASHBOARD.BANNER.DESCRIPTION')}</p>
        </IonCol>
      </IonRow>
    </IonCardContent>
    <div className={styles.card_footer}>
      <IonButton
        mode="md"
        slot="end"
        shape="round"
        color="light"
        fill="solid"
        routerLink={content.get('DASHBOARD.BANNER.CTA_LINK')}
        className="mr-2 default_button"
        >
          {/* <IonIcon slot="start" icon={enterSharp} /> */}
          {content.get('DASHBOARD.BANNER.CTA_TITLE')}
        </IonButton>
    </div>
    <img src="assets/images/basepaws-banner-dental-test.png" alt="Basepaws CatKit dental test" />
    </IonCard>
  )
}

export default AdvertisingCardItem
