import { IonInput } from '@ionic/react'
import { useField } from 'formik'
import React, { useContext } from 'react'
import { FormContext } from '../Form/FormContext'
import moment from 'moment'
import { Row } from '../Form/Row'
import { Static } from '../Form/Static'
export const Age = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, name, label, multipleValueLabel } = props

  const [field, { value }] = useField(name)

  const date = value ? new Date(value) : null
  const today = moment(new Date())
  const years = today.diff(date, 'years')
  const months = today.diff(date, 'months') % 12
  if (!editable) {
    let str
    if (!years && !months) str = '--'
    if (years && !months) str = `${years}y`
    if (!years && months) str = `${months}m`
    if (years && months) str = `${years}y ${months}m`
    return (
    <Row
      label={label}
      multiple = {multipleValueLabel}
    >
      {<Static
        multiValues = {multipleValueLabel}>{str}</Static>}
    </Row>
    )
  }

  if (editable) {
    return (
    <Row label={label}>
      <div className={styles.d_md_flex}>
        <IonInput
          className={`mb-2 ${styles.input_w_100}`}
          disabled
          placeholder="Years"
          value={years || (value ? 0 : '')}
        />
        <IonInput
          className={`mb-2 ${styles.input_w_100}`}
          disabled
          placeholder="Months"
          value={months || (value ? 0 : '')}
        />
      </div>
    </Row>)
  }
}

export default Age
