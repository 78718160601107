import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { IonCol, IonGrid, IonRow, useIonViewWillEnter, useIonViewDidLeave, useIonViewDidEnter } from '@ionic/react'
import { alertCircleSharp } from 'ionicons/icons'
import { object, string } from 'yup'

import NewForm from 'components/basepaws/form/Form/Form'
import { Button, Password, Radio, Message, DateField, Note, UserFind, AnimalFind, CheckList, Age, Error, AutoSave, Boolean } from 'components/basepaws/form'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import Toaster from 'components/basepaws/utils/Toaster'
import { Row } from 'components/basepaws/form/Form/Row'
import services, { servicesNew } from 'util/services'
import content from 'util/content'
import delay from 'util/delay'

import testTypes from './testTypes'
import { RequisitionHeader } from './RequisitionHeader'
import EditableRequisitionEmail from './EditableRequisitionEmail'
import { LegalDisclaimer } from './LegalDisclaimer'
import deceases from './deceases'

import styles from './RequisitionFormPage.module.scss'

const NewRequisitionFormPage = (params) => {
  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()

  const [data, setData] = useState({})
  const [toast, setToast] = useState()
  const [kitError, setKitError] = useState()
  const [urlHandle, setUrlHandle] = useState()
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [errorMessageSubmit, setErrorMessageSubmit] = useState('Something went wrong. Please try again')
  const [validChosenOrganism, setValidChosenOrganism] = useState(true)

  const clinicIcon = '/assets/icon/data/clinic.svg'

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  useIonViewDidEnter(() => {
    document.title = 'Requisition Form - Vet Portal - Basepaws'
  })

  useIonViewDidLeave(() => {
    setUrlHandle(null)
  })

  useIonViewWillEnter(async () => {
    if (!user) {
      await load()
    }

    const id = params.match?.params?.handle
    let req = { editable: true }
    if (id) {
      req = (await servicesNew.get(`/requisitionForm?handle=${id}`))[0]
      req.kit.code_repeat = req.kit.code
      if (req.submit) req.review = true
    }
    req.date = req.date || new Date()
    setData(req)
  }, [params.match.params.handle])

  const loadOwners = async (value) => {
    if (!value) return null
    value = value.toLowerCase()
    const list = (await servicesNew.getOrganizationOwners(user.organization?.id))
      .filter(item => item?.first_name?.toLowerCase().match(value) || item?.last_name?.toLowerCase().match(value))
      .map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}   ${item.email}`,
        ...item
      })
      )

    return list
  }

  const loadAnimals = async (value) => {
    if (!value) return null
    value = value.toLowerCase()
    const list = (await servicesNew.getOrganizationPatients(user.organization?.id))
      .filter(item => item.name.toLowerCase().match(value))
      .map(item => ({
        value: item.id,
        label: `${item.name} · ${item.owner.first_name} ${item.owner.last_name}  ${item.owner.email}`,
        ...item
      })
      )

    return list
  }

  const newRequisition = async (code, repeat) => {
    if (code !== repeat || !code || !repeat) {
      setKitError(null)
      return
    }
    try {
      const kit = await servicesNew.get(`/kit/${code}`)
      if (kit.user) {
        return setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.KIT_ALREADY_ACTIVATED') })
      }
      if (kit.user_type !== 'vet') {
        return setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.INVALID_USER_TYPE') })
      }

      const requisitions = await servicesNew.get(`/requisitionForm?kit.code=${code}`)
      if (requisitions.length) {
        const temp = { ...data }
        temp.kit = { id: kit.id, code_repeat: kit.code, code: kit.code, productName: kit.productName }
        temp.species = kit.organism
        setData(temp)
        setKitError({ message: content.get('REQUISITION_FORMS_LOG.ERROR.KIT_CREATED_SAMPLE', { sample: requisitions.map(i => i.handle).join(', ') }) })
        return
      }

      const req = await servicesNew.post('/requisitionForm/', { kit: { id: kit.id, code: kit.code, productName: kit.productName }, species: kit.organism, organization_id: user.organization?.id, date: new Date() })
      servicesNew.clearCache('requisition')
      req.kit.code_repeat = req.kit.code
      req.species = kit.organism
      req.review = false

      setKitError(null)
      setToast(content.get('REQUISITION_FORMS_LOG.TOAST.SAVED'))
      setData(req)
    } catch (e) {
      if ('error' in e) {
        setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.INVALID_BARCODE') })
      } else {
        setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.INTERNAL_ERROR'), icon: alertCircleSharp })
      }
    }
  }

  const save = async (values) => {
    console.log('save', values)
    if (!values.id) await newRequisition(values.kit?.code, values.kit?.code_repeat)
    if (!values.id) return

    if (values.species !== data?.species) {
      setValidChosenOrganism(false)
    }

    // Vet selection removed: https://zoetis.atlassian.net/browse/WEB-158
    // Customer collect sample removed: https://zoetis.atlassian.net/browse/WEB-232
    const valuesWithHardcodedInfo = { ...values, no_vet: true, customer_collect_sample: false }

    const req = await servicesNew.put(`/requisitionForm/${valuesWithHardcodedInfo.id}`, valuesWithHardcodedInfo)
    if (req.handle && req.url) setUrlHandle({ handle: req.handle, url: req.url })

    servicesNew.clearCache('requisition')
    await validate.validate(values)
  }

  const onSubmit = async (values) => {
    console.log('onSubmit', values)
    setErrorSubmit(false)
    if (!values.id) return
    try {
      await servicesNew.put(`/requisitionForm/${values.id}/submit`)
      servicesNew.clearCache()
      history.push({
        pathname: '/requisition/success',
        state: {
          handle: urlHandle.handle,
          id: values.id,
          url: urlHandle.url
        }
      })
    } catch (error) {
      if (error?.error && typeof error.error === 'string') {
        setErrorMessageSubmit(error.error)
      } else {
        setErrorMessageSubmit('Something went wrong. Please try again')
      }
      setErrorSubmit(true)
    }
  }

  const validate = object().shape({
    species: string().required()
  })

  useEffect(() => {
    // This is executed when loading the RF page directly
    // without coming from RF Logs page
    if (user.organization) {
      const req = { ...data }
      req.accountId = user.organization.id
      req.clinic = {
        first_name: user.organization.company,
        last_name: '',
        email: user.organization.email
      }
      setData(req)
    }
  }, [data.review])

  const editableRequisitionEmailSubmit = async (_email) => {
    const newData = { ...data, pet: { ...data.pet, owner: { ...data.pet.owner, email: _email } } }
    const updatedReq = await servicesNew.put(`/requisitionForm/${newData.id}`, newData)
    servicesNew.clearCache('requisition')
    servicesNew.clearCache('patients')
    // slight delay so user can see the feedback
    await delay(1000)
    updatedReq.review = true
    setData(updatedReq)
  }

  return (
    <GeneralPage
      headerStyle='left'
      isNotFixed={true}
      pageClass="page-no-margin"
      title={content.get('requisition_forms_log.newform.title')}
      user={user}
    >
      <NewForm
        editable={!data.review}
        initialValues={data}
        onSubmit={save}
        styles={styles}
      >
        {({ values, isValid, setFieldValue }) => (
          <>
            {(urlHandle?.handle) &&
              <RequisitionHeader handle={urlHandle.handle }
                                 name='reqData'
                                 onCopy={e => setToast(content.get('REQUISITION_FORMS_LOG.TOAST.COPY_CLIPBOARD'))}
                                 url={urlHandle.url}/>
            }
            <IonGrid
                className={`${styles.new_form_styles} form`}
                fixed
              >
              <Toaster header={content.get('requisition_forms_log.newform.toast')}
                       message={toast}
                       onDidDismiss={() => setToast(null)}
                       status={toast != null}
              />

              {!values.review &&
                <Message
                  className='mt-3 mb-4'
                  info
                  pink
                  styles={styles}>
                    {content.get('requisition_forms_log.newform.required_fields')}
                </Message>
              }

              {values.review &&
                <>
                  <Password
                    bold
                    disabled={true}
                    editable={false}
                    label={content.get('requisition_forms_log.newform.bp_account_label')}
                    name='accountId'
                  />
                  <UserFind
                    bold
                    disabled={true}
                    editable={false}
                    label={content.get('requisition_forms_log.newform.clinic_label')}
                    name='clinic'
                    userIcon={clinicIcon}
                  />
                </>
              }

              <Password bold
                        disabled={values.id}
                        editable={!values.review}
                        errors={kitError && (('icon' in kitError)
                          ? <Message
                        icon={kitError.icon}
                        info
                        pink
                        styles={styles}>{kitError.message}</Message>
                          : <Error>{kitError.message}</Error>)}
                        label={content.get('requisition_forms_log.newform.barcode_label')}
                        name='kit.code'
                        placeholder={content.get('requisition_forms_log.newform.barcode_placeholder')}
              />

              <div className={`${values.review ? '' : 'my-4'}`}>
                <Radio
                  bold
                  disabled
                  editable={!values.review}
                  label={content.get('requisition_forms_log.newform.test_based_on')}
                  list={testTypes}
                  name='kit.productName'>
                  <Message
                    accent
                    info
                    m0
                    styles={styles}>
                      <span dangerouslySetInnerHTML={{ __html: content.get('requisition_forms_log.newform.contactus') }}/>
                  </Message>
                </Radio>
              </div>

              <div className={`${values.review ? '' : 'my-4'}`}>
                <DateField
                  bold
                  editable={!values.review}
                  label={content.get('requisition_forms_log.newform.collection_date_label')}
                  name='date'
                  placeholder={content.get('requisition_forms_log.newform.collection_date_placeholder')}
                  required
                />
              </div>

              <div className={`${values.review ? '' : 'my-4'}`}>
                <Note
                  bold
                  clarification='(if needed)'
                  editable={!values.review}
                  label={content.get('requisition_forms_log.newform.annotation_label')}
                  name='note'
                  placeholder={content.get('requisition_forms_log.newform.annotation_placeholder')}
                  sublabel='200 characters limit'
                />
              </div>

              { values.review && values.vet
                ? <div>
                    <UserFind
                      bold
                      editable={false}
                      label={values.review && content.get('requisition_forms_log.newform.user_find_label')}
                      name='vet'
                      placeholder={content.get('requisition_forms_log.newform.user_find_placeholder')}
                    />
                  </div>
                : null
              }

              <div className={`${values.review ? '' : 'my-4'}`}>
                <AnimalFind
                  bold
                  components={{ email: values.submit && !data.pet?.owner?.email ? <EditableRequisitionEmail onSubmitCallback={ editableRequisitionEmailSubmit}/> : null }}
                  editable={!values.review}
                  label={content.get('requisition_forms_log.newform.patient_label')}
                  name='pet'
                  newOptionOnEmpty={true}
                  onChange={animal => {
                    setFieldValue('birthday', animal.born_on)
                    setFieldValue('gender', animal.gender)
                    setFieldValue('spayed', animal.spayed)
                    setFieldValue('species', animal.species)
                  }}
                  options={{ animal: loadAnimals, owner: loadOwners }}
                  placeholder={content.get('requisition_forms_log.newform.patient_placeholder')}
                  required
                />
              </div>

              <div className={`${styles.patient_fields} ${values.review ? '' : 'my-4'}`}>
                <DateField
                  editable={!values.review && !values.pet?.id}
                  label={content.get('requisition_forms_log.newform.birthdate_label')}
                  multipleFirst
                  name='birthday'
                  placeholder={content.get('requisition_forms_log.newform.birthdate_placeholder')}
                  required
                  />
                <Age
                  editable={!values.review && !values.pet?.id}
                  label={content.get('requisition_forms_log.newform.age_label')}
                  multipleValueLabel
                  name='birthday'
                  required
                  />
                <Radio
                  disabled
                  editable={!values.review && !values.pet?.id}
                  label={content.get('requisition_forms_log.newform.species_label')}
                  list={[{ label: 'requisition_forms_log.newform.canine', value: 'dog' }, { label: 'requisition_forms_log.newform.feline', value: 'cat' }]}
                  multipleValueLabel
                  name='species'
                  required />
                <Radio
                  editable={!values.review && !values.pet?.id}
                  label={content.get('requisition_forms_log.newform.gender')}
                  list={[{ label: 'requisition_forms_log.newform.female', value: 'female' }, { label: 'requisition_forms_log.newform.male', value: 'male' }]}
                  multipleValueLabel
                  name='gender'
                  required />
                <Radio
                  editable={!values.review && !values.pet?.spayed}
                  label={content.get('requisition_forms_log.newform.spayed')}
                  list={[{ label: 'requisition_forms_log.newform.yes', value: 'yes' }, { label: 'requisition_forms_log.newform.no', value: 'no' }]}
                  multipleValueLabel
                  name='spayed' />
              </div>

              <CheckList clarification='(if applicable)'
                         editable={!values.review}
                         info={values.species == null && content.get('requisition_forms_log.newform.species_diseases')}
                         label={content.get('requisition_forms_log.newform.diagnosed_diseases')}
                         list={deceases[values.species]}
                         multipleValueLabel
                         name='diagnosed_diseases'
              />

              <Boolean
                customClassName={`${styles.checkbox_no_border} ${styles.checkbox_p} ${styles.align_content_start}`}
                editable={!values.review}
                label={values.review && content.get('requisition_forms_log.newform.participate_research')}
                name='accept_research'
              >
                <p className='Body_small_regular text_dark blue_bold'
                   dangerouslySetInnerHTML={{
                     __html: content.get('requisition_forms_log.newform.acceptance')
                   }}
                />
              </Boolean>

              <AutoSave onSave={save}/>

              {!values.submit && !values.review &&
              <IonRow className='ion-justify-content-end mt-4'>
                <IonCol
                  className={styles.confirmColumn}
                  size='12'
                  size-md='8'
                  >
                  <Button
                    disabled={ !isValid || !validChosenOrganism}
                    info={
                      (!isValid || !validChosenOrganism) &&
                        <div>
                          <span className={`${styles.d_sm_block} Body_small_regular text_dark`}>
                            {!isValid ? content.get('requisition_forms_log.newform.invalid_form') : ''}
                            {!validChosenOrganism
                              ? 'The kit you are trying to activate is for a different species than the patient you selected.'
                              : ''
                            }
                          </span>
                          {!validChosenOrganism
                            ? (<>
                                <br/>
                                <span className={`${styles.d_sm_block} Body_small_regular text_dark`}>
                                    Please <a className='underline Body_small_bold text_accent'
                                              href='javascript:window.location.reload(true)'>click here</a> to reload the page and start again.
                                </span>
                              </>)
                            : null}
                        </div>
                      }
                    onClick={e => {
                      // This is to use the organization fields when coming from a new/editable RF
                      setFieldValue('accountId', user.organization.id)
                      setFieldValue('clinic', {
                        first_name: user.organization.company,
                        last_name: '',
                        email: user.organization.email
                      })
                      setFieldValue('review', true)
                    }}>
                    {content.get('requisition_forms_log.newform.confirm')}
                  </Button>
                </IonCol>
              </IonRow>
              }

              {(values?.pet?.owner?.first_name && !values?.pet?.owner?.email) && <>
                <Row>
                    <Message
                      icon={alertCircleSharp}
                      info
                      red
                      styles={styles}>
                        {content.get('REQUISITION_FORMS_LOG.NEWFORM.OWNER_WITHOUT_EMAIL')}
                    </Message>
                </Row>
              </>}

              {!values.submit && values.review && <>
                <IonRow>
                  <IonCol
                    size='12'
                    size-md='4'>
                  </IonCol>
                  <IonCol
                    className={styles.confirmColumn}
                    size='12'
                    size-md='5'>
                    <div className='d-flex ion-justify-content-between'>
                      <Button
                        onClick={() => onSubmit(values)}>{content.get('requisition_forms_log.newform.submit')}
                      </Button>
                      <Button
                        color='dark'
                        fill='outline'
                        onClick={e => setFieldValue('review', false)}>{content.get('requisition_forms_log.newform.edit_submit')}</Button>
                    </div>
                    {errorSubmit && <div className='d-flex ion-justify-content-between errorMessageSubmit'>
                      {errorMessageSubmit}
                    </div>}
                  </IonCol>
                </IonRow>
              </>}

              <IonRow>
                <IonCol
                    size='12'
                    size-md='4'>
                </IonCol>
                <IonCol size-md='6'>
                  <LegalDisclaimer/>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
      </NewForm>
    </GeneralPage>
  )
}

export default NewRequisitionFormPage
