import React from 'react'

import { IonFooter, IonGrid, IonRow, IonCol } from '@ionic/react'

import styles from './Footer.module.scss'
import content from 'util/content'

const Footer = () => {
  return (
    <>
      <IonFooter>
        <div className={styles.pattern}></div>
        <IonGrid fixed>
          <IonRow className="ion-margin-top ion-justify-content-center">
            <IonCol
              size="12"
              size-md="6">
              <div className={`${styles.email_us_wrapper}`}>
                <p className="Body_regular text_dark">{content.get('FOOTER.EMAIL_US.TITLE')}</p>
                <p className="Body_bold text_accent">
                  <a
                    className='Body_bold'
                    href={`mailto:${content.get('FOOTER.EMAIL')}`}
                    rel="noreferrer"
                    target="_blank">{content.get('FOOTER.EMAIL')}</a></p>
              </div>
            </IonCol>
            <IonCol
              className={styles.links_wrapper}
              size="12"
              size-md="6">
              <p>
                <a
                  className='Body_bold text_dark'
                  href={content.get('FOOTER.EXTERNALS.LINK_URL1')}
                  rel="noreferrer"
                  target="_blank">{content.get('FOOTER.EMAIL_US.FAQS')}</a>
                <span className="Body_regular text_light mx-2">  |  </span>
                <a
                  className='Body_bold text_dark'
                  href={content.get('FOOTER.EXTERNALS.LINK_URL2')}
                  rel="noreferrer"
                  target="_blank">{content.get('FOOTER.EMAIL_US.TERMS_OF_SERVICE')}</a>
                <span className="Body_regular text_light mx-2">  |  </span>
                <a
                  className='Body_bold text_dark'
                  href={content.get('FOOTER.EXTERNALS.LINK_URL3')}
                  rel="noreferrer"
                  target="_blank">{content.get('FOOTER.EMAIL_US.PRIVACY')}</a>
              </p>
            </IonCol>
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <IonCol
              size="12"
              size-md="6">
              <img
                alt="Basepaws logo"
                className={styles.footer_brand}
                src="assets/images/basepaws-footer-logo.png" />
            </IonCol>
            <IonCol
              className={styles.footer_credentials}
              size="12"
              size-md="6">
              <p className='Body_small text_grey'>{content.get('FOOTER.EMAIL_US.ALL_RIGHTS_RESERVED ')}</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>
    </>
  )
}

export default Footer
