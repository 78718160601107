import React, { IonCol, IonIcon, IonRow } from '@ionic/react'
import { informationCircleSharp } from 'ionicons/icons'
import { useContext } from 'react'
import { FormContext } from './FormContext'
import Message from '../Message'

export const Row = (props) => {
  const context = useContext(FormContext)
  const { info, styles = context.styles, editable = context.editable, repeat, name, label, bold, className, sublabel, clarification, multiple, small } = props

  return (
    <IonRow className={className}>
    <IonCol
      className={`${styles.labels_wrapper}`}
      size='12'
      size-md="4"
    >
      <p className={`${multiple && styles.multiple} ${bold && 'Body_bold'} text_primary mb-2 mr-2 my-3`}>{label}
      {clarification && <span className='Body_regular text_primary ml-1'>{clarification}</span>}
      </p>
      {sublabel && <p className={'Body_small_regular text_light mr-2 my-1'}>{sublabel}</p>}
    </IonCol>
    <IonCol className='w-100'
            size='12'
            size-md={small ? '4' : '6'}>
        {info && <Message
        accentSecondary
        info
        mx7
        >
          {info}
        </Message>}
      {props.children}
    </IonCol>
  </IonRow>
  )
}
