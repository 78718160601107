import React from 'react'
import Select, { components } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import './InputSelect.scss'
import { IonIcon } from '@ionic/react'
import { search } from 'ionicons/icons'

const Highlighter = require('react-highlight-words')

function formatGroupLabel (group: any) {
  return (<div className='group'>{group.label}</div>)
};
function formatOptionLabel (param1: any, param2: any) {
  const { label } = param1
  const { inputValue } = param2
  if (typeof (label) !== 'string') return label

  return (<Highlighter
    matchClass="bold"
    searchWords={[inputValue]}
    textToHighlight={label}
  />)
};

const Input = (props: any) => {
  return (
    <components.Input data-qa={props.selectProps.name}
                      {...props}
                      className="selectInput">
    </components.Input>
  )
}

const InputSelect = (props: any) => {
  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      background: props.disabled && 'repeating-linear-gradient(-45deg, #FFF, #FFF 10px, rgba(240, 242, 247, 0.35) 10px, rgba(240, 242, 247, 0.35) 20px)',
      flexDirection: 'row-reverse',
      borderColor: '#F0F2F7',
      borderRadius: '8px'
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      paddingLeft: '16px'
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      borderBottom: 'solid 1px #EEEEEE',
      color: state.isSelected ? '#A9A9A9' : '#212121'
    }),
    group: (provided: any, state: any) => ({
      ...provided,
      color: '#A9A9A9'
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      zIndex: '1000000'
    })
  }

  const loader = (value: string, clback: any) => {
    const parse = JSON.parse(localStorage.getItem(`history_${props.name}`)!)
    const back = (data: any, label: string) => {
      if (data && data.length > 0) {
        return clback([{ label: label, options: data }])
      } else {
        return clback([{ label: 'No options', options: null, disabled: true }])
      }
    }

    if (!value) {
      if (parse) {
        back(parse.reverse().slice(0, 5), 'Recent Searches')
      } else {
        props.options('')?.then((data: object) => {
          back(data, 'Your Vets')
        })
      }
    } else {
      props.options(value)?.then((data: object) => {
        clback(data, 'Your Vets')
      })
    }
  }

  return (
    typeof (props.options) === 'function'
      ? <AsyncCreatableSelect
        {...props}
        cacheOptions
        className={`${props.disabled && 'disabled'} select w-100`}
        components={{
          Input: Input,
          // eslint-disable-next-line react/display-name
          DropdownIndicator: () => <IonIcon icon={search}
                                            slot="start" />,
          IndicatorSeparator: () => null
        }}
        defaultOptions
        disabled={props.disabled}
        formatCreateLabel={props.createLabel}
        formatGroupLabel={formatGroupLabel}
        formatOptionLabel={formatOptionLabel}
        isDisabled={props.disabled}
        isOptionDisabled={(option) => option.disabled}
        isValidNewOption={() => props.newOptionOnEmpty}
        loadOptions={loader}
        onChange={(value: any) => {
          const ls = JSON.parse(localStorage.getItem('history_' + props.name)!)
          if (ls) {
            if (!(ls.map((el: any) => el.value).includes(value?.value))) {
              const arr = [...ls, value]
              localStorage.setItem('history_' + props.name, JSON.stringify(arr))
            }
          } else localStorage.setItem('history_' + props.name, JSON.stringify([value]))
          props.onChange(value)
        }}
        onCreateOption={props.onCreate}
        styles={customStyles}
      />
      : <Select
        allowCreateWhileLoading={true}
        className="select w-100"
        isDisabled={props.disabled}
        onChange={props.onChange}
        {...props}
        styles={customStyles}
      />
  )
}

export default InputSelect
