import { IonButton, IonCol, IonInput, IonRow } from '@ionic/react'
import { useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'

export const Submit = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, frame = true, options, name, label, placeholder, disabled, space, submit, content } = props
  const formik = useFormikContext()
  const { errors } = formik

  return (
    <Row label={space ? ' ' : label}>
       <div className="d-flex ion-align-items-center">
          <IonButton
            className="default_button mr-3"
            color="primary"
            disabled={disabled}
            fill="solid"
            shape="round"
            type='submit'
          >
            {content || 'Submit'}
          </IonButton>

          {props.children}
        </div>
    </Row>
  )
}
