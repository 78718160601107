import React, { useState } from 'react'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonButton, IonListHeader, IonCardContent } from '@ionic/react'
import { closeCircle, eye, eyeOff, checkmarkCircle, ellipseOutline } from 'ionicons/icons'

import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { servicesNew } from 'util/services'
import Toaster from 'components/basepaws/utils/Toaster'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import styles from './RecoverPasswordFormPassword.module.scss'
import content from 'util/content'

const RecoverPasswordFormPassword = (otp) => {
  const [saved, setSaved] = useState(false)
  const [passwordFieldType, setPasswordFieldType] = useState({
    currentPassword: 'password',
    currentPasswordIcon: eye,
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: null,
    number: null,
    size: null,
    equal: null
  })

  const registerSchema = Yup.object().shape({
    password: Yup.string().test(
      'password-valid',
      '',
      () => passwordCheck.uppercase && passwordCheck.number && passwordCheck.size && passwordCheck.equal
    )
  })

  const history = useHistory()

  const onSubmit = async (values, setSubmitting) => {
    const passwords = {
      temporaryCode: otp.otp,
      password: password,
      password_repeat: passwordRepeat
    }
    setSubmitting(true)
    try {
      await servicesNew.post('/auth/changePassword', passwords).then(() => setSaved(true))
    } catch (e) {
      console.log(e)
    }
    history.push('/login')
  }

  const onChangePassword = (e) => {
    const passwordLocal = e.target.value || ''

    const check = passwordCheck
    check.uppercase = /[A-Z]/.test(passwordLocal)
    check.number = /[0-9]/.test(passwordLocal)
    check.size = passwordLocal.length >= 6
    check.equal = (passwordLocal === passwordRepeat)

    setPassword(passwordLocal)
    setPasswordCheck(check)
  }

  const onChangePasswordRepeat = (e) => {
    const passwordLocal = e.target.value || ''

    const passwordEqual = (passwordLocal === password)
    setPasswordRepeat(passwordLocal)
    setPasswordCheck({
      ...passwordCheck,
      equal: passwordEqual
    })
  }

  const changeType = (field) => {
    const currentType = passwordFieldType[field]
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      [field]: nextType,
      [`${field}Icon`]: icon
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        temporaryCode: otp.otp,
        password: '',
        passwordRepeat: ''
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
      validationSchema={registerSchema}
    >
      {({
        values,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <Toaster
            header={content.get('RECOVERPASSWORD.PASSWORD.TOAST.TITLE')}
            message={content.get('RECOVERPASSWORD.PASSWORD.TOAST.TEXT')}
            onDidDismiss={() => setSaved(false)}
            status={saved}
          />
          <IonCardContent className={styles.card_content}>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <IonList className="form ion-margin-top">
                  <IonItem className="item-no-padding"
                           lines="none">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_primary">{content.get('RECOVERPASSWORD.FORM.NEW_PASSWORD.TITLE')}</h4>
                        <p className="Body_small_regular text_grey">{content.get('RECOVERPASSWORD.FORM.NEW_PASSWORD.CONTENT')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="item-no-padding-left"
                           lines="none">
                    <IonInput
                      className={`input-w-icon ${passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                      clearOnEdit={false}
                      name="password"
                      onIonInput={onChangePassword}
                      type={passwordFieldType.password}
                      value={values.password}
                    >
                      <IonButton fill="clear"
                                 mode="md"
                                 onClick={() => changeType('password')}
                                 slot="end"
                                 tabindex="-1">
                        <IonIcon color="dark"
                                 icon={passwordFieldType.passwordIcon}
                                 slot="icon-only"></IonIcon>
                      </IonButton>
                    </IonInput>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding">
                    <IonRow className="w-100">
                      <IonCol size="auto">
                        <IonButton className="button-form-check Body_small_regular text_dark"
                                   disabled
                                   fill="clear"
                                   mode="md"
                                   size="small">
                          {passwordCheck.uppercase === null && <IonIcon icon={ellipseOutline}
                                                                        slot="start" />}
                          {passwordCheck.uppercase === true && <IonIcon color="success"
                                                                        icon={checkmarkCircle}
                                                                        slot="start" />}
                          {passwordCheck.uppercase === false && <IonIcon color="danger"
                                                                         icon={closeCircle}
                                                                         slot="start" />}
                          {content.get('RECOVERPASSWORD.FORM.ERROR.UPPERCASE')}
                        </IonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <IonButton className="button-form-check Body_small_regular text_dark ml-2"
                                   disabled
                                   fill="clear"
                                   mode="md"
                                   size="small">
                          {passwordCheck.number === null && <IonIcon icon={ellipseOutline}
                                                                     slot="start" />}
                          {passwordCheck.number === true && <IonIcon color="success"
                                                                     icon={checkmarkCircle}
                                                                     slot="start" />}
                          {passwordCheck.number === false && <IonIcon color="danger"
                                                                      icon={closeCircle}
                                                                      slot="start" />}
                          {content.get('RECOVERPASSWORD.FORM.ERROR.NUMBER')}
                        </IonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <IonButton className="button-form-check Body_small_regular text_dark ml-2"
                                   disabled
                                   fill="clear"
                                   mode="md"
                                   size="small">
                          {passwordCheck.size === null && <IonIcon icon={ellipseOutline}
                                                                   slot="start" />}
                          {passwordCheck.size === true && <IonIcon color="success"
                                                                   icon={checkmarkCircle}
                                                                   slot="start" />}
                          {passwordCheck.size === false && <IonIcon color="danger"
                                                                    icon={closeCircle}
                                                                    slot="start" />}
                          {content.get('RECOVERPASSWORD.FORM.ERROR.MIN_LENGTH')}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                  <IonItem className="item-no-padding"
                           lines="none">
                    <IonListHeader className="ion-no-padding ion-margin-bottom">
                      <IonLabel>
                        <h4 className="Heading_5 text_primary">{content.get('RECOVERPASSWORD.FORM.NEW_PASSWORD_CONFIRM.TITLE')}</h4>
                        <p className="Body_small_regular text_grey">{content.get('RECOVERPASSWORD.FORM.NEW_PASSWORD_CONFIRM.CONTENT')}</p>
                      </IonLabel>
                    </IonListHeader>
                  </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding-left"
                           lines="none">
                    <IonInput
                      className={`input-w-icon ${(passwordCheck.equal != null && passwordCheck.equal === false) || passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                      clearOnEdit={false}
                      name="passwordRepeat"
                      onIonInput={onChangePasswordRepeat}
                      type={passwordFieldType.passwordRepeat}
                      value={values.passwordRepeat}
                    >
                      <IonButton fill="clear"
                                 mode="md"
                                 onClick={() => changeType('passwordRepeat')}
                                 slot="end"
                                 tabindex="-1">
                        <IonIcon color="dark"
                                 icon={passwordFieldType.passwordRepeatIcon}
                                 slot="icon-only"></IonIcon>
                      </IonButton>
                    </IonInput>
                  </IonItem>
                  {passwordCheck.equal != null && !passwordCheck.equal &&
                    <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                      <IonRow>
                        <IonCol>
                          <div className="input-error-label">
                            <IonIcon color="danger"
                                     icon={closeCircle}
                                     slot="start" />
                            {content.get('RECOVERPASSWORD.FORM.ERROR.NO_MATCH')}
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonItem>
                  }
                </IonList>
              </IonCol>
            </IonRow>
          </IonCardContent>
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              buttonText={content.get('RECOVERPASSWORD.FORM.CTA_SAVE')}
              className="default_button"
              color="primary"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              type="submit"
            ></LoaderButton>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default RecoverPasswordFormPassword
