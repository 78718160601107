import React from 'react'
import { IonImg, IonRow, IonButton, IonCol, useIonViewWillEnter } from '@ionic/react'

import services from 'util/services'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import content from 'util/content'

const Error = () => {
  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()

  const load = async () => {
    const userLocal = await services.user()
    if (!userLocal || !userLocal.id) history.push('/login')
  }

  useIonViewWillEnter(async () => {
    await load()
  })

  return (
    <GeneralPage
      header='none'
      pageClass="page-404"
      user={user}
    >
      <IonRow className="ion-justify-content-center w-100">
        <IonCol className="ion-text-center"
          size="12">
          <IonImg className="empty-section-image no-margin-top"
            src="/assets/images/basepaws-error-image.png" />
          <h1 className="Heading_2 text_primary m-0">
            {content.get('ERROR_PAGE.TITLE')}
          </h1>
          <IonRow className="ion-justify-content-center w-100">
            <IonCol className="ion-text-center"
              size="10"
              size-md="6">
              <p className="Lead text_grey mt-0">
                {content.get('ERROR_PAGE.TEXT')}
              </p>
            </IonCol>
          </IonRow>
          <IonButton className="ion-margin-top big_button"
            color="primary"
            mode="md"
            routerLink={user?.id ? '/patients' : '/login'}
            shape="round">{`${user?.id ? content.get('ERROR_PAGE.BACK_MY_PATIENTS') : content.get('ERROR_PAGE.BACK_LOGIN')}`}</IonButton>
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default Error
