import { IonIcon, IonSelect, IonSelectOption } from '@ionic/react'
import { caretDownSharp } from 'ionicons/icons'
import React from 'react'
import styles from './PaginationSelector.module.scss'

const PaginationSelector = (props) => {
  const { reportsPerPage, setReportsPerPage, baseKey } = props

  const optionsItemsPerPage = [20, 50, 100]

  return (
    <div className={`d-flex ion-align-items-center ml-3 filter_selectors ${styles.paginator_selector}`}>
      <span className='Body_regular text_grey'>Show</span>
      <IonSelect
        className={'Body_bold text_grey border-0'}
        interface="popover"
        onIonChange={(e) => setReportsPerPage(parseInt(e.detail.value))}
        placeholder={`Show ${reportsPerPage} results`}
        value={reportsPerPage}
      >
        {
          optionsItemsPerPage.map((item, index) => {
            return (
              <IonSelectOption
                className='Body_regular text_grey ion-text-right'
                key={`${baseKey}-${index}`}
                value={item}>
                {item} results
              </IonSelectOption>
            )
          })
        }
      </IonSelect>
      <IonIcon
        color="dark"
        icon={caretDownSharp}
        size="14"
      />
    </div>
  )
}

export default PaginationSelector
