import React from 'react'
import { IonRow, IonCol } from '@ionic/react'
import content from 'util/content'

const ProfileCatCardEmpty = () => {
  return <IonRow>
    <IonCol size="12">
      <h2 className="Heading_3 text_cyan">{content.get('PROFILE_CAT.CARD_EMPTY.TITLE')}</h2>
    </IonCol>
    <IonCol size="12"
      size-lg="8"
      size-md="10">
      <p className="Body_1 text_dark_grey">{content.get('PROFILE_CAT.CARD_EMPTY.MESSAGE')}</p>
    </IonCol>
  </IonRow>
}

export default ProfileCatCardEmpty
