import React from 'react'
import content from 'util/content'

import styles from './LegalDisclaimer.module.scss'

export default function LegalDisclaimer () {
  return (
    <div className={`${styles.container}`}>
      <span>{content.get('requisition_form_new.page.disclaimer')} </span>
      <a
        className={`${styles.link}`}
        href={content.get('footer.externals.link_url2')}
      >
        Terms of Service
      </a><span> and </span><a
        className={`${styles.link}`}
        href={content.get('footer.externals.link_url3')}
      >
      Privacy Policy
      </a>
    </div>
  )
}
