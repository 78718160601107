import React from 'react'
import { IonButton, IonIcon, IonRow, IonCol, IonList, IonAvatar } from '@ionic/react'
import { enterSharp } from 'ionicons/icons'

import styles from './ProfilePendingTasksCard.module.scss'

const PendingTaskCard = (props) => {
  const { task, category } = props

  let description = ''
  try { description = category.text.replace('__NAME__', task.petName).replace('__ACTION__', task.action) } catch (e) {}

  const link = task.ctaLink
    ? {
        href: task.ctaLink.replace('__HANDLE__', task.petHandle)
      }
    : {
        routerLink: category.ctaLink.replace('__HANDLE__', task.petHandle)
      }
  const button =
    <IonButton
        mode="md"
        shape="round"
        fill="clear"
        color="primary"
        target='_blank'
        { ...link }
      >
        { category.ctaText }
        <IonIcon slot="start" color="primary" icon={enterSharp}></IonIcon>
      </IonButton>

  return <IonList>
    <li className={styles.my_profile_pendingtask_card_item}>
      <IonRow>
        <IonCol size="12">
          <div className="d-flex ion-align-items-center">
            <IonAvatar>
              <img src={task.petAvatar} alt="Cat avatar"/>
            </IonAvatar>
            <div className="d-flex ion-align-items-center">
              <IonIcon className="mr-2" color="medium" icon={category.icon}></IonIcon>
              <h6 className="Running_head_3 text_sad_purple">{ category.mainCategory }</h6>
            </div>
          </div>
        </IonCol>
        <IonCol size="12">
          <p className="Body_1">{ description }</p>
        </IonCol>
        <IonCol size="12">
          {button}
        </IonCol>
      </IonRow>
    </li>
  </IonList>
}

export default PendingTaskCard
