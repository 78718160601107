import React from 'react'
import { IonRow, IonCol, IonIcon, IonButton, IonCardHeader } from '@ionic/react'
import { removeCircleSharp, addCircleSharp } from 'ionicons/icons'
import './Accordion.scss'

export interface AccordionProps {
  list: Array<any>
  className?: string
  renderHeader: (item: any) => React.ReactNode
  renderPanel: (item: any) => React.ReactNode
  toggleVar?: boolean | Array<boolean>
  toggleFunction: any
  toggleIcons?: { iconOpen: any, iconClose: any }
  toggleIconPosition?: 'before' | 'after'
  showIcons?: boolean
  renderOrder?: 'HeaderBeforePanel' | 'HeaderAfterPanel'
}

const Accordion: React.FC<AccordionProps> = (props: AccordionProps) => {
  const {
    list,
    className,
    renderPanel,
    renderHeader,
    toggleVar,
    toggleFunction,
    toggleIcons,
    toggleIconPosition,
    showIcons,
    renderOrder
  } = props

  const iconOpen = toggleIcons?.iconOpen ?? addCircleSharp
  const iconClose = toggleIcons?.iconClose ?? removeCircleSharp
  const position = toggleIconPosition ?? 'after'
  const showIcon = showIcons ?? true
  const orderHeaderPanel = renderOrder ?? 'HeaderBeforePanel'

  const headerClicked = (_event: any, index: number) => {
    _event.currentTarget.classList.toggle('active')
    /* Toggle close all panels, except on that was clicked */
    const allPanels = document.getElementsByClassName('panel')

    Array.from(allPanels).forEach((panel: any) => {
      if (_event.currentTarget.nextElementSibling !== panel) {
        panel.style.maxHeight = null
      }
      panel.previousElementSibling?.classList.remove('active')
    })
    /* Toggle between hiding and showing the active panel */
    const panel = _event.currentTarget.nextElementSibling
    if (panel) {
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null
      } else {
        panel.style.maxHeight = null
        const scrollHeight = panel.scrollHeight
        setTimeout(() => {
          panel.style.maxHeight = scrollHeight + 'px'
        }, 100)
      }
    }

    if (toggleFunction) {
      if ((typeof toggleVar === 'object')) {
        toggleFunction(index)
      } else {
        toggleFunction()
      }
    }
  }

  return (
    <div>
      {list.map((item, index) => {
        return (
          <div key={index}>
            {(orderHeaderPanel === 'HeaderAfterPanel') && <div className={`panel ${(typeof toggleVar === 'boolean') && toggleVar ? 'open' : ''}`}>
              {renderPanel(item)}
            </div>}
            <button
              className={`accordion ${className ?? ''}`}
              onClick={(e) => headerClicked(e, index)}
            >
              <IonCardHeader>
                <IonRow className="ion-align-items-center">
                  {position === 'after' && renderHeader(item)}
                  <IonCol
                    className="ion-text-end"
                    size="6"
                    size-lg="1"
                    size-md="6"
                  >
                    <IonButton
                      aria-label="See more/less detail"
                      className="default_button"
                      fill="clear"
                      mode="md"
                    >
                      {showIcon && (<>
                        {(typeof toggleVar === 'boolean') && <IonIcon icon={toggleVar ? iconClose : iconOpen}></IonIcon>}
                        {(typeof toggleVar === 'object') && <IonIcon icon={toggleVar[index] ? iconClose : iconOpen}></IonIcon>}
                      </>)}
                    </IonButton>
                  </IonCol>
                  {position === 'before' && renderHeader(item)}
                </IonRow>
              </IonCardHeader>
            </button>
            {(orderHeaderPanel === 'HeaderBeforePanel') && <div className={`panel ${(typeof toggleVar === 'boolean') && toggleVar ? 'open' : ''}`}>
              {renderPanel(item)}
            </div>}
          </div>
        )
      })}
    </div>
  )
}

export default Accordion
