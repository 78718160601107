import React, { useContext } from 'react'
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from '@ionic/react'
import { useField } from 'formik'
import { checkmarkCircleSharp } from 'ionicons/icons'
import { string } from 'yup'
import { yupAdapter } from '../Form/Form'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'
import { Static } from '../Form/Static'
import content from 'util/content'

export const Radio = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, list, name, label, required, disabled, bold, multipleValueLabel } = props

  let validate = string()
  if (required) validate = validate.required('required')
  const [field, { value }, { setValue }] = useField({ name: name, validate: yupAdapter(validate) })

  if (!editable) {
    return (
    <Row
      bold={bold}
      label={label}
      multiple={multipleValueLabel}>
      <Static
        icon={checkmarkCircleSharp}
        multiValues={multipleValueLabel}
      >
        {content.get(`products.${value}.name`) || value}
      </Static>
    </Row>)
  }

  if (editable) {
    return (
    <IonRadioGroup
    className="w-100"
    name={name}
    onIonChange={e => setValue(e.detail.value)}
    value={value}
  >
    <Row bold={bold}
         label={label}>
        <div className={`${styles.d_lg_flex}`}>
          {list.map((tt, i) => (
            <div
              className={`${styles.input_radio_wrapper} input-radio-wrapper w-auto mb-2`}
              key={`testTypes-${i}`}
            >

              <IonItem className={`${styles.input_radio} input-radio item-no-padding`}>
                <IonRadio
                  data-qa={`${name}:${tt.label}`}
                  disabled={disabled}
                  name={name}
                  value={tt.value}
                />
                <IonLabel>{content.get(tt.label)}</IonLabel>
                </IonItem>

            </div>
          ))}
        </div>
        {props.children}
    </Row>
  </IonRadioGroup>)
  }
}
