import React, { useEffect, useState } from 'react'
import { useIonViewWillEnter, IonGrid, IonButton, IonIcon, IonRow, IonCol } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import services from 'util/services'
import styles from './RequisitionFormPage.module.scss'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import { checkmarkSharp, copySharp, printSharp } from 'ionicons/icons'
import content from 'util/content'

const RequisitionFormSuccess = (props) => {
  const { handle } = props

  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()
  const location = useLocation()
  const baseUrl = window.location.origin

  const [copied, setCopied] = useState(null)
  const [copiedTimeOut, setCopiedTimeOut] = useState(null)

  const [requisition, setRequisition] = useState()

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
  })

  useEffect(async () => {
    const state = location.state
    if (state?.handle && state?.url) {
      setRequisition({
        handle: state?.handle,
        id: state?.id,
        url: state?.url
      })
    }
  }, [location])

  // copy text to clipboard and show success message
  const copyTextToClipboard = (textToCopy, whichButton) => {
    if (copiedTimeOut) {
      clearTimeout(copiedTimeOut)
    }
    navigator.clipboard.writeText(textToCopy)
    props.onCopy && props.onCopy()
    const TimeOut = setTimeout(() => {
      setCopied(null)
      setCopiedTimeOut(null)
    }, 2000)
    setCopied(whichButton)
    setCopiedTimeOut(TimeOut)
  }

  const detailSuccess = (
  <>
    <div className="d-flex ion-align-items-center ion-justify-content-center mb-2">
      <div className={`Body_regular text_light ${styles.tag}`}>
        {`${requisition?.handle}`}
      </div>
      <IonButton
        className={`default_button icon-start ${styles.button_min_width1}  ${copied === 'copyID' ? 'done_button left' : styles.button_light_blue}`}
        fill="solid"
        mode="md"
        onClick={() => copyTextToClipboard(requisition?.handle, 'copyID')}
        shape='round'
      >
        <IonIcon src={copied === 'copyID' ? checkmarkSharp : copySharp}/>
        {copied === 'copyID' ? 'Copied' : 'Copy ID'}
      </IonButton>
    </div>
    <div className="d-flex ion-align-items-center ion-justify-content-center mb-2">
      <div className={`${styles.tag} Body_regular text_light`}>
        {`${baseUrl}${requisition?.url}`}
      </div>
      <IonButton
        className={`default_button icon-start ${styles.button_min_width2} ${copied === 'copyURL' ? 'done_button left' : styles.button_light_blue}`}
        fill="solid"
        mode="md"
        onClick={() =>
          copyTextToClipboard(`${baseUrl}${requisition?.url}`, 'copyURL')
        }
        shape='round'
      >
        <IonIcon src={copied === 'copyURL' ? checkmarkSharp : copySharp}/>
        {copied === 'copyURL' ? 'Copied' : 'Copy URL'}
      </IonButton>
    </div>
  </>
  )

  return (
    <GeneralPage
      header='none'
      isNotFixed={true}
      pageClass="page-404"
      user={user}
    >
      <IonGrid fixed>
        <div>
          <IonRow className="ion-justify-content-center w-100 mt-5">
            <IonCol className="ion-text-center"
                    size='11'
                    size-md="10">
                <span className='success-section-circle big my-3'>
                  <IonIcon className="empty-section-icon"
                           color='success'
                           src={checkmarkSharp} />
                </span>
              <h1 className="Heading_2 text_primary ion-text-center">
                {content.get('REQUISITION_FORM.REPLACED.TITLE')}
              </h1>
              <IonRow className="ion-justify-content-center w-100">
                  <IonCol className="ion-text-center"
                          size="10"
                          size-md="10">
                    <p className="Lead text_grey mt-0">
                    {content.get('REQUISITION_FORM.REPLACED.MESSAGE')}
                    </p>
                  </IonCol>
                </IonRow>
            </IonCol>
          </IonRow>
          <IonRow className='ion-justify-content-center'>
            <IonCol size='12'
                    size-md='8'>
              {detailSuccess}
              <div className='ion-text-center'>
                <IonButton
                  className='big_button icon-start mt-3'
                  color='primary'
                  fill='solid'
                  mode='md'
                  onClick={() => window.open(`https://pdf.basepaws.com/requisition?id=${requisition?.id}`, '_blank') }
                  shape='round'
                  >
                  <IonIcon src={printSharp}/>
                  {content.get('REQUISITION_FORM.BUTTON_DOWNLOAD.TITLE')}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </div>
      </IonGrid>
    </GeneralPage>
  )
}

export default RequisitionFormSuccess
