import React from 'react'
// import DateFnsUtils from '@date-io/date-fns'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './DateInputDesktop.scss'

const DateInputDesktop = (props) => {
  const { selectRange, portal, format, placeholder, onChange } = props
  const { startDate, setStartDate, endDate, setEndDate } = props.dateOptions

  const onChangeLocal = (dates) => {
    console.log(dates)
    if (selectRange) {
      const [start, end] = dates
      setStartDate(start)
      setEndDate(end)
    } else {
      const start = dates
      setStartDate(start)
    }
  }

  return (
    <div className='date_input'>
      <DatePicker
        endDate={endDate}
        format={format}
        onChange={(date) => {
          onChangeLocal(date)
          onChange(date)
        }}
        placeholderText={placeholder}
        portalId={ portal }
        selected={startDate}
        selectsRange={selectRange || false}
        startDate={startDate}
        {...props}
        />
    </div>
  )
}

export default DateInputDesktop
