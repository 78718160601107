import React, { useState } from 'react'
import { IonCard, IonRow, IonCol, IonIcon, IonImg, IonButton, IonPopover, useIonAlert } from '@ionic/react'
import { alertCircleSharp, arrowForwardSharp, checkmarkCircleSharp, copySharp, downloadSharp, pencilSharp, refreshCircleSharp, trashSharp } from 'ionicons/icons'
import content from 'util/content'
import styles from './RequisitionFormLogCard.module.scss'
import HidingMessageIcon from 'components/basepaws/general/HidingMessageIcon'

const RequisitionFormLogCard = (props) => {
  const { log, onDelete } = props

  const [presenetAlert] = useIonAlert() // IonAlert Hook

  const rfStatusMap = {
    pending: {
      class: 'pending',
      icon: { __html: refreshCircleSharp.replace('data:image/svg+xml;utf8,', '').replace(/<title>.*<\/title>/gi, '<title>Pending</title>') }
    },
    submitted: {
      class: 'submitted',
      icon: { __html: checkmarkCircleSharp.replace('data:image/svg+xml;utf8,', '').replace(/<title>.*<\/title>/gi, '<title>Submitted</title>') }
    }
  }

  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  })

  // copy text to clipboard and show success message
  const copyTextToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
  }

  // alert to confirm delete req.
  const showDeleteAlert = () => {
    const message = content.get('REQUISITION_LOGS.DELETE.MESSAGE', { kitname: `<b>${content.get(`report_card.${log.kit?.productName}.name`)}</b>`, kitcode: `<b>kit ID ${log.kit?.code}</b>` })
    presenetAlert({
      cssClass: 'alert',
      header: 'Delete Requisition Form',
      message: message,
      buttons: [
        {
          text: 'No, keep it',
          role: 'cancel'
        },
        {
          text: 'Yes, delete it',
          role: 'confirm',
          handler: () => {
            onDelete()
          }
        }]
    })
  }

  // buttons switch
  const buttonsAccordingToState = (log) => {
    let temp = null
    if (log.status === 'pending') { // Pending: Delete and Edit buttons
      temp = <>
        <IonButton
          className='default_button icon-end mx-3'
          color='primary'
          fill='clear'
          mode='md'
          onClick={showDeleteAlert}
          shape='round'
        >
          Delete
          <IonIcon icon={trashSharp} />
        </IonButton>
        <IonButton
          className='default_button icon-end'
          color='primary'
          fill='solid'
          mode='md'
          routerLink={`/forms/requisition/${log.handle}`}
          shape='round'
        >
          Edit
          <IonIcon icon={pencilSharp} />
        </IonButton>
      </>
    } else if (log.status === 'submitted') { // submitted
      if (!log.pet?.owner?.email) { // Submitted (missing email): Download and Complete buttons
        temp = <>
          <IonButton
            className='default_button icon-end mx-3'
            color='primary'
            fill='clear'
            mode='md'
            onClick={() => window.open(`https://pdf.basepaws.com/requisition?id=${log.id}`, '_blank')}
            shape='round'
          >
            Download
            < IonIcon icon={downloadSharp} />
          </IonButton >
          <IonButton
            className='default_button icon-end'
            color='primary'
            fill='solid'
            mode='md'
            routerLink={`/forms/requisition/${log.handle}`}
            shape='round'
          >
            Complete
            <IonIcon icon={arrowForwardSharp} />
          </IonButton>
        </>
      } else if (log.testStatus === 'exception') { // Submitted (exception -failed kit-): Exception tag and Replace button
        temp = <>
          <div className={`${styles.tag} d-flex`}>
            <p className={`${styles.card_rf_log_report_state_icon_danger_label} ${styles.expection} Running_head_3 text_danger mr-3 m-0`}>
              exception
            </p>
            <IonIcon
              className={`${styles.card_rf_log_report_state_icon} mr-2`}
              color='danger'
              icon={alertCircleSharp}
            />
          </div>
          <IonButton
            className='default_button icon-end'
            color='primary'
            fill='solid'
            mode='md'
            shape='round'
          >
            Replace
            <IonIcon icon={arrowForwardSharp} />
          </IonButton>
        </>
      } else { // Submitted (all correct): Download and View buttons
        temp = <>
          <IonButton
            className='default_button icon-end mx-3'
            color='primary'
            fill='clear'
            href={`https://pdf.basepaws.com/requisition?id=${log.id}`}
            mode='md'
            shape='round'
            target='_blank'
          >
            Download
            <IonIcon icon={downloadSharp} />
          </IonButton>
          <IonButton
            className='default_button icon-end'
            color='primary'
            fill='solid'
            mode='md'
            routerLink={`/forms/requisition/${log.handle}`}
            shape='round'
          >
            View
            <IonIcon icon={arrowForwardSharp} />
          </IonButton>
        </>
      }
    }
    return temp
  }

  return (
    <>
      <IonCard
        className={`${styles.card_rf_log} ${popoverState.showPopover ? styles.active : ''} card`}
      >
        <IonRow className={'ion-align-items-center'}>
          <IonCol
            className={`${styles.mt_sm_1}`}
            size='12'
            size-lg='7'
            size-xl="6"
          >
            <IonRow className={`ion-align-items-center ${styles.justify_content_sm_between}`}>
              <IonCol size='12'
                      size-md='4'
              >
                <div className={`${styles.justify_content_md_between} d-flex ion-align-items-center no-wrap`}>
                  <div className="d-flex no-wrap ion-align-items-center">
                    <IonButton
                      aria-label='copy test id'
                      className="default_button no-padding"
                      fill='clear'
                      mode='md'
                      onClick={(e) => {
                        e.persist()
                        copyTextToClipboard(log.handle, 'The requisition ID has been copied to your clipboard.')
                        setShowPopover({ showPopover: true, event: e })
                        setTimeout(() => {
                          setShowPopover({ showPopover: false, event: e })
                        }, 2000)
                      }}
                      shape='round'
                    >
                      <IonIcon
                        color={popoverState.showPopover ? 'success' : 'primary'}
                        icon={popoverState.showPopover ? checkmarkCircleSharp : copySharp}
                      />
                    </IonButton>
                    <IonPopover
                      cssClass="tooltip"
                      event={popoverState.event}
                      isOpen={popoverState.showPopover}
                      mode='ios'
                      onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                      show-backdrop='false'
                    >
                      <span className='Body_small_regular m-0'>Copied</span>
                    </IonPopover>
                    <span className={`${styles.card_rf_log_tags_id} Running_head text_primary ml-3`}>
                      {log.handle}
                    </span>
                  </div>
                  <span
                    className={`${styles.card_rf_log_states} ${styles.requisitionStatus} ${styles[rfStatusMap[log?.status]?.class]} ml-3`}
                    dangerouslySetInnerHTML={rfStatusMap[log?.status]?.icon} >
                  </span>
                </div>
              </IonCol>
              <IonCol size='12'
                      size-md='8'
              >
                <div className="d-flex no-wrap ion-align-items-center">
                  <IonImg
                    alt={`Outlined cat icon for ${content.get(`report_card.${log.kit?.productName}.name`)} reference`}
                    className={styles.ml_lg_3}
                    src={`/assets/icon/tests/${log.kit?.productName}.svg`}
                  />
                  <p className={`Body_small_regular text_dark ml-2 m-0 ${styles.overflow_text}`}>
                    {content.get(`report_card.${log.kit?.productName}.name`)}
                  </p>
                  <span className={`${styles.card_rf_log_tags_id} ${styles.text_no_wrap} Running_head text_primary ml-3`}>
                    ID {log.kit?.code}
                  </span>
                </div>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol
            className={`${styles.card_rf_log_column} ${styles.mt_sm_2}`}
            size='12'
            size-lg='4'
            size-xl="2"
          >
            <div className="d-flex no-wrap ion-align-items-center">
              {/* For missing email case use this icon, and use the running header 'Missing emails' */}
              {log.pet?.owner?.first_name && !log.pet?.owner?.email && log.status !== 'pending' &&
                <HidingMessageIcon
                  icon={alertCircleSharp}
                  iconColor='danger'
                  iconStyle={styles.card_rf_log_report_state_icon}
                  messageStyle={`${styles.card_rf_log_report_state_icon_danger_label} Running_head text_danger`}
                >
                  Missing Email
                </HidingMessageIcon>
              }
              <p className="Body_small_bold text_dark m-0">{log.pet?.name}</p>
              <p className="Body_small text_light ml-3 m-0">{log.pet?.owner?.first_name} {log.pet?.owner?.last_name}</p>
            </div>
          </IonCol>
          <IonCol
            className={`${styles.mt_sm_3}`}
            size='12'
            size-lg='12'
            size-xl="4"
          >
            <div className={`${styles.justify_content_xl_end} ${styles.flex_sm_reverse} ${styles.mb_sm_2} d-flex ion-align-items-center`}>
              {buttonsAccordingToState(log)}
            </div>
          </IonCol>
        </IonRow>
      </IonCard>
    </>
  )
}

export default RequisitionFormLogCard
