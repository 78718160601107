import React, { useState, useEffect } from 'react'
import { IonSelect, IonSelectOption, IonIcon } from '@ionic/react'
import content from 'util/content'
import { caretDownSharp } from 'ionicons/icons'
import styles from './CatFilters.module.scss'

const SortByButton = (props) => {
  const { options, sortIn, setResultFunction, defaultOrder } = props
  const [orderValue, setOrderValue] = useState('Select One')

  const getDeepValue = (data, fields) => {
    let value = null
    let current = data

    fields?.forEach(field => {
      if (field.endsWith('[]')) {
        const f = field.substring(0, field.length - 2)
        value = current[f]
        if (value?.length > 0) {
          value = value[0]
          current = value
        } else {
          return null
        }
      } else {
        value = current ? current[field] : null
        current = value
      }
    })
    return value ?? 'none'
  }

  const sortFunction = (a, b, field, direction = 'asc') => {
    const aField = getDeepValue(a, field)
    const bField = getDeepValue(b, field)

    if (aField === undefined) return 1; // Move `a` to the end
    if (bField === undefined) return -1; // Move `b` to the end
  
    // if field is a date, convert to date object for comparison
    const aDateField = new Date(aField)
    const bDateField = new Date(bField)

    if (aDateField > 0 && bDateField > 0) { // if both are dates
      if (direction === 'asc') {
        return Number(aDateField.getTime()) - Number(bDateField.getTime())
      } else {
        return Number(bDateField.getTime()) - Number(aDateField.getTime())
      }
    } else { // if not dates, compare as strings
      if (aField < bField) {
        return direction === 'asc' ? -1 : 1
      }
      if (aField > bField) {
        return direction === 'asc' ? 1 : -1 // Corrected the logic here to return 1 for descending order
      }
      return 0
    }
  }

  const groupBy = (list, keyGetter) => {
    const map = new Map()
    list.forEach((item) => {
      const key = getDeepValue(item, keyGetter.split('.'))
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const loadOrder = () => {
    const order = options.find(item => item.value === orderValue)
    const fields = order?.value_order.split('.')
    let sortedArray = []

    sortedArray = sortIn.sort((a, b) => sortFunction(a, b, fields, order?.direction))

    if (order?.groupBy) {
      const mappedArray = groupBy(sortedArray, order.groupBy)
      sortedArray = []
      let orderGroup = order.orderGroup.split('|')
      orderGroup = [...orderGroup, 'none']
      orderGroup.forEach(group => {
        const groupArray = mappedArray.get(group)
        if (groupArray) {
          sortedArray = [...sortedArray, ...groupArray]
        }
      })
    }

    setResultFunction([...sortedArray])
    return () => null
  }

  useEffect(() => {
    loadOrder()
  }, [orderValue])

  useEffect(() => {
    orderValue ? setOrderValue(orderValue) : setOrderValue(defaultOrder ?? 'Select One')
  }, [sortIn])

  useEffect(() => {
    loadOrder()
    setOrderValue(defaultOrder ?? 'Select One')
  }, [])

  return (
    <div className={`d-flex no-wrap ion-align-items-center filter_selectors ${styles.sort_selector}`}>
      <span
        className="Body_regular text_light ion-no-margin mr-2"
      >
        {content.get('MY_CATS.SORT.TITLE')}
      </span>
      <IonSelect
        className="Body_bold text_grey border-0"
        interface="popover"
        onIonChange={(e) => {
          setOrderValue(e.detail.value)
        }}
        placeholder="Sort By"
        value={orderValue}
      >
        {options && options.map(
          (option, index) => {
            return (
              <IonSelectOption
                className='Body_regular text_grey ion-text-right'
                key={index}
                value={option.value}
              >
                {option.label}
              </IonSelectOption>
            )
          })
        }
      </IonSelect>
      <IonIcon
        color="dark"
        icon={caretDownSharp}
        size="14"
      />
    </div>
  )
}

export default SortByButton
