import React from 'react'
import { IonButton } from '@ionic/react'
import styles from './LoaderButton.module.scss'

interface IProps {
  type: 'button' | 'submit' | 'reset' | undefined
  disabled: boolean
  color: any
  className: string
  onClick?: any
  href: string
  buttonText?: string
  isSubmitting: boolean
  children?: string
}

const LoaderButton = (props: IProps) => {
  const { type, disabled, color, className, onClick, href, buttonText, isSubmitting, children } = props

  return (
    <IonButton
      className={`${styles.loader_button} ${className}`}
      color={color}
      disabled={disabled}
      fill="solid"
      mode="md"
      onClick={onClick}
      routerLink={href}
      shape="round"
      type={type}
    >
    {children ?? buttonText}
    {isSubmitting && <div className="loader"></div>}
    </IonButton>
  )
}

export default LoaderButton
