import React from 'react'
import { IonCol, IonRow, IonSkeletonText } from '@ionic/react'

export interface SkeletonProps {
  rows: Number, // Number of rows to display
  colsSizes: Array<Number>, // Array with the size of each col. sum = 12 e.g.: [4,4,2,1,1]
  animated: boolean, // Animate or not the Skeleton
  customStyle: any // Json with styles e.g.: { height: '20px' }
}

const Skeleton = (props: SkeletonProps) => {
  const { rows, colsSizes, animated, customStyle } = props

  const skeletonRow = (indexRow: number) => <IonRow key={`skeleton-row-${indexRow}`}>
    {[...Array(colsSizes.length)].map((_, i) => {
      return <IonCol
        key={`skeleton-col-${i}`}
        size={String(colsSizes[i])} >
        <IonSkeletonText
          animated={animated}
          style={customStyle}
        />
      </IonCol>
    }
    )}
  </IonRow>

  return (
    <>
      {[...Array(rows)].map((_, i) => skeletonRow(i))}
    </>
  )
}

export default Skeleton
