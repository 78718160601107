import React from 'react'
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonButton, IonIcon } from '@ionic/react'
import { lockClosedSharp, pencilSharp } from 'ionicons/icons'
import content from 'util/content'
import styles from './ProfileWelcomeCard.module.scss'

const ProfileWelcomeCard = (props) => {
  return (
    <IonCard className={`${styles.my_profile_welcome_card} card`}>
      <IonCardHeader>
        <IonCardTitle className="Running_head_1 text_dark_grey">
          {content.get('dashboard.my_profile.title')}
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <h2 className="Heading_3 text_sad_purple">
          {props.user?.first_name} {props.user?.last_name}
        </h2>
        <p className="Body_1 text_grey">
          {props.user?.email}
        </p>
      </IonCardContent>
      <div className={styles.card_footer}>
        <IonButton
          mode="md"
          slot="end"
          shape="round"
          color="primary"
          fill="solid"
          routerLink={content.get('DASHBOARD.MY_PROFILE.CTA_LINK1')}
          className="mr-2 default_button"
        >
          <IonIcon slot="start" icon={pencilSharp} />
          {content.get('DASHBOARD.MY_PROFILE.CTA_TITLE1')}
        </IonButton>
        <IonButton
          mode="md"
          slot="end"
          shape="round"
          color="primary"
          fill="clear"
          routerLink={content.get('DASHBOARD.MY_PROFILE.CTA_LINK2')}
        >
          <IonIcon
            slot="start"
            icon={lockClosedSharp}
          />
          {content.get('DASHBOARD.MY_PROFILE.CTA_TITLE2')}
        </IonButton>
      </div>
    </IonCard>
  )
}

export default ProfileWelcomeCard
