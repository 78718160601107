import React from 'react'
import { IonRow, IonCol, useIonViewWillEnter } from '@ionic/react'

import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import services from 'util/services'
import content from 'util/content'
// import styles from './AccountProfile.module.scss'
import ProfileWelcomeCard from 'components/basepaws/account/ProfileWelcomeCard'
import ProfileAdvertisingCard from 'components/basepaws/account/ProfileAdvertisingCard'
import ProfileMyCatsCard from 'components/basepaws/account/ProfileMyCatsCard'
import ProfilePendingTasksCard from 'components/basepaws/account/ProfilePendingTasksCard'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'

const AccountProfile = () => {
  const history = useHistory()
  const location = useLocation()
  const user = useSelector(state => state.userInReducer.user)

  const load = async () => {
    const userLocal = await services.user()

    if (!userLocal || !userLocal.id) {
      history.push('/login')
    }
  }

  useIonViewWillEnter(async () => {
    if (!user.id) {
      const query = location.hash
      if (query) {
        const data = query.split('/')
        const mail = data[0].substring(1)
        const code = data[1]
        if (mail && code) { await services.post('/auth/confirm', { mail: mail, code: code }) }
      }
      await load()
    }
  })

  // problem with replaceable content
  const headerTitle = content.get('dashboard.HEADER.TITLE', { name: user?.first_name })
  return (
    <GeneralPage
      title={headerTitle}
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom"
      user={user}
    >
      <IonRow>
        <IonCol size="12"
          size-lg="6">
          <ProfileWelcomeCard user={user} />
        </IonCol>
        <IonCol className="no-mobile"
          size="12"
          size-lg="6">
          <ProfileAdvertisingCard />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12"
          size-lg="6">
          <ProfileMyCatsCard user={user} />
        </IonCol>
        <IonCol size="12"
          size-lg="6">
          <ProfilePendingTasksCard
            pets={user?.pets?.filter((p) => !p.death_on)}
          />
        </IonCol>
      </IonRow>
      <IonRow className="no-desktop">
        <IonCol size="12"
          size-lg="6">
          <ProfileAdvertisingCard />
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default AccountProfile
