import React from 'react'
import { IonIcon } from '@ionic/react'
import styles from './PatientListItem.module.scss'

const StateLabel = (props) => {
  const { reportStatus } = props

  return (
    <div className={`${styles.patient_list_item_report_state} ${reportStatus?.style} d-flex ion-align-items-center ion-justify-content-end`}>
      <p className='Running_head mr-2 mb-0 mt-0'>{reportStatus?.text}</p>
      <IonIcon icon={reportStatus?.icon}/>
    </div>
  )
}

export default StateLabel
