import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Error } from './pages'

import PointContactRegister from './pages/adminPortal/PointContactRegister'
import PointContactRegisterThanks from './pages/adminPortal/PointContactRegisterThanks'

import { NewRequisitionFormPage, RequisitionFormPage, RequisitionFormSuccess } from 'pages/RequisitionFormPage'
import RequisitionFormLog from 'pages/RequisitionFormLog'
import VetPatients from './pages/vetPortal/VetPatient/VetPatient'
import VetResources from './pages/vetPortal/VetResources/index'
import AccountInformation from './pages/AccountInformation'
import AccountProfile from './pages/account/AccountProfile'
import AccountChangePassword from 'pages/account/AccountChangePassword'
import AccountEditProfile from 'pages/account/AccountEditProfile'
import AccountLogin from 'pages/account/AccountLogin'
import AccountRegister from 'pages/account/AccountRegister'
import AccountRecoverPasswordEmail from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordEmail'
import AccountRecoverPasswordPassword from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordPassword'
import AccountRecoverPasswordEmailConfirmation from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordEmailConfirmation'
import AccountRecoverPasswordPasswordConfirmation from 'pages/account/AccountRecoverPassword/AccountRecoverPasswordPasswordConfirmation'
import ContentPage from 'pages/other/ContentPage'
import VetEmailValidation from 'pages/vetPortal/VetEmailValidation'
import AccountNotification from 'pages/account/AccountNotification'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/general.scss'
// import LogRocket from 'logrocket';
// LogRocket.init('6h9g5o/basepaws');

/* Slick carrousel css */
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import content from 'util/content'
import ReplacementRequisitionForm from 'pages/RequisitionFormPage/ReplacementRequisitionForm'

/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-sort-props */
const App: React.FC = () => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    content.load().then((d) => {
      setLoaded(true)
    })
  }, [])

  if (loaded === false) { return (<></>) }

  const localUser = JSON.parse(localStorage.getItem('user') || 'null')

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/vet" component={VetPatients}></Route>
          <Route exact path="/changePassword" component={AccountRecoverPasswordPassword}></Route>

          {/* admin portal */}
          <Route exact path="/organization/register" component={PointContactRegister}></Route>
          <Route exact path="/organization/thanks" component={PointContactRegisterThanks}></Route>
          <Route exact path="/admin/contentpage" component={ContentPage}></Route>

          {/* vet portal */}
          <Route exact path="/notification" component={AccountNotification}></Route>
          <Route exact path="/patients" component={VetPatients}></Route>
          <Route exact path="/requisition-form-log" component={RequisitionFormLog}></Route>
          <Route exact path="/resources" component={VetResources}></Route>
          <Route exact path="/vet/requisition#:handle" component={RequisitionFormPage}></Route>
          <Route exact path="/forms/requisition/:handle" component={NewRequisitionFormPage}></Route>
          <Route exact path="/forms/replace_requisition/:handle" component={ReplacementRequisitionForm}></Route>
          <Route exact path="/requisition/success" component={RequisitionFormSuccess}></Route>
          <Route exact path="/forms/requisition" component={NewRequisitionFormPage}></Route>
          <Route exact path="/profile" component={AccountProfile}></Route>
          <Route exact path="/account" component={AccountInformation}></Route>

          <Route exact path="/profile/ChangePassword" component={AccountChangePassword}></Route>
          <Route exact path="/profile/edit" component={AccountEditProfile}></Route>

          {/* routes without user */}
          <Route exact path="/login" component={AccountLogin}></Route>
          <Route exact path="/register" component={AccountRegister}></Route>
          <Route exact path="/vet/mail/confirmed" component={VetEmailValidation}></Route>
          <Route exact path="/recoverPasswordEmail" component={AccountRecoverPasswordEmail}></Route>
          <Route exact path="/recoverPasswordEmail/confirmation" component={AccountRecoverPasswordEmailConfirmation}></Route>
          <Route exact path="/recoverPasswordPassword/confirmation" component={AccountRecoverPasswordPasswordConfirmation}></Route>
          <Route exact path="/profile"><Redirect to={`${localUser ? '/patients' : '/login'}`} /></Route>
          <Route exact path="/"><Redirect to={`${localUser ? '/patients' : '/login'}`} /></Route>
          <Route path="/error" component={Error}></Route>
          <Route ><Redirect to='/error' /></Route>

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
}

export default App
