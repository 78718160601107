import React, { useEffect, useState } from 'react'
import { IonButton, IonInput, useIonAlert } from '@ionic/react'

import Loading from 'components/basepaws/utils/Loading'
import { servicesNew } from 'util/services'
import content from 'util/content'

import { replaceRequisition } from './replaceRequisition'

import styles from './RequisitionReplacement.module.scss'

interface IRequisitionReplacementProps {
  oldRequisitionId: string,
  oldKitCode: string,
}

export default function RequisitionReplacement ({ oldRequisitionId, oldKitCode }: IRequisitionReplacementProps) {
  const [barcode, setBarcode] = useState<string>('')
  const [barcodeTouched, setBarcodeTouched] = useState<boolean>(false)
  const [barcodeError, setBarcodeError] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const [presentAlert] = useIonAlert()

  const barcodeInvalid = barcodeTouched && barcode.length !== 14
  const buttonDisabled = (!barcodeTouched || barcodeInvalid || !!barcodeError) || loading

  useEffect(() => {
    if (!barcodeInvalid && barcode) {
      const fetchData = async () => {
        const kit = await servicesNew.get(`/kit/${barcode}`)
        const oldKit = await servicesNew.get(`/kit/${oldKitCode}`)
        if (kit?.user) {
          setBarcodeError(content.get('replacementRequisition.feedback.kitAlreadyActivated'))
        }
        if (kit?.organism !== oldKit?.organism) {
          setBarcodeError('The kit you are trying to activate is for a different species than the one you are trying to replace.')
        }
        setLoading(false)
      }

      // debounce
      let cancel = false
      setTimeout(() => {
        if (!cancel) {
          fetchData()
            .catch(() => {
              setBarcodeError(content.get('replacementRequisition.feedback.barcodeInvalid'))
            })
            .finally(
              () => setLoading(false)
            )
        }
      }, 500)

      return () => {
        cancel = true
        setLoading(false)
      }
    }
  }, [barcode])

  async function handleSubmit () {
    setLoading(true)
    try {
      const responseCode = await replaceRequisition({ newBarcode: barcode, oldRequisitionId })
      if (responseCode) {
        presentAlert({
          cssClass: `${styles.info} ${styles.alert}`,
          header: content.get('replacementRequisition.success.header'),
          message: content.get('replacementRequisition.success.content'),
          buttons: [
            {
              text: content.get('replacementRequisition.success.cta'),
              role: 'confirm',
              handler: () => { window.location.reload() }
            }
          ],
          onDidPresent: () => setTimeout(() => window.location.reload(), 3_000)
        })
      } else {
        presentAlert({
          cssClass: `${styles.info} ${styles.alert}`,
          header: content.get('replacementRequisition.error.header'),
          message: content.get('replacementRequisition.error.content'),
          buttons: [
            {
              text: content.get('replacementRequisition.error.cta'),
              role: 'confirm',
              handler: () => { window.location.href = 'mailto:support@basepawsvet.com' }
            }
          ]
        })
      }
    } catch (error) {
      presentAlert({
        cssClass: `${styles.info} ${styles.alert}`,
        header: content.get('replacementRequisition.error.header'),
        message: content.get('replacementRequisition.error.content'),
        buttons: [
          {
            text: content.get('replacementRequisition.error.cta'),
            role: 'confirm',
            handler: () => { window.location.href = 'mailto:support@basepawsvet.com' }
          }
        ]
      })
    }
    setLoading(false)
  }

  return (
    <>
      <h6 className='Heading_5 text_primary mb-1 mt-0'>
        {content.get('replacementRequisition.form.header')}
      </h6>
      <p className='Body_small_regular text_grey mt-0'>
        {content.get('replacementRequisition.form.content')}
      </p>
      <div className={`ion-justify-content-between no-wrap ${styles.formContainer}`}>
          <div className={styles.inputContainer}>
            <IonInput
              className={`${styles.barcodeInput} ${barcodeInvalid || barcodeError !== '' ? styles.invalid : ''}`}
              id="barcode"
              name="barcode"
              onIonBlur={(e) =>
                setLoading(false)
              }
              onIonChange={(e) => {
                setLoading(true)
                setBarcodeError('')
                setBarcode(e.detail.value ?? '')
              }}
              onIonFocus={() => setBarcodeTouched(true)}
              placeholder={content.get('replacementRequisition.form.placeholder')}
              value={barcode}
            />
            {barcodeInvalid
              ? (
                  <div className={styles.errorLabel}>
                    {content.get('replacementRequisition.feedback.barcodeMinCharacter')}
                  </div>
                )
              : null}
            {barcodeError
              ? (
                  <div className={styles.errorLabel}>
                    {barcodeError}
                  </div>
                )
              : null}
          </div>
            <IonButton
                className={`default_button ${styles.submit}`}
                disabled={buttonDisabled}
                fill={loading ? 'outline' : 'solid'}
                mode='md'
                onClick={handleSubmit}
                shape='round'
              >
                {loading
                  ? <Loading/>
                  : content.get('replacementRequisition.form.cta')}
            </IonButton>
      </div>
    </>
  )
}
