interface IUserRole{
  name: string;

}
interface IUser{
  roles: IUserRole[];
}

function isOrganizationUser (user:IUser) {
  return user?.roles?.some((role) =>
    role.name.includes(':vet') ||
    role.name.includes(':admin'))
}

export { isOrganizationUser }
