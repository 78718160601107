import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { IonRow, IonCol, IonList, IonItem, IonInput, IonLabel, IonIcon, IonButton, IonListHeader, IonCheckbox } from '@ionic/react'
import { closeCircle, eye, eyeOff, checkmarkCircle, ellipseOutline, logoFacebook, logoGoogle } from 'ionicons/icons'

import { Formik } from 'formik'
import * as Yup from 'yup'
import services from 'util/services'
import styles from './RegisterForm.module.scss'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import { NavLink } from 'react-router-dom'

const RegisterForm = () => {
  const [passwordFieldType, setPasswordFieldType] = useState({
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })

  const [isFormFilledCheck, setFormFilledCheck] = useState({
    typedFirst_name: false,
    typedLast_name: false,
    typedEmail: false,
    typedPassword: false,
    typedPasswordRepeat: false,
    isTermsChecked: false,
    isPrivacyChecked: false
  })

  const isFormFilled = Object.values(isFormFilledCheck)

  const history = useHistory()

  const changeType = (field) => {
    const currentType = passwordFieldType[field]
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      [field]: nextType,
      [`${field}Icon`]: icon
    })
  }

  const onSubmit = async (values, setSubmitting) => {
    try {
      await services.post('/auth/register', values)
      await services.post('/auth/login', registerValues)
      history.push('/profile')
    } catch (e) {
      console.log(e)
      setSubmitting(false)
      setMailNew(false)
    }
    setSubmitting(false)
  }

  const [isMailNew, setMailNew] = useState(true)

  const onChangePassword = (e) => {
    const passwordLocal = e.target.value || ''

    const check = passwordCheck
    check.valid = false
    check.uppercase = /[A-Z]/.test(passwordLocal)
    check.number = /[0-9]/.test(passwordLocal)
    check.size = passwordLocal.length >= 6
    if (check.uppercase === true && check.number === true && check.size === true) check.valid = true
    check.equal = (passwordLocal === passwordRepeat)

    setPassword(passwordLocal)
    setPasswordCheck(check)
  }

  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')

  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: null,
    number: null,
    size: null,
    equal: null,
    valid: null
  })

  const onChangePasswordRepeat = (e) => {
    const passwordLocal = e.target.value || ''

    const passwordEqual = (passwordLocal === password)
    setPasswordRepeat(passwordLocal)
    setPasswordCheck({
      ...passwordCheck,
      equal: passwordEqual
    })
  }

  const [registerValues, setRegisterValues] = useState({
    email: '',
    password: ''
  })

  const registerSchema = Yup.object().shape()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        password: '',
        password_confirmation: ''
      }}
      validationSchema={registerSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting)
      }}
    >
      {({
        errors,
        touched,
        handleChange,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit}>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12">
              <div className={styles.social_media_wrapper}>
                <IonButton
                  mode="md"
                  color="primary"
                  size="full"
                  fill="outline"
                  shape="round"
                  className="default_button"
                  href="/2020-09/auth/facebook"
                >
                  <IonIcon
                    slot="start"
                    icon={logoFacebook}
                  >
                  </IonIcon>
                  Connect with Facebook
                </IonButton>
                <IonButton
                  mode="md"
                  color="tertiary"
                  size="full"
                  fill="outline"
                  shape="round"
                  className="default_button"
                  href="/2020-09/auth/google"
                >
                  <IonIcon
                    slot="start"
                    icon={logoGoogle}
                  >
                  </IonIcon>
                  Connect with Google
                </IonButton>
                <div className="d-flex ion-justify-content-center">
                  <hr/>
                  <p className="Body_1">Or register with your e-mail account</p>
                  <hr/>
                </div>
              </div>
              <IonList className="form">
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">First Name</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                  <IonInput
                    type="text"
                    name="first_name"
                    required
                    clearOnEdit={false}
                    onIonChange={handleChange}
                    placeholder="First Name"
                    onIonInput={(event) => {
                      setFormFilledCheck({
                        ...isFormFilledCheck,
                        typedFirst_name: true
                      })
                      handleChange(event)
                    }
                  }
                  >
                  </IonInput>
                      {errors.born_on && touched.born_on
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">Last Name</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                  <IonInput
                    type="text"
                    name="last_name"
                    required
                    clearOnEdit={false}
                    onIonChange={handleChange}
                    placeholder="Last Name"
                    onIonInput={(event) => {
                      setFormFilledCheck({
                        ...isFormFilledCheck,
                        typedLast_name: true
                      })
                      handleChange(event)
                    }}
                  >
                  </IonInput>
                      {errors.born_on && touched.born_on
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">E-mail Address</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                  <IonInput
                    className={!isMailNew ? 'has-error' : null}
                    type="email"
                    name="email"
                    required
                    onIonChange={handleChange}
                    placeholder="Email"
                    onIonInput={(event) => {
                      setFormFilledCheck({
                        ...isFormFilledCheck,
                        typedEmail: true
                      })
                      setRegisterValues({
                        ...registerValues,
                        email: event.target.value
                      })
                      handleChange(event)
                    }}
                  >
                  </IonInput>
                      {errors.born_on && touched.born_on
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">Password</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <IonInput
                    placeholder="Password"
                    className={`input-w-icon ${passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                    type={passwordFieldType.password}
                    name="password"
                    onIonChange={handleChange}
                    clearOnEdit={false}
                    onIonInput={(event) => {
                      setFormFilledCheck({
                        ...isFormFilledCheck,
                        typedPassword: true
                      })
                      setRegisterValues({
                        ...registerValues,
                        password: event.target.value
                      })
                      onChangePassword(event)
                      handleChange(event)
                    }}
                  >
                    <IonButton
                      mode="md"
                      slot="end"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('password')}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.passwordIcon}
                      >
                      </IonIcon>
                    </IonButton>
                  </IonInput>
                      {errors.currentPassword && touched.currentPassword
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                  <IonItem className="ion-margin-bottom item-no-padding">
                    <IonRow className="w-100">
                      <IonCol size="auto">
                        <IonButton mode="md" className="button-form-check" size="small" fill="clear" disabled>
                          {passwordCheck.uppercase === null && <IonIcon slot="start" icon={ellipseOutline} />}
                          {passwordCheck.uppercase === true && <IonIcon slot="start" icon={checkmarkCircle} color="success"/>}
                          {passwordCheck.uppercase === false && <IonIcon slot="start" icon={closeCircle} color="danger"/>}
                          1 uppercase
                        </IonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <IonButton mode="md" className="button-form-check ml-2" size="small" fill="clear" disabled>
                          {passwordCheck.number === null && <IonIcon slot="start" icon={ellipseOutline} />}
                          {passwordCheck.number === true && <IonIcon slot="start" icon={checkmarkCircle} color="success"/>}
                          {passwordCheck.number === false && <IonIcon slot="start" icon={closeCircle} color="danger"/>}
                          1 number
                        </IonButton>
                      </IonCol>
                      <IonCol size="auto">
                        <IonButton mode="md" className="button-form-check ml-2" size="small" fill="clear" disabled>
                          {passwordCheck.size === null && <IonIcon slot="start" icon={ellipseOutline} />}
                          {passwordCheck.size === true && <IonIcon slot="start" icon={checkmarkCircle} color="success"/>}
                          {passwordCheck.size === false && <IonIcon slot="start" icon={closeCircle} color="danger"/>}
                          6 characters
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                {/* Valitation: Checks chraracters, numbers, etc. */}
                <IonItem lines="none" className="item-no-padding">
                  <IonListHeader className="ion-no-padding">
                    <IonLabel>
                      <h4 className="Heading_5 text_sad_purple">Confirm password</h4>
                    </IonLabel>
                  </IonListHeader>
                </IonItem>
                <IonItem lines="none" className="ion-margin-bottom item-no-padding">
                  <IonInput
                    placeholder="Repeat password"
                    className={`input-w-icon ${(passwordCheck.equal != null && passwordCheck.equal === false) || passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                    type={passwordFieldType.passwordRepeat}
                    name="password_confirmation"
                    clearOnEdit={false}
                    onIonChange={handleChange}
                    onIonInput={(event) => {
                      setFormFilledCheck({
                        ...isFormFilledCheck,
                        typedPasswordRepeat: true
                      })
                      onChangePasswordRepeat(event)
                    }}
                  >
                    <IonButton
                      mode="md"
                      slot="end"
                      fill="clear"
                      tabindex="-1"
                      onClick={() => changeType('passwordRepeat')}
                    >
                      <IonIcon
                        slot="icon-only"
                        color="dark"
                        icon={passwordFieldType.passwordRepeatIcon}
                      >
                      </IonIcon>
                    </IonButton>
                  </IonInput>
                      {errors.currentPassword && touched.currentPassword
                        ? (
                        <IonItem className="ion-margin-bottom input-error-message">
                          <div className="input-error-label">
                            <IonIcon
                              icon={closeCircle}
                              color="danger"
                              slot="start"
                            />
                            {errors.born_on}
                          </div>
                        </IonItem>
                          )
                        : null}
                </IonItem>
                  {passwordCheck.equal != null && !passwordCheck.equal &&
                  <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                    <IonRow>
                      <IonCol>
                        <div className="input-error-label">
                          <IonIcon icon={closeCircle} color="danger" slot="start"/>
                          Both passwords should match
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                  }
                {/* Valitation: Both password should match */}
                <hr className={styles.divider}/>
                <IonItem lines="none" className="item-no-padding">
                  <p className="Body_2 ion-no-margin">
                    I read and accept the
                    <a href="https://basepaws.com/pages/terms-of-service" className="ml-2 Body_2_bold">Terms of Service</a>
                    .
                  </p>
                  <IonCheckbox
                  required
                  name="terms"
                  slot="start"
                  color="primary"
                  onIonChange={() => {
                    setFormFilledCheck({
                      ...isFormFilledCheck,
                      isTermsChecked: true
                    })
                  }}
                  />
                </IonItem>
                <IonItem lines="none" className="item-no-padding">
                  <p className="Body_2 ion-no-margin">
                    I read and accept the
                    <a href="https://basepaws.com/pages/privacy-policy" className="ml-2 Body_2_bold">Privacy Policy</a>
                    .
                  </p>
                  <IonCheckbox
                  required
                  name="terms"
                  slot="start"
                  color="primary"
                  onIonChange={() => {
                    setFormFilledCheck({
                      ...isFormFilledCheck,
                      isPrivacyChecked: true
                    })
                  }}
                  />
                </IonItem>
              </IonList>
            </IonCol>
                {!isMailNew &&
                <IonItem className="ion-margin-bottom item-no-padding input-error-message">
                  <IonRow>
                    <IonCol>
                      <div className="input-error-label">
                        <IonIcon icon={closeCircle} color="danger" slot="start"/>
                        This email already exists.
                      </div>
                    </IonCol>
                  </IonRow>
              </IonItem>
              }
          </IonRow>
          <div className={`${styles.card_footer} ion-justify-content-center`}>
            <LoaderButton
              type="submit"
              disabled={isSubmitting || !isFormFilled.every((currentValue) => currentValue === true) || !passwordCheck.equal || !passwordCheck.valid}
              color="primary"
              className="big_button"
              buttonText="Sign up"
              isSubmitting={isSubmitting}
            ></LoaderButton>
            {/* <IonButton
              type="submit"
              disabled={isSubmitting || !isFormFilled.every((currentValue) => currentValue === true) || !passwordCheck.equal || !passwordCheck.valid}
              color="primary"
              fill="solid"
              shape="round"
              className="big_button"
            >
            Sign up
            </IonButton> */}
          </div>
          <hr className={styles.divider}/>
          <p className="ion-text-center Body_1">
            Already have an account?
            <NavLink
              to="/login"
              className="ml-2"
            >
              Log in
            </NavLink>
          </p>
        </form>
      )}
    </Formik>
  )
}

export default RegisterForm
