import { IonCheckbox, IonCol, IonIcon, IonInput, IonItem, IonLabel, IonRow } from '@ionic/react'
import { useField } from 'formik'
import { checkmarkCircleSharp, informationCircleSharp } from 'ionicons/icons'
import React, { useContext, useState } from 'react'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'
import { Static } from '../Form/Static'

export const Boolean = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, info, editable = context.editable, list = [], name, label, placeholder, type, className, customClassName, bold } = props

  const [field, { value }, { setValue }] = useField(name)

  if (!editable) {
    return (
    <Row label={label}>
        <Static>{value ? 'Yes.' : 'No.'}</Static>
    </Row>
    )
  }
  if (editable) {
    return (
    <Row
    bold={bold}
    className={customClassName}
    info={info}
    label={label}>
      <IonItem className={`${styles.input_radio} input-radio p-0 ${className}`}>
              <IonCheckbox
                checked={value}
                name={name}
                onIonChange={e => setValue(e.detail.checked)}
                value={value}
              />
              {props.children &&
                <IonLabel className={'Body_small_regular'}>{props.children}</IonLabel>
              }
        </IonItem>
      </Row>)
  }
}
