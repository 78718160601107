import Age from './Age'
import AnimalFind from './AnimalFind'
import CheckList from './CheckList'
import DateField from './DateField'
import NewForm from './Form/Form'
import Message from './Message'
import Note from './Note'
import Password from './Password'
import Radio from './Radio'
import Select from './Select'
import Submit from './Submit'
import Text from './Text'
import UserFind from './UserFind'
import Button from './Button'
import Error from './Error'
import AutoSave from './Form/AutoSave'
import Boolean from './Boolean'

export default NewForm
export { AutoSave, Age, AnimalFind, CheckList, DateField, Message, Note, Password, Radio, Select, Submit, Text, UserFind, Button, Error, Boolean }
