import React from 'react'
import { IonCard, IonRow, IonCol, IonButton, IonIcon } from '@ionic/react'
import { enterSharp } from 'ionicons/icons'
import styles from './GreyBanner.module.scss'

const GreyBanner = (props) => {
  const { title, message, buttonText, buttonLink, buttonHref, small } = props

  return (
    <IonCard className={`${styles.card_grey_banner} ${small ? styles.small : ''} card`}>
      <IonRow className={`${small ? '' : 'ion-justify-content-between'} ion-align-items-center`}>
        <IonCol
          size='12'
          size-lg={small ? '12' : '7'}
          >
          <h3 className={`${small ? 'Heading_5' : 'Body_bold'} text_accent ion-no-margin`}>{title}</h3>
          <p className='Body_small_regular text_grey ion-no-margin'>{message}</p>
        </IonCol>
        <IonCol
          className={`${small ? '' : styles.text_md_right}`}
          size='12'
          size-lg={small ? '12' : '4'}
        >
          <IonButton
            className='default_button icon-end'
            color='primary'
            fill='solid'
            href={buttonHref}
            mode='md'
            routerLink={buttonLink}
            shape='round'
            target="_blank"
          >
            {buttonText}
            {!small && <IonIcon icon={enterSharp}/>}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonCard>
  )
}

export default GreyBanner
