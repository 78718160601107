import React from 'react'
import { alertCircleSharp } from 'ionicons/icons'
import styles from './PatientListItem.module.scss'
import HidingMessageIcon from 'components/basepaws/general/HidingMessageIcon'

const MissingEmailIcon = (props) => {
  const { text } = props

  return (
    <HidingMessageIcon
      icon={alertCircleSharp}
      iconColor='danger'
      iconStyle={styles.state_icon}
    >
      { text && 'Missing email'}
    </HidingMessageIcon>
  )
}

export default MissingEmailIcon
