import React from 'react'
import { withIonLifeCycle, IonRow, IonCol } from '@ionic/react'

import { connect } from 'react-redux'

import RegisterForm from 'components/basepaws/account/RegisterForm'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'

const AccountRegister = (props) => {
  const { user } = props
  return (
    <GeneralPage
      headerStyle='center'
      title={content.get('registeraccount.title')}
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
      user={user}
    >
      <IonRow className="ion-justify-content-center">
        <IonCol size="11"
          size-lg="4"
          size-md="6">
          <RegisterForm />
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountRegister))
