import React from 'react'
import Fuse from 'fuse.js'
import SelectSearch from 'react-select-search'
import './InputSelect.scss'

const InputSelect = (props: any) => {
  const { options } = props

  // the name fields is required
  const opt = options.map((o: any) => {
    const opt = o
    opt.name = o.name ?? o.value
    return opt
  })

  const fuzzySearch = (options: any) => {
    const fuse = new Fuse(options, {
      keys: ['name', 'groupName', 'items.name'],
      threshold: 0.1
    })

    return (value: any) => {
      if (!value.length) {
        return options
      }

      return fuse.search(value)
    }
  }

  return <>
    <SelectSearch
      filterOptions={fuzzySearch}
      options={opt}
      search={true}
      {...props}
    />
  </>
}

export default InputSelect
