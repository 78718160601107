import React, { useEffect, useState } from 'react'
import { IonRow, IonCol, useIonViewWillEnter, IonInput, IonRadio, IonLabel, IonTextarea, IonImg, IonIcon, IonCheckbox, IonButton, IonRadioGroup, IonGrid, IonItem, useIonViewDidEnter } from '@ionic/react'
import { closeSharp, copySharp, informationCircleSharp, mailSharp, save, calendarClearSharp, checkmarkCircleSharp } from 'ionicons/icons'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Formik, Form, Field } from 'formik'
import moment from 'moment'
import Moment from 'react-moment'
import diseases from './deceases'
import testTypes from './testTypes'

import Select from 'components/basepaws/utils/InputSelect'
import services, { servicesNew } from 'util/services'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import DateInputDesktop from 'components/basepaws/general/DateInputDesktop/'

import styles from './RequisitionFormPage.module.scss'
import Toaster from 'components/basepaws/utils/Toaster'
import content from 'util/content'
import { object, string } from 'yup'
import { DateField } from 'components/basepaws/form/DateField/DateFIeld'
// import { SearchBar } from 'components/basepaws/general/SearchFilterSort'

const RequisitionFormPage = (params) => {
  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()
  const [dateCalculation, setDateCalculation] = useState()
  const [isNewVet, setIsNewVet] = useState(false)
  const [isNewPet, setIsNewPet] = useState(false)
  const [isNewOwner, setIsNewOwner] = useState(false)
  const [isViewOnly, setIsViewOnly] = useState(false)
  const [isMailMissed, setIsMailMissed] = useState(false)
  const [requisition, setRequisition] = useState()
  const [toast, setToast] = useState()
  const [disableSubmit, setDisableSubmmit] = useState(false)
  const baseUrl = window.location.origin

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
  })

  const validationSchema = object().shape({
    testId: string().required(),
    testIdRepeat: string().required(),
    date: string().required(),
    vet: object().required(),
    pet: object({
      owner: object().required()
    }).required(),
    birthday: string().required(),
    species: string().required(),
    gender: string().required(),
    spayed: string().required()
  })

  const copyTextToClipboard = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
    setToast(content.get('REQUISITION_FORMS_LOG.TOAST.COPY_CLIPBOARD'))
  }

  const loadVetsList = (value, cback) => {
    const list = [{
      value: 'new',
      label: 'Add new Vet'
    },
    ...(servicesNew._vets || [])
      .filter(item => item.first_name?.match(value) || item.last_name?.match(value))
      .map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}   ${item.email}`,
        ...item
      }))
    ]
    cback(list)
    servicesNew.getOrganizationVets(user.organization?.id)
  }

  const loadPatientsList = (value, cback) => {
    const list = [{
      value: 'new',
      label: 'Add new Pet'
    },
    ...(servicesNew._patients || [])
      .filter(item => item.name.match(value))
      .map(item => ({
        value: item.id,
        label: `${item.name}`,
        ...item
      }
      ))
    ]

    cback(list)
    servicesNew.getOrganizationPatients(user.organization?.id)
  }

  const loadOwnersList = (value, cback) => {
    const list = [{
      value: 'new',
      label: 'Add new Owner'
    },
    ...(servicesNew._owners || [])
      .filter(item => item.first_name.match(value) || item.last_name.match(value))
      .map(item => ({
        value: item.id,
        label: `${item.first_name}`,
        ...item
      }
      ))
    ]

    cback(list)
    servicesNew.getOrganizationOwners(user.organization?.id)
  }

  useIonViewDidEnter(async () => {
    if (!user) return

    const id = params.match?.params?.handle
    if (id) {
      const req = (await servicesNew.get(`/requisitionForm?handle=${id}`))[0]
      setRequisition(req)
      setIsViewOnly(req.status == 'submitted')
      console.log('====', !req.pet?.owner?.email)
      setIsMailMissed(!req.pet?.owner?.email)
    } else {
      setIsViewOnly(false)
      setRequisition()
    };
    console.log('requisition form', id)
  }, [params.match.params.handle])

  const newRequisition = async (code) => {
    if (requisition?.id || !code) return
    const kit = await servicesNew.get(`/kit/${code}`)
    const req = await servicesNew.post('/requisitionForm/', { kit: { id: kit.id, code: kit.code, productName: kit.productName }, species: kit.target?.organism })
    setToast(content.get('REQUISITION_FORMS_LOG.TOAST.SAVED'))
    setRequisition(req)
  }
  const save = async (values) => {
    await servicesNew.put(`/requisitionForm/${values.requisitionId}`, values)
  }
  const onSubmit = async (values, setSubmitting) => {
    if (!isViewOnly) return setIsViewOnly(true)
    await servicesNew.put(`/requisitionForm/${values.requisitionId}/submit`, values)
    servicesNew.clearCache()
    history.push({
      pathname: '/requisition/success',
      state: {
        handle: requisition.handle,
        url: requisition.url
      }
    })
    setDisableSubmmit(true)
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  return (
    <GeneralPage
      headerStyle='left'
      isNotFixed={true}
      pageClass="page-no-margin"
      title={content.get('requisition_form_new.page.title')}
      user={user}
    >
      {requisition && <div className={styles.requisition_form_header}>
        <IonGrid fixed>
          <IonRow className="ion-justify-content-between">
            <IonCol className={styles.mb_sm_3}
                    size='12'
                    size-md='auto'>
              <div className='d-flex'>
                <div className={`${styles.tag} Body_regular text_light`}>
                  {requisition.handle}
                </div>
                <IonButton
                  className={`default_button icon-start ${styles.button_light_blue}`}
                  color="primary"
                  fill="fill"
                  mode="md"
                  onClick={() => copyTextToClipboard(requisition.handle)}
                  shape='round'
                >
                  <IonIcon src={copySharp}/>
                  Copy ID
                </IonButton>
              </div>
            </IonCol>
            <IonCol size='12'
                    size-md='auto'>
              <div className='d-flex'>
                <div className={`${styles.tag} Body_regular text_light`}>
                  {`${baseUrl}${requisition.url}`}
                </div>
                <IonButton
                  className={`default_button icon-start ${styles.button_light_blue}`}
                  color="primary"
                  fill="fill"
                  mode="md"
                  onClick={() => copyTextToClipboard(`${baseUrl}${requisition.url}`)}
                  shape='round'
                >
                  <IonIcon src={copySharp}/>
                  Copy URL
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div> }
      <IonGrid fixed>
        <Toaster
          header={content.get('REQUISITION_FORM_PAGE.TOASTER.SUCCESS')}
          message={toast}
          onDidDismiss={() => {
            setToast(null)
          }}
          status={toast != null}
        />
        {
        isViewOnly ||
        <div className={`${styles.disclaimer} ${styles.pink}`}>
          <IonIcon src={informationCircleSharp}/>
          <p className='Body_small_regular text_primary m-0'>{content.get('requisition_form_new.page.form_information')}</p>
        </div>
        }
        <Formik
          enableReinitialize={true}
          initialValues={{
            requisitionId: requisition?.id,
            organization_id: user.organization?.id,
            testId: requisition?.kit?.code,
            testIdRepeat: requisition?.kit?.code,
            testType: requisition?.kit?.productName,
            date: requisition?.date ? new Date(requisition?.date) : '',
            note: requisition?.note,
            vet: requisition?.vet,
            pet: requisition?.pet,
            gender: requisition?.gender,
            spayed: requisition?.spayed,
            birthday: requisition?.birthday ? new Date(requisition?.birthday) : '',
            species: requisition?.species,
            diagnosed_diseases: requisition?.diagnosed_diseases || [],
            terms: false
          }}
          key={requisition?.id}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
            setSubmitting,
            setFieldError,
            setFieldTouched
          }) => (
            <Form>
              <div className={`${styles.new_form_styles} form`}>

              {
              isViewOnly &&
              <IonRow className="mb-2">
                <IonCol
                  className={`${styles.labels_wrapper}`}
                  size='12'
                  size-md="3"
                >
                  <p className="Body_bold text_primary mr-2 my-2">Basepaws Account</p>
                </IonCol>
                <IonCol size-md="5">
                  <p className="Body_regular text_dark my-2">{user?.id}</p>
                </IonCol>
              </IonRow>
              }

              {
              isViewOnly &&
              <IonRow className="mb-2">
                <IonCol
                  className={`${styles.labels_wrapper}`}
                  size='12'
                  size-md="3"
                >
                  <p className="Body_bold text_primary mr-2 my-2">Clinic</p>
                </IonCol>
                <IonCol size-md="5">
                    <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                      <IonImg
                        className="mr-2"
                        src="/assets/icon/data/clinic.svg"
                      />
                      <p className="Body_regular text_dark my-2">{user?.organization?.name}</p>
                    </div>
                    <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                      <IonIcon
                        className="mr-2"
                        icon={mailSharp}
                        slot="start"
                      />
                      <p className="Body_regular text_dark my-2">{user?.organization?.email}</p>
                    </div>
                </IonCol>
              </IonRow>
              }

                {/* Barcode ID on Collection Tube */}
                {
                isViewOnly
                  ? <IonRow className="mb-2">
                <IonCol
                  className={`${styles.labels_wrapper}`}
                  size='12'
                  size-md="3"
                >
                  <p className="Body_bold text_primary mr-2 my-2">{content.get('requisition_form_new.page.test_id')}</p>
                </IonCol>
                <IonCol size-md="5">
                  <p className="Body_regular text_dark my-2">{values?.testId}</p>
                </IonCol>
              </IonRow>
                  : <IonRow className="mb-4">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2">{content.get('requisition_form_new.page.test_id')}</p>
                  </IonCol>
                  <IonCol size='12'
                          size-md="7">
                    <IonInput
                      className={`${styles.input_w_300} mb-2`}
                      name="testId"
                      onIonChange={e => {
                        handleChange(e)
                        const equals = e.detail.value === values.testIdRepeat
                        if (!equals) setFieldError('testIdRepeat', 'Test IDs does not match')
                        if (equals) newRequisition(values.testId)
                      }}
                      placeholder={content.get('requisition_form_new.page.test_id_placeholder')}
                      type="numeric"
                      value={values.testId}
                    />
                    <IonInput
                      className={`${styles.input_w_300} mb-2`}
                      name="testIdRepeat"
                      onIonChange={e => {
                        handleChange(e)
                        const equals = e.detail.value === values.testId
                        console.log(equals)
                        if (!equals) setFieldError('testIdRepeat', 'Test IDs does not match')
                        if (equals) newRequisition(values.testId)
                      }}
                      placeholder={content.get('requisition_form_new.page.test_id_placeholder_repeat')}
                      type="numeric"
                      value={values.testIdRepeat}
                    />
                  </IonCol>
                </IonRow>
                }

                {/* Test based on ID */}
                {
                isViewOnly
                  ? <IonRow className="mb-2">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2 my-2">Test based on ID</p>
                  </IonCol>
                  <IonCol size-md="5">
                    <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonIcon
                          className="mr-2"
                          icon={checkmarkCircleSharp}
                          slot="start"
                        />
                        <p className="Body_regular text_dark my-2">
                          {content.get(`report_card.${values.testType}.name`)}
                        </p>
                      </div>
                  </IonCol>
                </IonRow>
                  : <IonRow className="mb-4">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2">{content.get('requisition_form_new.page.test_select')}</p>
                  </IonCol>
                  <IonCol size='12'
                          size-md="7">
                    <IonRadioGroup
                      className="w-100"
                      name="testType"
                      onIonBlur={() => save(values)}
                      value={values.testType}
                    >
                      <div className={`${styles.d_md_flex}`}>
                        {testTypes.map((tt, i) => (
                          <div
                            className={`${styles.input_radio_wrapper} ${styles.mr_sm_0} input-radio-wrapper w-auto`}
                            key={`testTypes-${i}`}
                          >
                            <div className={`${styles.input_radio} input-radio`}>
                              <IonRadio
                                disabled
                                name='testType'
                                value={tt.value}
                              />
                              <IonLabel className='Body_regular text_grey'>{tt.label}</IonLabel>
                            </div>
                          </div>
                        ))}
                      </div>
                    </IonRadioGroup>
                    <div className="ion-margin-top">
                      <div className={`${styles.disclaimer} ${styles.blue} mt-2 mx-0`}>
                        <p className="Body_small_regular text_primary m-0">{content.get('requisition_form_new.page.contact')}
                          <a
                            className="Body_small_bold underline ml-2"
                            href="mailto:meow@basepaws.com"
                          >
                            meow@basepaws.com
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </IonCol>
                </IonRow>
                }

                {/* Collection Date */}

                <div id="datepicker-portal"></div>
                {
                isViewOnly
                  ? <IonRow className="mb-2">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2 my-2">{content.get('requisition_form_new.page.collection_date')}</p>
                  </IonCol>
                  <IonCol size-md="5">
                    <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonIcon
                          className="mr-2"
                          icon={calendarClearSharp}
                          slot="start"
                        />
                        <p className="Body_regular text_dark my-2">
                          <Moment format='MM/DD/YYYY'>
                            {values?.date}
                          </Moment>
                        </p>
                      </div>
                  </IonCol>
                </IonRow>
                  : <IonRow className="mb-4">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2">{content.get('requisition_form_new.page.collection_date')}</p>
                  </IonCol>
                  <IonCol size='12'
                          size-md="7">
                    <DateInputDesktop
                      // className={`${styles.input_w_300} mb-2`}
                      customInput={<IonInput
                        className={`${styles.input_w_300} mb-2`}
                        name='date'
                        // value={values.collectionDate}
                      />}
                      dateFormatCalendar="MMMM"
                      dateOptions = {{ startDate, endDate, setStartDate, setEndDate }}
                      format="MM/dd/yyyy"
                      label={content.get('requisition_form_new.page.date_placeholder')}
                      maxDate={new Date()}
                      name="date"
                      onChange={date => setFieldValue('date', date)}
                      onIonBlur={() => save(values)}
                      placeholder={content.get('requisition_form_new.page.date_placeholder')}
                      portal="#datepicker-portal"
                      selectRange={false}
                      selected={values.date}
                      showYearDropdown
                    />
                  </IonCol>
                </IonRow>
                }

                {/* Annotations */}
                {
                isViewOnly
                  ? <IonRow className="mb-2">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2 my-2">{content.get('requisition_form_new.page.annotations')}</p>
                  </IonCol>
                  <IonCol size-md="5">
                    <p className="Body_regular text_dark my-2">{values?.note}</p>
                  </IonCol>
                </IonRow>
                  : <IonRow className="mb-4">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2">{content.get('requisition_form_new.page.annotations')}</p>
                  </IonCol>
                  <IonCol size='12'
                          size-md="7">
                    <IonTextarea
                      name="note"
                      onIonBlur={() => save(values)}
                      onIonChange={handleChange}
                      placeholder={content.get('requisition_form_new.page.notes_placeholder')}
                      value={values.note}
                    />
                  </IonCol>
                </IonRow>
                }

                {/* Veterinarian */}
                {
                isViewOnly
                  ? <IonRow className="mb-2">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2 my-2">Veterinarian</p>
                  </IonCol>
                  <IonCol size-md="5">
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonImg
                          className="mr-2"
                          src="/assets/icon/data/vet.svg"
                        />
                        <p className="Body_regular text_dark my-2">
                          {values?.vet?.first_name} {values?.vet?.last_name}
                        </p>
                      </div>
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonIcon
                          className="mr-2"
                          icon={mailSharp}
                          slot="start"
                        />
                        <p className="Body_regular text_dark my-2">
                          {values?.vet?.email}
                        </p>
                      </div>
                  </IonCol>
                </IonRow>
                  : <IonRow className="mb-4">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2">{content.get('requisition_form_new.page.veterinarian')}</p>
                  </IonCol>
                  <IonCol size-md="7">
                    {isNewVet
                      ? <IonButton
                      className="default_button icon-start"
                      color="primary"
                      fill="clear"
                      mode='md'
                      onClick={() => {
                        setFieldValue('vet.id', '')
                        setFieldValue('vet.first_name', '')
                        setFieldValue('vet.last_name', '')
                        setFieldValue('vet.email', '')
                        setIsNewVet(false)
                      }}
                      shape="round"
                      type='button'
                    >
                      <IonIcon color='primary'
                               src={closeSharp}
                      />
                      Discard New Veterinarian
                    </IonButton>
                      : <Select
                      onChange={(value, e) => {
                        const isnew = !value.id
                        console.log(isnew ? '' : value.first_name)
                        setIsNewVet(isnew)
                        setFieldValue('vet.id', isnew ? null : value.id)
                        setFieldValue('vet.first_name', isnew ? '' : value.first_name)
                        setFieldValue('vet.last_name', isnew ? '' : value.last_name)
                        setFieldValue('vet.email', isnew ? '' : value.email)
                      }}
                      onIonBlur={() => save(values)}
                      options={loadVetsList}
                      placeholder={content.get('requisition_form_new.page.search_vet')}
                    />}
                    <div className={styles.d_md_flex}>
                      <IonInput
                        className={`${isNewVet ? '' : styles.data_disabled} ${styles.ml_md_0} ${styles.mr_md_1} mb-2`}
                        disabled={!isNewVet}
                        name="vet.first_name"
                        onBlur={() => save(values)}
                        onIonBlur={() => save(values)}
                        onIonChange={handleChange}
                        placeholder={content.get('requisition_form_new.page.vet_first_name')}
                        value={values.vet?.first_name}
                      >
                        <IonImg
                          className="ion-padding-start"
                          src="/assets/icon/data/vet.svg"
                        />
                      </IonInput>
                      <IonInput
                        className={`${isNewVet ? '' : styles.data_disabled} ${styles.mr_md_0} ${styles.ml_md_1} mb-2`}
                        disabled={!isNewVet}
                        name="vet.last_name"
                        onIonBlur={() => save(values)}
                        onIonChange={handleChange}
                        placeholder={content.get('requisition_form_new.page.vet_last_name')}
                        value={values.vet?.last_name}
                      >
                        <IonImg
                          className="ion-padding-start"
                          src="/assets/icon/data/vet.svg"
                        />
                      </IonInput>
                    </div>
                    <IonInput
                      className={`${isNewVet ? '' : styles.data_disabled} ${styles.ml_md_0} ${styles.mr_md_1} mb-2`}
                      disabled={!isNewVet}
                      name="vet.email"
                      onIonBlur={() => save(values)}
                      onIonChange={handleChange}
                      placeholder={content.get('requisition_form_new.page.vet_email')}
                      value={values.vet?.email}
                    >
                      <IonIcon
                        className="ion-padding-start"
                        icon={mailSharp}
                        slot="start"
                      />
                    </IonInput>
                    <div className={`${styles.input_message_info} d-flex ion-align-items-start no-wrap`}>
                      <IonIcon className='mr-1'
                               color='primary'
                               src={informationCircleSharp}/>
                      <p className='Body_small_regular text_grey m-0'>
                        {content.get('requisition_form_new.page.vet_warning')}
                      </p>
                    </div>
                  </IonCol>
                </IonRow>
                }

                {/* Patient */}
                {
                isViewOnly
                  ? <IonRow className="mb-2">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2 my-2">{content.get('requisition_form_new.page.patient')}</p>
                  </IonCol>
                  <IonCol size-md="5">
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonImg
                          className="mr-2"
                          src="/assets/icon/data/pet.svg"
                        />
                        <p className="Body_regular text_dark my-2">{values?.pet?.name}</p>
                      </div>
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonImg
                          className="mr-2"
                          src="/assets/icon/data/pet-owner.svg"
                        />
                        <p className="Body_regular text_dark my-2">{values?.pet?.owner.first_name}</p>
                      </div>
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonImg
                          className="mr-2"
                          src="/assets/icon/data/pet-owner.svg"
                        />
                        <p className="Body_regular text_dark my-2">{values?.pet?.owner.last_name}</p>
                      </div>
                      {
                        values?.pet?.owner.email &&
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                          <IonIcon
                            className="mr-2"
                            icon={mailSharp}
                            slot="start"
                          />
                        <p className="Body_regular text_dark my-2">{values?.pet?.owner.email}</p>
                      </div>
                      }
                  </IonCol>
                </IonRow>
                  : <IonRow className="mb-4">
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_bold text_primary mr-2">
                    {content.get('requisition_form_new.page.patient')}
                    </p>
                  </IonCol>
                  <IonCol size='12'
                          size-md="7">
                    {isNewPet
                      ? <IonButton
                      className="default_button icon-start"
                      color="primary"
                      fill="clear"
                      mode='md'
                      onClick={() => {
                        setFieldValue('pet.name', '')
                        setIsNewPet(false)
                        setIsNewOwner(false)
                      }}
                      onIonBlur={() => save(values)}
                      shape="round"
                      type='button'
                    >
                      <IonIcon color='primary'
                               src={closeSharp}/>
                      Discard New Pet
                    </IonButton>
                      : <Select
                      onChange={(value, e) => {
                        const isnew = !value.id

                        setFieldValue('pet.id', isnew ? null : value.id)
                        setFieldValue('pet.name', isnew ? '' : value.name)
                        setFieldValue('birthday', isnew ? '' : value.born_on)
                        setFieldValue('gender', isnew ? '' : value.gender)
                        setFieldValue('species', isnew ? '' : value.organism)
                        setFieldValue('spayed', isnew ? '' : value.spayed)
                        if (!isnew) {
                          setFieldValue('pet.owner.id', value.owner.id)
                          setFieldValue('pet.owner.first_name', value.owner.first_name)
                          setFieldValue('pet.owner.last_name', value.owner.last_name)
                          setFieldValue('pet.owner.email', value.owner.email)
                        }
                        if (value.born_on) {
                          const date = new Date(value.born_on)
                          const today = moment(new Date())
                          const years = today.diff(date, 'years')
                          const months = today.diff(date, 'months') % 12
                          setDateCalculation({ years, months })
                        }
                        setIsNewPet(isnew)
                      }}
                      options={loadPatientsList}
                      placeholder={content.get('requisition_form_new.page.search_pet')}
                      value={''}
                    />
                  }
                    <div>
                      <IonInput
                        className={`${isNewPet ? '' : styles.data_disabled} ${styles.ml_md_0} ${styles.mr_md_0} mb-2`}
                        disabled={!isNewPet}
                        name='pet.name'
                        onIonBlur={() => save(values)}
                        onIonChange={handleChange}
                        placeholder={content.get('requisition_form_new.page.user_pet_name')}
                        value={values.pet?.name}
                      >
                        <IonImg
                          className="ion-padding-start"
                          src="/assets/icon/data/pet.svg"
                        />
                      </IonInput>
                    </div>
                    {isNewOwner
                      ? <IonButton
                      className="default_button icon-start"
                      color="primary"
                      fill="clear"
                      mode='md'
                      onClick={() => {
                        setFieldValue('pet.owner.first_name', '')
                        setFieldValue('pet.owner.last_name', '')
                        setFieldValue('pet.owner.email', '')
                        setIsNewOwner(false)
                      }}
                      shape="round"
                      type='button'
                    >
                      <IonIcon color='primary'
                               src={closeSharp}/>
                      Discard New Pet Owner
                    </IonButton>
                      : <Select
                      className={`${isNewPet ? '' : styles.data_disabled} ${styles.ml_md_0} ${styles.mr_md_1} mb-2`}
                      disabled={!isNewPet}
                      name='owner'
                      onChange={(value, e) => {
                        const isnew = !value.id
                        setFieldValue('pet.owner.id', isnew ? null : value.id)
                        setFieldValue('pet.owner.first_name', isnew ? null : value.first_name)
                        setFieldValue('pet.owner.last_name', isnew ? null : value.last_name)
                        setFieldValue('pet.owner.email', isnew ? null : value.email)
                        setIsNewOwner(isnew)
                      }}
                      onIonBlur={() => save(values)}
                      options={loadOwnersList}
                      placeholder={content.get('requisition_form_new.page.search_pet_owner')}
                      value={''}
                    />
                  }

                    <div className={styles.d_md_flex}>
                      <IonInput
                        className={`${isNewOwner ? '' : styles.data_disabled} ${styles.ml_md_0} ${styles.mr_md_1} mb-2`}
                        disabled={!isNewOwner}
                        name='pet.owner.first_name'
                        onIonBlur={() => save(values)}
                        onIonChange={handleChange}
                        placeholder={content.get('requisition_form_new.page.user_first_name_placeholder')}
                        value={values.pet?.owner?.first_name}
                      >
                        <IonImg
                          className="ion-padding-start"
                          src="/assets/icon/data/pet-owner.svg"
                        />
                      </IonInput>
                      <IonInput
                        className={`${isNewOwner ? '' : styles.data_disabled} ${styles.mr_md_0} ${styles.ml_md_1} mb-2`}
                        disabled={!isNewOwner}
                        name='pet.owner.last_name'
                        onIonBlur={() => save(values)}
                        onIonChange={handleChange}
                        placeholder={content.get('requisition_form_new.page.user_last_name_placeholder')}
                        value={values.pet?.owner?.last_name}
                      >
                        <IonImg
                          className="ion-padding-start"
                          src="/assets/icon/data/pet-owner.svg"
                        />
                      </IonInput>
                    </div>
                    <IonInput
                      className={`${isNewOwner ? '' : styles.data_disabled} ${styles.ml_md_0} ${styles.mr_md_0} mb-2`}
                      disabled={!isNewOwner}
                      name='pet.owner.email'
                      onIonBlur={() => save(values)}
                      onIonChange={handleChange}
                      placeholder={content.get('requisition_form_new.page.user_email_placeholder')}
                      value={values.pet?.owner?.email}
                    >
                      <IonIcon
                        className="ion-padding-start"
                        icon={mailSharp}
                        slot="start"
                      />
                    </IonInput>
                  </IonCol>
                </IonRow>
                }

                {
                  isMailMissed &&
                  <IonRow>
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  ></IonCol>
                  <IonCol size-md="5">
                  <IonInput
                  className={'mb-2'}
                  disabled={false}
                  name='pet.owner.email'
                  onIonChange={handleChange}
                  placeholder={content.get('requisition_form_new.page.user_email_placeholder')}
                  value={values.pet?.owner?.email}
                  />
                  </IonCol>
                  </IonRow>
                }

                {/* This class 'data_disabled' disabled all inputs (only css), checkbox and buttons, same as Figma */}
                <div
                  // className={`${styles.data_disabled}`}
                >

                  {/* Patient Birth Date */}
                  {(values.pet?.id && values.birthday) || isViewOnly
                    ? <IonRow>
                  <IonCol
                    className={`${styles.labels_wrapper}`}
                    size='12'
                    size-md="3"
                  >
                    <p className="Body_regular text_primary mr-2 my-2">{content.get('requisition_form_new.page.birth_date')}</p>
                  </IonCol>
                  <IonCol size-md="5">
                      <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                        <IonIcon
                          className="mr-2"
                          icon={calendarClearSharp}
                          slot="start"
                        />
                        <p className="Body_1 text_black mr-2 my-2">
                          <Moment format='MM/DD/YYYY'>
                            {values.birthday}
                          </Moment>
                        </p>
                      </div>
                  </IonCol>
                </IonRow>
                    : <IonRow>
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className={'Body_regular text_primary mr-2'}>{content.get('requisition_form_new.page.birth_date')}</p>
                    </IonCol>
                    <IonCol
                      size='12'
                      size-md="6">
                      <DateInputDesktop
                        // className={`${styles.input_w_300} mb-2`}
                        customInput={<IonInput
                          className={`${styles.input_w_300} mb-2`}
                          name='birthday'
                          // value={values.collectionDate}
                        />}
                        dateFormatCalendar="MMMM"
                        dateOptions = {{ startDate, endDate, setStartDate, setEndDate }}
                        format="MM/dd/yyyy"
                        label={content.get('requisition_form_new.page.collection_date_placeholder')}
                        maxDate={new Date()}
                        name="birthday"
                        onBlur={() => save(values)}
                        onChange={(date) => {
                          setFieldValue('birthday', date)
                          const today = moment(new Date())
                          const years = today.diff(date, 'years')
                          const months = today.diff(date, 'months') % 12

                          setDateCalculation({ years, months })
                        }}
                        placeholder={content.get('requisition_form_new.page.collection_date_placeholder')}
                        portal="#datepicker-portal"
                        selectRange={false}
                        selected={values.birthday}
                        showYearDropdown
                      />
                    </IonCol>
                  </IonRow>}

                  {/* Patient Age */}
                  {(values.pet?.id && values.birthday) || isViewOnly
                    ? <IonRow className="mb-2">
                   <IonCol
                     className={`${styles.labels_wrapper}`}
                     size='12'
                     size-md="3"
                   >
                     <p className="Body_regular text_dark mr-2 my-2">Age</p>
                   </IonCol>
                   <IonCol
                    size='12'
                    size-md="5"
                   >
                       <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                         <p className="Body_regular text_dark my-2">
                         {dateCalculation?.years}y {dateCalculation?.months}m
                         </p>
                       </div>
                   </IonCol>
                 </IonRow>
                    : <IonRow>
                    <IonCol
                      className={styles.labels_wrapper}
                      size='12'
                      size-md="3"
                    >
                      <p className={'Body_regular text_primary mr-2'}>{content.get('requisition_form_new.page.age')}</p>
                    </IonCol>
                    <IonCol size='6'
                            size-md="3">
                      <div className={styles.d_md_flex}>
                        <IonInput
                          className={`mb-2 ${styles.ml_md_0} ${styles.mr_md_1}`}
                          disabled
                          placeholder="Years"
                          value={dateCalculation?.years ? `${dateCalculation?.years} Years` : null}
                        />
                        <IonInput
                          className={`mb-2 ${styles.mr_md_0} ${styles.ml_md_1}`}
                          disabled
                          placeholder="Months"
                          value={dateCalculation?.months ? `${dateCalculation?.months} Months` : null}
                        />
                      </div>
                    </IonCol>
                  </IonRow>
                  }

                  {/* Patient Specie */}
                  {(values.pet?.id && values.species) || isViewOnly
                    ? <IonRow className="mb-2">
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className="Body_regular text_primary mr-2 my-2">Specie</p>
                    </IonCol>
                    <IonCol
                      size='12'
                      size-md="5"
                    >
                        <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                          <IonIcon
                            className="mr-2"
                            icon={checkmarkCircleSharp}
                            slot="start"
                          />
                          <p className="Body_regular text_dark my-2">
                            {values?.species}
                          </p>
                        </div>
                    </IonCol>
                  </IonRow>
                    : <IonRadioGroup
                      className="w-100"
                      name="species"
                      onIonBlur={() => save(values)}
                      onIonChange={handleChange}
                      value={values.species}
                    >
                    <IonRow>
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className={'Body_regular text_primary mr-2'}>
                      {content.get('requisition_form_new.page.specie')}
                      </p>
                    </IonCol>
                    <IonCol size='12'
                            size-md="6">
                      <div className="d-flex">
                        <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper}`}>
                          <IonItem className={`${styles.input_radio} input-radio item-no-padding`}>
                            <IonRadio
                              name='species'
                              value="dog"
                            />
                            <IonLabel>
                              Dog
                            </IonLabel>
                          </IonItem>
                        </div>
                        <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper}`}>
                          <IonItem className={`${styles.input_radio} input-radio item-no-padding`}>
                            <IonRadio
                              name='species'
                              value="cat"
                            />
                            <IonLabel>
                              Cat
                            </IonLabel>
                          </IonItem>
                        </div>
                      </div>
                    </IonCol>
                    </IonRow>
                  </IonRadioGroup>
                  }

                  {/* Patient Gender */}
                  {(values.pet?.id && values.gender) || isViewOnly
                    ? <IonRow className="mb-2">
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className="Body_regular text_primary mr-2 my-2">Sex</p>
                    </IonCol>
                    <IonCol size-md="5">
                        <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                          <IonIcon
                            className="mr-2"
                            icon={checkmarkCircleSharp}
                            slot="start"
                          />
                          <p className="Body_regular text_dark my-2">{values?.gender}</p>
                        </div>
                    </IonCol>
                  </IonRow>
                    : <IonRadioGroup
                      className="w-100"
                      name="gender"
                      onIonBlur={() => save(values)}
                      onIonChange={handleChange}
                      value={values.gender}
                    >
                    <IonRow>
                      <IonCol
                        className={`${styles.labels_wrapper}`}
                        size='12'
                        size-md="3"
                      >
                        <p className={'Body_regular text_primary mr-2'}>
                        {content.get('requisition_form_new.page.gender')}
                        </p>
                      </IonCol>
                      <IonCol size='12'
                              size-md="6">
                        <div className="d-flex">
                          <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper}`}>
                            <IonItem className={`${styles.input_radio} input-radio item-no-padding`}>
                              <IonRadio
                                name='gender'
                                value="female"
                              />
                              <IonLabel>Female</IonLabel>
                            </IonItem>
                          </div>
                          <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper}`}>
                            <IonItem className={`${styles.input_radio} input-radio item-no-padding`}>
                                <IonRadio
                                  name='gender'
                                  value="male"
                                />
                                <IonLabel>Male</IonLabel>
                            </IonItem>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonRadioGroup>
                  }

                  {/* Patient Spayed/Neutered */}
                  {(values.pet?.id && values.spayed) || isViewOnly
                    ? <IonRow className="mb-2">
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className="Body_regular text_primary mr-2 my-2">Spayed/Neutered</p>
                    </IonCol>
                    <IonCol size-md="5">
                        <div className={`${styles.precharged_data} d-flex ion-align-items-center`}>
                          <IonIcon
                            className="mr-2"
                            icon={checkmarkCircleSharp}
                            slot="start"
                          />
                          <p className="Body_regular text_dark my-2">{values?.spayed}</p>
                        </div>
                    </IonCol>
                  </IonRow>
                    : <IonRadioGroup
                      className="w-100"
                      name="spayed"
                      onIonBlur={() => save(values)}
                      onIonChange={handleChange}
                      value={values.spayed}
                    >
                    <IonRow className="mb-4">
                      <IonCol
                        className={`${styles.labels_wrapper}`}
                        size='12'
                        size-md="3"
                      >
                        <p className={'Body_regular text_primary mr-2'}>{content.get('requisition_form_new.page.procedure')}</p>
                      </IonCol>
                      <IonCol size='12'
                              size-md="6">
                        <div className="d-flex">
                          <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper}`}>
                            <IonItem className={`${styles.input_radio} input-radio item-no-padding`}>
                                <IonRadio
                                  name='spayed'
                                  value="yes"
                                />
                                <IonLabel>Yes</IonLabel>
                            </IonItem>
                          </div>
                          <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper}`}>
                            <IonItem className={`${styles.input_radio} input-radio`}>
                              <IonRadio
                                name='spayed'
                                value="no"
                              />
                              <IonLabel>No</IonLabel>
                            </IonItem>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonRadioGroup>
                  }

                  {/* Patient Diagnosed Diseases */}
                  {
                  isViewOnly
                    ? <IonRow className="mb-4">
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className="Body_regular text_primary mr-2 my-2">{content.get('requisition_form_new.page.diseases')}</p>
                    </IonCol>
                    <IonCol size-md="5">
                        {values?.diagnosed_diseases?.map((disease, index) => (
                          <div className={`${styles.precharged_data} d-flex ion-align-items-center`}
                               key={`diagnostic-diseases-${index}`}>
                            <IonIcon
                              className="mr-2"
                              icon={checkmarkCircleSharp}
                              slot="start"
                            />
                            <p className="Body_regular text_dark my-2">{diseases[values.species].find(x => x.value === disease)?.label}</p>
                          </div>
                        ))}

                        {/* Checkbox legals */}
                        {values?.terms == 'on' &&
                          <div className={`${styles.precharged_data} d-flex no-wrap ion-align-items-center`}>
                            <IonIcon
                              className="mr-2"
                              icon={checkmarkCircleSharp}
                              slot="start"
                            />
                            <p className="Body_small_regular text_dark my-2">
                            I accept to participate in
                          <a
                            className="ml-1 mr-1 Body_small_bold text_accent"
                            href="https://basepaws.com/pages/terms-of-service"
                          >
                            Research
                          </a>
                          since prior medical history in recognizing genetic patterns and improving our research.
                            </p>
                          </div>
                        }

                    </IonCol>
                  </IonRow>
                    : <IonRow className="mb-4">
                    <IonCol
                      className={`${styles.labels_wrapper}`}
                      size='12'
                      size-md="3"
                    >
                      <p className={'Body_regular text_primary mr-2'}>{content.get('requisition_form_new.page.diseases')}</p>
                    </IonCol>
                    <IonCol size='12'
                            size-md="6">
                      <div className={`${styles.input_message_info} d-flex ion-align-items-start no-wrap mt-2 mb-4`}>
                        <IonIcon className='mr-1'
                                 color='primary'
                                 src={informationCircleSharp}/>
                        <p className='Body_small_regular text_grey m-0'>{content.get('requisition_form_new.page.select_info')}</p>
                      </div>

                        {values.species &&
                          <IonRow className="mb-4">
                          {diseases[values.species].map((disease, index) => (
                            <IonCol
                              className={styles.col_sm_p0}
                              key={`disease-${index}`}
                              size='12'
                              size-md="6"
                            >
                              <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper} ${styles.mr_sm_0}`}>
                                <div className={`${styles.input_radio} input-radio p-0`}>
                                  <IonItem>
                                  <IonCheckbox
                                    checked={values.diagnosed_diseases.includes(disease.value)}
                                    name="diagnosed_diseases"
                                    onIonBlur={() => save(values)}
                                    onIonChange={(event) => {
                                      if (event.detail.checked) { setFieldValue('diagnosed_diseases', [...values.diagnosed_diseases, disease.value]) } else { setFieldValue('diagnosed_diseases', values.diagnosed_diseases.filter(item => item != disease.value)) }
                                    }}
                                    value={disease.value}
                                  />
                                  <IonLabel>{disease.label}</IonLabel>
                                  </IonItem>
                                </div>
                              </div>
                            </IonCol>
                          ))}
                          <IonCol className={styles.col_sm_p0}
                                  size='12'
                                  size-md="6">
                            <div className={`input-radio-wrapper w-auto ${styles.input_radio_wrapper} ${styles.mr_sm_0}`}>
                            <IonInput
                              className={`mb-2 ${styles.ml_md_0}`}
                              disabled={!values.diagnosed_diseases?.includes('other')}
                              placeholder="Add other diagnosed disease"
                              />
                            </div>
                          </IonCol>
                        </IonRow>
                        }

                      {/* Checkbox legals */}
                      <div className={`${styles.input_checkbox} d-flex no-wrap ion-align-items-start mt-3`}>
                        <IonCheckbox
                          color="primary"
                          name="terms"
                          onIonBlur={() => save(values)}
                          onIonChange={handleChange}
                          required
                        />
                        <p className="Body_small_regular text_dark ion-no-margin">
                          I accept to participate in
                          <a
                            className="ml-1 mr-1 Body_small_bold"
                            href="https://basepaws.com/pages/terms-of-service"
                          >
                            Research
                          </a>
                          since prior medical history in recognizing genetic patterns and improving our research.
                        </p>
                      </div>
                    </IonCol>
                  </IonRow>
                  }

                  {/* Buttons */}
                  <IonRow className='ion-justify-content-end'>
                    <IonCol size='12'
                            size-md="9">

                      <div className="d-flex ion-align-items-center">
                        {disableSubmit
                          ? null
                          : <IonButton
                          className="default_button mr-3"
                          color="primary"
                          disabled={Object.keys(errors).length > 0}
                          fill="solid"
                          shape="round"
                          type='submit'
                        >
                          Submit
                        </IonButton>}

                        {Object.keys(errors).length > 0 && <p className='Body_small_regular text_dark'>{content.get('requisition_form_submit_warning')}</p>}
                      </div>
                    </IonCol>
                  </IonRow>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </IonGrid>

    </GeneralPage>
  )
}

export default RequisitionFormPage
