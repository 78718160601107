import React from 'react'
import { Message } from 'components/basepaws/form'

import styles from './ReportModals.module.scss'
import MissingEmailWidget from '../PatientListItem/MissingEmailWidget'

const MissingEmailWarning = (props) => {
  const { patient, toastMessage } = props

  return (
    <>
      <Message
        className='mb-3 mt-0'
        info
        red
        styles={styles}
      >
        <b>Missing Email:</b> Please complete it.
      </Message>
      {patient
        ? <MissingEmailWidget
        pet={patient}
        requisitionForm={patient?.reports[0].requisition}
        toastMessage={toastMessage}
      />
        : ''
      }
    </>
  )
}

export default MissingEmailWarning
