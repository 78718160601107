import React from 'react'
import { useHistory } from 'react-router'

import { IonButton, IonIcon } from '@ionic/react'
import { arrowBackSharp } from 'ionicons/icons'

import styles from './BackButton.module.scss'

const BackButton = () => {
  const history = useHistory()

  return (
    <>
      <IonButton
        mode="md"
        onClick={() => history.goBack()}
        shape="round"
        fill="outline"
        color="primary"
        class={`${styles.back_button} icon-start default_button`}
      >
        <IonIcon icon={arrowBackSharp}></IonIcon>
        Back
      </IonButton>
    </>
  )
}

export default BackButton
