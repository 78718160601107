import React, { useState } from 'react'
import { IonRow, IonCol, useIonViewWillEnter, IonGrid } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import services from 'util/services'
import styles from './VetResources.module.scss'
import content from 'util/content'
import EmptyState from 'components/basepaws/general/EmptyState'
import { documentAttachSharp } from 'ionicons/icons'
import IconTitle from 'components/basepaws/vetPortal/IconTitle'
import ResourceItem from 'components/basepaws/vetPortal/ResourceItem'
import GreyBanner from 'components/basepaws/vetPortal/GreyBanner'
import Skeleton from 'components/basepaws/utils/Skeleton'

const VetResources = () => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  useIonViewWillEnter(async () => {
    setLoading(true)
    if (!user.id) {
      await load()
    }
    const organizationId = user?.organization?.id
    services.getResources(organizationId)
    setLoading(false)
  })

  const user = useSelector(state => state.userInReducer.user)

  // API mocked response
  const resources = useSelector(state => state.userInReducer.resources)

  const guideDetail = (guides) => {
    const detail = guides.map((guide, index) => {
      return (
        <ResourceItem
          buttonText='Download'
          className={styles.item}
          description={guide.content}
          href={guide.url}
          key={`resource-item-${index}`}
          title={guide.title}
        />
      )
    })
    return detail
  }

  // No resources.
  const emptyList = (
    <EmptyState
      asset={documentAttachSharp}
      assetType='icon'
      text={content.get('VET_RESOURCES.EMPTY_LIST.CONTENT')}
      title={content.get('VET_RESOURCES.EMPTY_LIST.TITLE')}
    />
  )

  const resourceList = (
    <IonGrid fixed>
      <IonRow className='ion-justify-content-between'>
        <IonCol
          size='12'
          size-lg='7'
        >
          {resources?.types?.map((resourceName) => {
            return (
              <div className={styles.resource_list}
                   key={`resources-${resourceName}`} >
                <IconTitle
                  img={`assets/icon/tests/${resources[resourceName].guideName}.svg`}
                  title={content.get(`VET_RESOURCES.GUIDES.${resourceName}`)}
                />
                {guideDetail(resources[resourceName].guides)}
              </div>
            )
          })}
        </IonCol>
        <IonCol
          size='12'
          size-lg='4'
        >
          <GreyBanner
            buttonHref={content.get('VET_RESOURCES.BANNER.EMAIL')}
            buttonLink=''
            buttonText={content.get('VET_RESOURCES.BANNER.CTA')}
            message={content.get('VET_RESOURCES.BANNER.CONTENT')}
            small
            title={content.get('VET_RESOURCES.BANNER.TITLE')}
          />
        </IonCol>
      </IonRow>
    </IonGrid>
  )

  return (
    <GeneralPage
      headerStyle='left'
      title="Resources"
      user={user}
    >
      {loading
        ? <IonGrid fixed>
          <Skeleton
            animated={true}
            colsSizes={[1, 4, 4, 3]}
            customStyle={{ height: '20px' }}
            rows={3}
          />
        </IonGrid>
        : ((!resources || resources.length === 0)
            ? emptyList
            : resourceList)
      }
    </GeneralPage>
  )
}

export default VetResources
