import React from 'react'
import { IonRow, IonCol } from '@ionic/react'

import { useSelector } from 'react-redux'

import RecoverPasswordFormEmail from 'components/basepaws/account/RecoverPasswordForms/RecoverPasswordFormEmail'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'

const AccountRecoverPasswordEmail = () => {
  const user = useSelector(state => state.userInReducer.user)

  return (
    <GeneralPage
      headerStyle='center'
      title={content.get('recoverpassword.title')}
      titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
      user={user}
    >
      <IonRow className="ion-justify-content-center">
        <IonCol size="11"
          size-lg="4"
          size-md="6">
          <RecoverPasswordFormEmail/>
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default AccountRecoverPasswordEmail
