import configureStore from 'store/configureStore'
import * as actions from 'store/actions/index'
import services from 'util/services'

const store = configureStore()

store.setUser = (user) => {
  store.dispatch(actions.setUser(user))
}

store.setActiveOrganization = (organizationId) => {
  store.dispatch(actions.setActiveOrganization(organizationId))
  services.getKitsInfo(organizationId)
  services.getSummary(organizationId)
  services.getPatients(organizationId)
  services.getResources(organizationId)

  // this is called in page
  // services.getContributors(organizationId)
}

store.setOrganization = (organization) => {
  store.dispatch(actions.setOrganization(organization))
}

store.setKitsInfo = (kitsInfo) => {
  store.dispatch(actions.setKitsInfo(kitsInfo))
}

store.setKits = (kits) => {
  store.dispatch(actions.setKits(kits))
}

store.setVets = (vets) => {
  store.dispatch(actions.setVets(vets))
}

store.setPatients = (patients) => {
  store.dispatch(actions.setPatients(patients))
}

store.setResources = (resources) => {
  store.dispatch(actions.setResources(resources))
}

store.setContributors = (contributors) => {
  store.dispatch(actions.setContributors(contributors))
}

store.removeContributor = (contributorId) => {
  store.dispatch(actions.removeContributor(contributorId))
}

store.cancelContributorInvitation = (contributorId) => {
  store.dispatch(actions.cancelContributorInvitation(contributorId))
}

export default store
