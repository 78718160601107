/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { IonRow, IonCol, IonList, IonItem, IonCheckbox, IonIcon, IonLabel } from '@ionic/react'
import { useSelector } from 'react-redux'

import { Modal } from 'components/basepaws/general/Modal/Modal'
import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import { checkmarkSharp } from 'ionicons/icons'
import MissingEmailWarning from './MissingEmailWarning'
import PetDetails from './PetDetails'
import { servicesNew } from 'util/services'
import content from 'util/content'
import delay from 'util/delay'
import styles from './ReportModals.module.scss'

const ModalReviewReport = (props) => {
  // Keep in mind that this component is used in more than one place.
  // If more props are included, they will have to be included in all those other places.
  const { modalState, close, patient, report } = props

  const [showModalSuccess, setShowModalSuccess] = useState(false)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [checkboxReportReviewed, setCheckboxReportReviewed] = useState(false)

  const user = useSelector(state => state.userInReducer.user)

  useEffect(() => {
    if (!checkboxReportReviewed) {
      setShowModalSuccess(false)
    } else {
      setCheckboxReportReviewed(false)
    }
  }, [report, patient, close])

  const modalSubTitle =
    <p className='Body_regular text_grey m-0'>
      {content.get('REVIEW_REPORT.SUBTITLE')}
    </p>

  const modalSuccess =
    <>
      <span className='success-section-circle mt-4'>
        <IonIcon
          color='success'
          src={checkmarkSharp}
        />
      </span>
      <h1 className='Heading_3 text_primary ion-text-center'>{content.get('REVIEW_REPORT.MODAL_SUCCESS.TITLE')}</h1>
      <p className='Lead m-0 text_grey ion-text-center px-3'>{content.get('REVIEW_REPORT.MODAL_SUCCESS.MESSAGE')}</p>
    </>

  const submitReviewReport = async () => {
    setIsSubmitting(true)
    const reportReviewed = report
    reportReviewed.reviewed = new Date()
    servicesNew.setReportReviewed(reportReviewed)
    await servicesNew.clearCache()
    await delay(1_000) // delay included as part of https://zoetis.atlassian.net/browse/WEB-303. Why is it needed? IDK.
    const patients = await servicesNew.getPatients(user.organization.id)
    setShowModalSuccess(true)
    props.reloadList(patients)
    setIsSubmitting(false)
    close()
  }

  const modalContents =
    <IonRow className="ion-justify-content-center ion-justify-content-between">
      <IonCol size="12"
              size-md="12">
        {modalSubTitle}
        <IonList className="form mt-3">
          {!patient?.owner?.email &&
            <MissingEmailWarning
              patient={patient}
              toastMessage={{
                error: 'Error when trying to update email',
                success: 'Email changed successfully!'
              }}
            />
          }
          <PetDetails
            patient={patient}
            report={report}
            showClientReportLink={false}
            showVetReportLink={true}
          />
          <IonItem
            className={`${styles.box} mt-3 item-no-padding`}
            lines="none"
          >
            <IonLabel className="checkbox-label ion-no-margin">
              {content.get('REVIEW_REPORT.READ_AND_REVIEW')}
            </IonLabel>
            <IonCheckbox
              color="primary"
              name='reportReviewed'
              onClick={() => {
                setCheckboxReportReviewed(current => !current)
              }}
              slot="start"
              value={checkboxReportReviewed}
            />
          </IonItem>
        </IonList>

        <div className="d-flex ion-justify-content-end">
          <LoaderButton
            buttonText="Confirm"
            className="big_button mt-3"
            color="primary"
            disabled={isSubmitting || !checkboxReportReviewed}
            isSubmitting={isSubmitting}
            onClick={() => {
              submitReviewReport()
            }}
            type="submit"
          >
          </LoaderButton>
        </div>
      </IonCol>
    </IonRow>

  return (
    <Modal
      {...props}
      close={close}
      headingText={showModalSuccess ? '' : 'Review Report'}
      modalState={modalState}
    >
      {!showModalSuccess && modalContents}
      {showModalSuccess && modalSuccess}
    </Modal>
  )
}

export default ModalReviewReport
