import React, { useState } from 'react'
import { IonButton, IonIcon, IonItem, IonInput } from '@ionic/react'

import Toaster from 'components/basepaws/utils/Toaster'
import { mailSharp } from 'ionicons/icons'
import styles from './PatientListItem.module.scss'
import { servicesNew } from 'util/services'

const MissingEmailWidget = (props) => {
  const { requisitionForm, pet, toastMessage, setPatient } = props
  const [completed, setCompleted] = useState(false)
  const [message, setMessage] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [email, setEmail] = useState()

  const onClickHandler = async (e) => {
    if (email) {
      // PUT /2021-08/requisitionForm/{id}
      try {
        const response = await servicesNew.putRequisitionForm(requisitionForm.id, {
          ...requisitionForm,
          pet: {
            ...pet,
            owner: {
              ...pet.owner,
              email: email
            }
          }
        })

        const p = pet
        p.owner = response.pet.owner
        setPatient(p)

        setMessage(toastMessage?.success)
      } catch (error) {
        console.log(error)
        setMessage(toastMessage?.error)
      }
      setShowToast(toastMessage)
    }
  }

  return (
    <>
      <IonItem
        className="item-no-padding form"
        lines="none"
      >
        <IonInput
          className={`${completed ? styles.input_has_success_button : 'has-error'} m-0`}
          name="owner_email"
          onInput={(e) => {
            setEmail(e.target.value)
            setCompleted(e.target.validity.valid)
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && event.target.validity.valid) {
              onClickHandler()
            }
          }}
          placeholder='Owner’s Email'
          required
          type="email"
          value={email}
        >
          <IonIcon
            className='ml-3'
            src={mailSharp}
          />
        </IonInput>
      </IonItem>
      <IonButton
        className='w-100 mt-3 mb-3'
        color='primary'
        disabled={!completed}
        fill='solid'
        onClick={onClickHandler}
        shape='round'
      >
        Save Email
      </IonButton>
      {toastMessage &&
      <Toaster
        header={message}
        onDidDismiss={() => {
          setShowToast(false)
        }}
        status={showToast}
      />}
    </>
  )
}

export default MissingEmailWidget
