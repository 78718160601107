import React from 'react'
import { createAnimation, IonButton, IonIcon, IonModal, IonHeader, IonTitle, IonImg, IonContent, IonAvatar } from '@ionic/react'
import { closeOutline } from 'ionicons/icons'

import styles from './Modal.module.scss'

export const Modal = (props) => {
  const { modalState, close, headingText, image, children } = props

  const enterAnimation = (baseEl) => {
    // Flor should improve this!
    const backdropAnimation = createAnimation()
      .addElement(baseEl.querySelector('ion-backdrop'))
      .fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

    const wrapperAnimation = createAnimation()
      .addElement(baseEl.querySelector('.modal-wrapper'))
      .keyframes([
        { offset: 0, opacity: '1', transform: 'translateX(100%)' },
        { offset: 1, opacity: '1', transform: 'translateX(0%)' }
      ])

    return createAnimation()
      .addElement(baseEl)
      .easing('ease-out')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation])
  }

  const leaveAnimation = (baseEl) => {
    return enterAnimation(baseEl).direction('reverse')
  }

  return (
    <IonModal class={`${styles.modal_right} modal-right`}
      enterAnimation={enterAnimation}
      isOpen={modalState}
      leaveAnimation={leaveAnimation}
      onDidDismiss={close}>
      <IonHeader>
        <div className="d-flex ion-justify-content-between">
          <IonButton
            className={`${styles.modal_close_button} default_button icon-start`}
            color="dark"
            fill="clean"
            mode="md"
            onClick={close}
            shape="round"
          >
            <IonIcon color="dark"
              icon={closeOutline}/>
            Close
          </IonButton>
          {image &&
            <IonAvatar className="ion-margin-top ion-margin-end">
              <IonImg alt="Cat avatar image"
                src={image} />
            </IonAvatar>
          }
        </div>
        <IonTitle class="Heading_2 text_primary">
          {headingText}
        </IonTitle>
      </IonHeader>
      <IonContent>
        <div className={`${styles.modal_content} modal-content`}>
          {children}
        </div>
      </IonContent>
    </IonModal>
  )
}
