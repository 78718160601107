import React, { useEffect, useState } from 'react'
import { IonRow, IonCol, IonIcon, useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import GreyBanner from 'components/basepaws/vetPortal/GreyBanner'
import { lockClosed, mailSharp } from 'ionicons/icons'
import services from 'util/services'
import content from 'util/content'

import styles from './AccountInformation.module.scss'

const AccountInformation = () => {
  const history = useHistory()
  const user = useSelector(state => state.userInReducer.user)
  const [organization, setOrganization] = useState()

  const load = async () => {
    const userBE = await services.user()
    if (!userBE) {
      history.push('/login')
    }
  }

  useIonViewDidEnter(() => {
    document.title = 'Account Information - Vet Portal - Basepaws'
  })

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
  })

  useEffect(() => {
    if (user && user.lastOrganization) {
      setOrganization(user.lastOrganization)
    }
  }, [user])

  const showFieldRow = (field, value) => {
    return (
      <IonRow>
        <IonCol size='12'
                size-lg='6'>
            <p className={`${styles.text_md_right} Body_bold text_primary mb-0 mr-3`}>
              {field}
            </p>
        </IonCol>
        <IonCol size='12'
                size-lg='6'>
            <p className={`${styles.m_sm_0} Body_regular text_dark`}>
              {value}
            </p>
        </IonCol>
      </IonRow>
    )
  }

  const accountActions = (
    <IonCol size='12'
            size-lg='4'
    >
      <h4 className={`Running_head text_primary mb-4 ${styles.mt_sm_4}`}>
        Account Actions
      </h4>
      <div className={styles.banner_actions}>
        <div className='d-flex ion-align-items-center'>
          <span className={styles.icon_wrapper}>
            <IonIcon icon={lockClosed}/>
          </span>
          <div>
            <p className='Body_bold text_primary m-0'>
                {content.get('profile.action_block.title1')}
            </p>
            <a
              className='underline Body_small_bold text_accent'
              href='/profile/ChangePassword'
            >
              {content.get('profile.action_block.cta1.title')}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.banner_actions}
           style={{ marginTop: '20px' }}>
        <div className='d-flex ion-align-items-center'>
          <span className={styles.icon_wrapper}>
            <IonIcon icon={mailSharp}/>
          </span>
          <div>
            <p className='Body_bold text_primary m-0'>
                {content.get('profile.action_block.cta3.title_bold')}
            </p>
            <a
              className='underline Body_small_bold text_accent'
              href='/notification'
            >
              {content.get('profile.action_block.cta3.title')}
            </a>
          </div>
        </div>
      </div>
    </IonCol>
  )

  const organizationInfo = (
    <IonCol size='12'
            size-lg='8'
    >
      {showFieldRow('Hospital Name', organization?.company)}
      {showFieldRow('Primary Contact Name', `${organization?.first_name} ${organization?.last_name}`)}
      {showFieldRow('Hospital Phone', organization?.phone)}
      {showFieldRow('Hospital or Primary Email Address', organization?.email)}
      {showFieldRow('Hospital Physical Address', organization?.address)}
      {showFieldRow('Hospital Website', organization?.hospital_website)}
      {showFieldRow('Number of Full Time Veterinarians', organization?.full_time_veterinarians)}
      {showFieldRow('AAHA Member', organization?.aaha_accredited_hospital ? 'Yes' : 'No')}
      {showFieldRow('Corporate Affiliation', organization?.corporate_or_group_affiliation)}
      {showFieldRow('Cat Friendly Practice', organization?.aafp_cat_friendly_practice ? 'Yes' : 'No')}
      {showFieldRow('Fear Free', organization?.fear_free_certified_practice ? 'Yes' : 'No')}
      {showFieldRow('Distributor Preference', organization?.distributor_preference)}
      {showFieldRow('Practice Type', organization?.practice_type)}
    </IonCol>
  )

  return (
    <GeneralPage
      headerStyle='left'
      title="Account Information"
      user={user}
    >
      <IonRow>
        {organizationInfo}
        {accountActions}
      </IonRow>
      <IonRow>
        <IonCol size='12'>
          <GreyBanner
            buttonHref={content.get('VET_RESOURCES.BANNER.EMAIL')}
            buttonText="Contact Basepaws Support"
            message="Please contact us with any details you want to update"
            title="Do you need to edit your account information?"
          />
        </IonCol>
      </IonRow>
    </GeneralPage>
  )
}

export default AccountInformation
