import React, { useState } from 'react'
import { IonIcon, IonInput } from '@ionic/react'
import { Button, Message } from 'components/basepaws/form'
import { mailSharp } from 'ionicons/icons'
import content from 'util/content'
import isEmailAddressValid from 'util/isEmailAddressValid'

import styles from './EditableRequisitionEmail.module.scss'

interface IFeedbackParams{
  submitted: boolean;
  submitSuccess: boolean;
  className?: string
}

function Feedback ({ submitted, submitSuccess, className }:IFeedbackParams) {
  let feedback = <Message
                    className={className}
                    frame={false}
                    info
                    pink
                  >
                    {content.get('requisition_forms_log.editable_email.message')}
                  </Message>

  if (submitted) {
    if (submitSuccess) {
      feedback = <Message
                  className={className}
                  frame={false}
                  green
                  info
                >
                  {content.get('requisition_forms_log.editable_email.success_message') ?? 'The email address was saved'}
                </Message>
    } else {
      feedback = <Message
      frame={false}
      info
      red>{content.get('requisition_forms_log.editable_email.error_message') ?? 'Something happened. Please try again later'}
    </Message>
    }
  }

  return feedback
}

interface IEditableRequisitionEmailParams{
  onSubmitCallback: (email:string) => void;
}

export default function EditableRequisitionEmail ({ onSubmitCallback }: IEditableRequisitionEmailParams) {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const isEmailValid = isEmailAddressValid(email)

  return (
    <>
      <IonInput
        className={`${email.length > 0 && !isEmailValid ? styles.invalidEmail : ''} 'mb-2'`}
        onIonChange={(event) => {
          const target = event.target as HTMLInputElement // necessary because IonInput event type is EventTarget
          setEmail(target.value)
        }}
      >
       {<IonIcon
          className="ion-padding-start"
          color="dark"
          icon={mailSharp}
          slot="start"
        />}
      </IonInput>
      <Button
        className={styles.submitButton}
        disabled={!isEmailValid}
        expand="block"
        frame={false}
        onClick={() => {
          setSubmitted(true)
          try {
            onSubmitCallback(email)
            setSubmitSuccess(true)
          } catch (error) {
            console.log('[EditableRequisitionEmail]', { error })
          }
        }}
      >
        {content.get('requisition_forms_log.editable_email.button')}
      </Button>
      <Feedback
        className = {styles.feedback}
        submitSuccess={submitSuccess}
        submitted = {submitted}
      />
  </>
  )
}
