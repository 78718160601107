import React, { useEffect, useState } from 'react'
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react'
import { chevronBackSharp, chevronForwardSharp } from 'ionicons/icons'

const Pagination = (props) => {
  const { setCurrentSlice = [], itemsToPaginate = [], reportsPerPage, pageNeighbours = 2 } = props

  const [currentPage, setCurrentPage] = useState(1)
  const totalItems = itemsToPaginate ? itemsToPaginate.length : 0

  const [totalPages, setTotalPages] = useState(Math.ceil(totalItems / reportsPerPage))

  useEffect(() => {
    const window = itemsToPaginate ? itemsToPaginate.slice((currentPage - 1) * reportsPerPage, currentPage * reportsPerPage) : []
    setCurrentSlice([...window])
  }, [currentPage])

  useEffect(() => {
    setCurrentPage(1)
    setTotalPages(Math.ceil(totalItems / reportsPerPage))
    const window = itemsToPaginate ? itemsToPaginate.slice((currentPage - 1) * reportsPerPage, currentPage * reportsPerPage) : []
    setCurrentSlice([...window])
  }, [reportsPerPage, totalItems, itemsToPaginate])

  let pagesToShow = Array(totalPages).fill().map((_, idx) => idx)
  if (totalPages > (pageNeighbours * 2 + 1)) {
    let start = 0
    let end = pageNeighbours * 2 + 1

    if ((currentPage > pageNeighbours + 1) && (currentPage < totalPages - pageNeighbours)) {
      start = currentPage - pageNeighbours - 1
      end = currentPage + pageNeighbours
    }

    if (currentPage <= pageNeighbours + 1) {
      start = 0
      end = pageNeighbours * 2 + 1
    }

    if (currentPage >= totalPages - pageNeighbours) {
      end = totalPages
      start = totalPages - (pageNeighbours * 2 + 1)
    }

    pagesToShow = pagesToShow.slice(start, end)
  }

  const nextPageButton = (
    <IonButton
      className='default_button'
      color='primary'
      fill='clear'
      mode='md'
      onClick={() => {
        setCurrentPage(currentPage + 1)
      }}>
      Next
      <IonIcon
        icon={chevronForwardSharp}
        slot="end"
      />
    </IonButton>
  )

  const lastPageButton = (
    <IonButton
      className='default_button'
      color='primary'
      fill='clear'
      mode='md'
      onClick={() => {
        setCurrentPage(totalPages)
      }}>
      <IonIcon
        icon='assets/icon/chevron-forward-sharp-double.svg'
        slot="end"
      />
    </IonButton>
  )

  const prevPageButton = (
    <IonButton
      className='default_button'
      color='primary'
      fill='clear'
      mode='md'
      onClick={() => {
        setCurrentPage(currentPage - 1)
      }}>
      Prev
      <IonIcon
        icon={chevronBackSharp}
        slot="start"
      />
    </IonButton>
  )

  const firstPageButton = (
    <IonButton
      className='default_button'
      color='primary'
      fill='clear'
      mode='md'
      onClick={() => {
        setCurrentPage(1)
      }}>
      <IonIcon
        icon='assets/icon/chevron-back-sharp-double.svg'
        slot="end"
      />
    </IonButton>
  )

  return (
    <IonRow>
      <IonCol size='12'>
        <div className="d-flex ion-align-items-center">

          {/* FirstPage */}
          {((totalPages > 1) && currentPage !== 1) ? firstPageButton : ''}

          {/* Prev Page */}
          {((totalPages > 1) && currentPage !== 1) ? prevPageButton : ''}

          {/* All pages numbers */}
          {(totalPages > 1)
            ? <ul className="pagination_numbers">
              {pagesToShow.map((i) => {
                return <li
                  className={`cursor_pointer Body_bold  ${(currentPage === i + 1) ? 'active' : ''}`}
                  key={`pagination-key-map-${i + 1}`}
                  onClick={() => setCurrentPage(i + 1)}
                >
                  {i + 1}
                </li>
              })
              }
            </ul>
            : ''}

          {/* Next Page */}
          {((totalPages > 1) && (currentPage < totalPages)) ? nextPageButton : ''}

          {/* Last Page */}
          {((totalPages > 1) && (currentPage < totalPages)) ? lastPageButton : ''}

        </div>
      </IonCol>
    </IonRow>
  )
}

export default Pagination
