import React from 'react'
import content from 'util/content'
import EmptyState from 'components/basepaws/general/EmptyState'

const VetPatientsEmptyState = (props) => {
  const { patientsLength } = props

  let text = ''
  let title = ''

  if (!patientsLength || patientsLength === 0) {
    text = content.get('dashboard.my_patients_empty.text')
    title = content.get('dashboard.my_patients_empty.title')
  } else {
    text = content.get('dashboard.my_patients_search_empty.text')
    title = content.get('dashboard.my_patients_search_empty.title')
  }

  return (
    <div>
      <EmptyState
        asset='assets/icon/data/pet_sad_purple.svg'
        assetType='image'
        content={null}
        text={text}
        title={title}
      />
    </div>
  )
}

export default VetPatientsEmptyState
