import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { idCardSharp, enterSharp } from 'ionicons/icons'

import content from 'util/content'

import styles from './NavbarWorkspaces.module.scss'

const OrganizationLinks = (props) => {
  const { onLogout, closePopover } = props

  return (
    <>
      <div className={`${styles.content} ${styles.nav_links}`}>
        <IonButton
          className='default_button no-padding'
          color='tertiary'
          fill='clear'
          mode='md'
          onClick={() => closePopover()}
          routerLink='/account'
        >
          <IonIcon
            className='mr-2'
            color='tertiary'
            src={idCardSharp}
          />
          {content.get('ORGANIZATION_LINKS.VIEW_PROFILE')}
        </IonButton>
      </div>
      <hr />
      <div className={`${styles.content} ${styles.nav_links}`}>
        <IonButton
          className={`${styles.logout} default_button no-padding`}
          color='medium'
          fill='clear'
          mode='md'
          onClick={async () => {
            closePopover()
            onLogout()
          }}
        >
          <IonIcon
            className='mr-2'
            color='medium'
            src={enterSharp}
          />
          {content.get('ORGANIZATION_LINKS.LOGOUT')}
        </IonButton>
      </div>
    </>
  )
}

export default OrganizationLinks
