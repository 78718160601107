import React, { useContext } from 'react'
import { IonIcon } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import { FormContext } from '../Form/FormContext'
import styles_ from './Error.module.scss'

export const Error = (props) => {
  const context = useContext(FormContext)

  const { children, styles = context.styles } = props

  return (
    <>
      <div className={`${styles_.input_error_message}`}>
        <div className="d-flex no-wrap ion-align-items-start">
          <IonIcon className='mr-1'
                   color="danger"
                   icon={closeCircle}
                   slot="start" />
          <p className='Body_small_regular text_dark m-0'>{children}</p>
        </div>
      </div>
    </>
  )
}
