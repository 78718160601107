import React, { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { FormContext } from 'components/basepaws/form/Form/FormContext'
import { useField } from 'formik'
import { copySharp, checkmarkSharp } from 'ionicons/icons'
import { useContext, useState } from 'react'

export const RequisitionHeader = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, url, name, handle } = props

  const [copied, setCopied] = useState(null)

  const copyTextToClipboard = (textToCopy, whichButton) => {
    navigator.clipboard.writeText(textToCopy)
    props.onCopy && props.onCopy()
    setTimeout(() => {
      setCopied(null)
    }, 2000)
    setCopied(whichButton)
  }

  const baseUrl = window.location.origin
  return (
      <div className={styles.requisition_form_header}
           data-qa={name}>
        <IonGrid fixed>
          <IonRow className="ion-justify-content-between">
            <IonCol size='12'
                    size-md='auto'>
              <div className='d-flex'>
                <div className={`${styles.tag} Body_regular text_light`}>
                  {handle}
                </div>
                <IonButton
                  className={`default_button icon-start ${styles.button_min_width1}  ${copied === 'copyID' ? 'done_button left' : styles.button_light_blue}`}
                  fill="solid"
                  mode="md"
                  onClick={() => copyTextToClipboard(handle, 'copyID')}
                  shape='round'
                >
                  <IonIcon src={copied === 'copyID' ? checkmarkSharp : copySharp}/>
                    {copied === 'copyID' ? 'Copied' : 'Copy ID'}
                </IonButton>
              </div>
            </IonCol>
            <IonCol size='12'
                    size-md='auto'>
              <div className='d-flex'>
                <div className={`${styles.tag} Body_regular text_light`}>
                  {`${baseUrl}${url}`}
                </div>
                <IonButton
                  className={`default_button icon-start ${styles.button_min_width2} ${copied === 'copyURL' ? 'done_button left' : styles.button_light_blue}`}
                  fill="solid"
                  mode="md"
                  onClick={() => copyTextToClipboard(`${baseUrl}${url}`, 'copyURL')}
                  shape='round'
                >
                  <IonIcon src={copied === 'copyURL' ? checkmarkSharp : copySharp}/>
                  {copied === 'copyURL' ? 'Copied' : 'Copy URL'}
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>)
}
