import React, { useState, useEffect } from 'react'
import { IonButton, IonCol, IonGrid, IonIcon, IonRow, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react'
import NewForm from 'components/basepaws/form/Form/Form'
import { Button, Password, Radio, Message, DateField, Note, UserFind, AnimalFind, CheckList, Age, Error, AutoSave, Text, Boolean } from 'components/basepaws/form'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import { useSelector } from 'react-redux'
import styles from './RequisitionFormPage.module.scss'
import services, { servicesNew } from 'util/services'
import testTypes from './testTypes'
import { RequisitionHeader } from './RequisitionHeader'
import deseases from './deceases'
import Toaster from 'components/basepaws/utils/Toaster'
import { object, string } from 'yup'
import { useHistory } from 'react-router'
import { alertCircleSharp, eyeOffSharp, eyeSharp, mailSharp } from 'ionicons/icons'
import content from 'util/content'
import { Row } from 'components/basepaws/form/Form/Row'

const NewRequisitionFormPage = (params) => {
  const user = useSelector(state => state.userInReducer.user)
  const history = useHistory()
  const clinicIcon = '/assets/icon/data/clinic.svg'
  const [data, setData] = useState({})
  const [replacement, setReplacement] = useState({})
  const [toast, setToast] = useState()
  const [summary, setSummary] = useState(false)
  const [kitError, setKitError] = useState()
  const [urlHandle, setUrlHandle] = useState()
  const [submittable, setSubmittable] = useState(false)

  const load = async () => {
    console.log('load')
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
  })

  useIonViewDidEnter(async () => {
    if (!user) return
    const id = params.match?.params?.handle
    let req = { editable: true }
    if (id) {
      req = (await servicesNew.get(`/requisitionForm?handle=${id}`))[0]
      setReplacement({ ...req })
      req.kit.code_repeat = req.kit.code
      if (req.submit) req.review = true
    }
    console.log('loaded', req)
    req.replace = { ...req }
    req.date = req.date || new Date()
    req.submit = null
    req.review = null
    req.handle = null
    req.kit = {}
    setData({ ...req, no_vet: false, customer_collect_sample: false })
  }, [params.match.params.handle])

  const loadVets = async (value) => {
    if (!value) return null
    value = value.toLowerCase()
    const list = (await servicesNew.getOrganizationVets(user.organization?.id))
      .filter(item => item.first_name.toLowerCase().match(value) || item.last_name.toLowerCase().match(value))
      .map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}   ${item.email}`,
        ...item
      })
      )

    return list
  }

  const loadOwners = async (value) => {
    if (!value) return null
    value = value.toLowerCase()
    const list = (await servicesNew.getOrganizationOwners(user.organization?.id))
      .filter(item => item?.first_name?.toLowerCase().match(value) || item?.last_name?.toLowerCase().match(value))
      .map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}   ${item.email}`,
        ...item
      })
      )

    return list
  }
  const loadAnimals = async (value) => {
    if (!value) return null
    value = value.toLowerCase()
    const list = (await servicesNew.getOrganizationPatients(user.organization?.id))
      .filter(item => item.name.toLowerCase().match(value))
      .map(item => ({
        value: item.id,
        label: `${item.name} · ${item.owner.first_name} ${item.owner.last_name}  ${item.owner.email}`,
        ...item
      })
      )

    return list
  }

  const newRequisition = async (code, repeat) => {
    if (code !== repeat || !code || !repeat) {
      setKitError(null)
      return
    }
    try {
      const kit = await servicesNew.get(`/kit/${code}`)
      if (kit.user) {
        return setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.KIT_ALREADY_ACTIVATED') })
      }
      if (kit.user_type !== 'vet') {
        return setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.INVALID_USER_TYPE') })
      }

      const reqs = await servicesNew.get(`/requisitionForm?kit.code=${code}`)
      if (reqs.length) {
        setKitError({ message: content.get('REQUISITION_FORMS_LOG.ERROR.KIT_CREATED_SAMPLE', { sample: reqs.map(i => i.handle).join(', ') }) })
        return
      }

      const req = await servicesNew.post('/requisitionForm/', { ...data, replacement: { code: replacement.kit.code, handle: replacement.handle }, kit: { id: kit.id, code: kit.code, productName: kit.productName }, species: kit.organism, organization_id: user.organization?.id, handle: undefined, date: new Date() })
      servicesNew.clearCache('requisition')
      req.replacement = { code: replacement.kit.code, handle: replacement.handle }
      req.replace = replacement
      req.kit.code_repeat = req.kit.code
      req.species = kit.organism
      req.review = false

      setKitError(null)
      setToast(content.get('REQUISITION_FORMS_LOG.TOAST.SAVED'))
      setData(req)
    } catch (e) {
      console.log(e)
      if ('error' in e) {
        setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.INVALID_BARCODE') })
      } else {
        setKitError({ message: content.get('REQUISITION_FORM_NEW.ERROR.INTERNAL_ERROR'), icon: alertCircleSharp })
      }
    }
  }

  const save = async (values) => {
    console.log('save', values)
    if (!values.id) await newRequisition(values.kit?.code, values.kit?.code_repeat)
    if (!values.id) return
    const req = await servicesNew.put(`/requisitionForm/${values.id}`, values)
    if (req.handle && req.url) setUrlHandle({ handle: req.handle, url: req.url })
    servicesNew.clearCache('requisition')
    try {
      await validate.validate(values)
      setSubmittable(true)
    } catch (e) {
      setSubmittable(false)
    }
  }

  const onSubmit = async (values) => {
    console.log('save', values)
    if (!values.id) return
    await servicesNew.put(`/requisitionForm/${values.id}/submit`)
    servicesNew.clearCache()
    history.push({
      pathname: '/requisition/success',
      state: {
        handle: urlHandle.handle,
        id: values.id,
        url: urlHandle.url
      }
    })
  }

  const validate = object().shape({
    species: string().required()
  })

  const editableMail =
  <div>
    <Text
      editable
      frame={false}
      icon={mailSharp}
      name='pet.owner.email'>
    </Text>
    <Button
      expand="block"
      frame={false}>{content.get('requisition_forms_log.editable_email.button')}</Button>
    <Message
      frame={false}
      info
      pink>{content.get('requisition_forms_log.editable_email.message')}
    </Message>
  </div>

  useEffect(() => {
    // This is executed when loading the RF page directly
    // without coming from RF Logs page
    if (user.organization) {
      const req = { ...data }
      req.accountId = user.organization.id
      req.clinic = {
        first_name: user.organization.company,
        last_name: '',
        email: user.organization.email
      }
      setData(req)
    }
  }, [data.review])

  return (
  <GeneralPage
    actionStyle='left'
    actions='Back'
    headerStyle='left'
    isNotFixed={true}
    pageClass="page-no-margin"
    title={content.get('requisition_forms_log.replacement_form.title')}
    user={user}
  >
    <NewForm
      editable={!data.review}
      initialValues={data}
      onSubmit={save}
      styles={styles}
    >
    {({ values, errors, isValid, setFieldValue }) => (
      <div className={values.review && 'review'}>
      {<IonGrid
          className={`${styles.new_form_styles} form`}
          fixed>
        <Toaster
          header={content.get('requisition_forms_log.newform.toast')}
          message={toast}
          onDidDismiss={() => setToast(null)}
          status={toast != null}
        />

<Row bold
     label="Failed Sample Requisition Form">
          <IonButton className={'default_button icon-start'}
                     color="primary"
                     fill="clear"
                     mode="md"
                     onClick={() => setSummary(!summary)}
              >
                <IonIcon
                color='primary'
                icon={summary ? eyeOffSharp : eyeSharp}
                ></IonIcon> {summary ? 'Hide Summary' : 'Show Summary'}
            </IonButton>
      </Row>

        {summary && <>
        <Password
          bold
          disabled
          editable={false}
          label={content.get('requisition_forms_log.newform.barcode_label')}
          name='replace.kit.code'
          placeholder={content.get('requisition_forms_log.newform.barcode_placeholder')} />
        <Radio bold
               disabled
               editable={false}
               label={content.get('requisition_forms_log.newform.test_based_on')}
               list={testTypes}
               name='replace.kit.productName'>
          <Message accent
                   info
                   styles={styles}>If you have any questions, please contact us at
            <a className='Body_small_bold'
               href='mailto:atmeow@basepaws.com'> meow@basepaws.com</a>.
          </Message>
        </Radio>
        <Boolean
          editable={false}
          label= {<p className='Body_bold'>Customer Collected Sample</p>}
          name='replace.customer_collect_sample'
          />
        <DateField
          bold
          disabled={values.customer_collect_sample}
          editable={false}
          label={content.get('requisition_forms_log.newform.collection_date_label')}
          name='replace.date'
          placeholder={content.get('requisition_forms_log.newform.collection_date_placeholder')}
          required/>
        <Note
          bold
          editable={false}
          label={content.get('requisition_forms_log.newform.annotation_label')}
          name='replace.note'
          placeholder={content.get('requisition_forms_log.newform.annotation_placeholder')} />
        <Boolean
          editable={false}
          label= {<b>Veterinarian</b>}
          name='replace.no_vet'
          type="list"
          >No vet assigned</Boolean>
        <UserFind
          bold
          disabled={values.no_vet}
          editable={false}
          label={content.get('requisition_forms_log.newform.user_find_label')}
          name='replace.vet'
          required>
        </UserFind>
        <AnimalFind
          bold
          components={{ email: !data.pet?.owner?.email ? editableMail : null }}
          editable={false}
          label={content.get('requisition_forms_log.newform.patient_label')}
          name='replace.pet'
          required
        />
        <DateField editable={false}
                   label={content.get('requisition_forms_log.newform.birthdate_label')}
                   name='replace.birthday'
                   placeholder={content.get('requisition_forms_log.newform.birthdate_placeholder')}
                   required/>
        <Age editable={false}
             label={content.get('requisition_forms_log.newform.age_label')}
             name='replace.birthday' />
        <Radio disabled
               editable={false}
               label={content.get('requisition_forms_log.newform.species_label')}
               list={[{ label: 'Canine', value: 'dog' }, { label: 'Feline', value: 'cat' }]}
               name='replace.species'
               required />
        <Radio editable={false}
               label={content.get('requisition_forms_log.newform.gender')}
               list={[{ label: 'Female', value: 'female' }, { label: 'Male', value: 'male' }]}
               name='replace.gender'
               required />
        <Radio editable={false}
               label={content.get('requisition_forms_log.newform.spayed')}
               list={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
               name='replace.spayed'
               required />
        <CheckList editable={false}
                   info={values.species == null && content.get('requisition_forms_log.newform.species_diseases')}
                   label={content.get('requisition_forms_log.newform.diagnosed_diseases')}
                   list={deseases[values.species]}
                   name='replace.diagnosed_diseases'/>
        <Boolean
          customClassName={`${styles.checkbox_no_border} ${styles.checkbox_p} ${styles.align_content_start}`}
          editable={false}
          label={'Participate on research'}
          name='replace.accept_research'
        >
            <p className='Body_small_regular text_dark'>
            I accept to participate in <b className='text_accent'>Research</b> since prior medical history in recognizing genetic patterns and improving our research.
          </p>
        </Boolean>

        </>}

        <hr className='mb-4 mt-3'/>

        {values.review &&
        <>
          <Password
            bold
            disabled={true}
            editable={false}
            label={content.get('requisition_forms_log.newform.bp_account_label')}
            // label={'Basepaws Account'}
            name='accountId'
          />
          <UserFind
            bold
            disabled={true}
            editable={false}
            label={content.get('requisition_forms_log.newform.clinic_label')}
            // label={'Clinic'}
            name='clinic'
            userIcon={clinicIcon}
          />
        </>}
        <Password
          bold
          disabled={values.id}
          editable={!values.review}
          errors={kitError && (('icon' in kitError)
            ? <Message
                  icon={kitError.icon}
                  info
                  pink
                  styles={styles}>{kitError.message}</Message>
            : <Error>{kitError.message}</Error>)}
          label={content.get('requisition_forms_log.newform.barcode_label')}
          name='kit.code'
          placeholder={content.get('requisition_forms_log.newform.barcode_placeholder')}
        />

        <Radio
          bold
          disabled
          editable={!values.review}
          label={content.get('requisition_forms_log.newform.test_based_on')}
          list={testTypes}
          name='kit.productName'>
          <Message
            accent
            info
            m0
            styles={styles}>If you have any questions, please contact us at
            <a
              className='Body_small_bold'
              href='mailto:support@basepawsvet.com'> support@basepawsvet.com</a>.
          </Message>
        </Radio>

        <Boolean
          customClassName={styles.checkbox_no_border}
          editable={!values.review}
          label={<span className='Body_bold'>Customer Collected Sample</span>}
          name='customer_collect_sample'
          />

        <DateField
          bold
          disabled={values.customer_collect_sample}
          editable={!values.review}
          label={content.get('requisition_forms_log.newform.collection_date_label')}
          name='date'
          placeholder={content.get('requisition_forms_log.newform.collection_date_placeholder')}
          required/>

        <Note
          bold
          clarification='(if needed)'
          editable={!values.review}
          label={content.get('requisition_forms_log.newform.annotation_label')}
          name='note'
          placeholder={content.get('requisition_forms_log.newform.annotation_placeholder')}
          sublabel='200 characters limit' />

        <>
        {!values.review &&
        <Boolean
          bold
          className={'d-inline-flex'}
          customClassName={`${styles.col_pb_0}`}
          editable={!values.review}
          label={content.get('requisition_forms_log.newform.user_find_label')}
          name='no_vet'
          >No vet assigned</Boolean>
        }
        <UserFind
          bold
          disabled={values.no_vet}
          editable={!values.review}
          label={values.review && content.get('requisition_forms_log.newform.user_find_label')}
          name='vet'
          options={loadVets}
          placeholder={content.get('requisition_forms_log.newform.user_find_placeholder')}
          required={!values.no_vet}
        >
          {/* <Message accentSecondary
            className={!values.vet?.email ? styles.data_disabled : ''}
            info
            >{content.get('requisition_forms_log.newform.user_find_message')}
          </Message> */}
        </UserFind>
        </>

        {values.review && <>
        <AnimalFind
          bold
          components={{ email: values.submit && !data.pet?.owner?.email ? editableMail : null }}
          editable={!values.review}
          label={content.get('requisition_forms_log.newform.patient_label')}
          name='pet'
          onChange={animal => {
            setFieldValue('birthday', animal.born_on)
            setFieldValue('gender', animal.gender)
            setFieldValue('spayed', animal.spayed)
          }}
          options={{ animal: loadAnimals, owner: loadOwners }}
          placeholder={content.get('requisition_forms_log.newform.patient_placeholder')}
          required
        />

        <div className={`${styles.patient_fields}`}>
          <DateField
            editable={!values.review && !values.pet?.id}
            label={content.get('requisition_forms_log.newform.birthdate_label')}
            multipleFirst
            name='birthday'
            placeholder={content.get('requisition_forms_log.newform.birthdate_placeholder')}
            />
          <Age
            editable={!values.review && !values.pet?.id}
            label={content.get('requisition_forms_log.newform.age_label')}
            multipleValueLabel
            name='birthday' />
          <Radio
            disabled
            editable={!values.review && !values.pet?.id}
            label={content.get('requisition_forms_log.newform.species_label')}
            list={[{ label: 'requisition_forms_log.newform.canine', value: 'dog' }, { label: 'requisition_forms_log.newform.feline', value: 'cat' }]}
            multipleValueLabel
            name='species'
            required />
          <Radio
            editable={!values.review && !values.pet?.id}
            label={content.get('requisition_forms_log.newform.gender')}
            list={[{ label: 'requisition_forms_log.newform.female', value: 'female' }, { label: 'requisition_forms_log.newform.male', value: 'male' }]}
            multipleValueLabel
            name='gender'
            required />
            {(!values.review) && <Radio
                editable={!values.review && !values.pet?.spayed}
                label={content.get('requisition_forms_log.newform.spayed')}
                list={[{ label: 'requisition_forms_log.newform.yes', value: 'yes' }, { label: 'requisition_forms_log.newform.no', value: 'no' }]}
                multipleValueLabel
                name='spayed'
              />
            }
        </div>
        <CheckList
          clarification='(if applicable)'
          editable={!values.review}
          info={values.species == null && content.get('requisition_forms_log.newform.species_diseases')}
          label={content.get('requisition_forms_log.newform.diagnosed_diseases')}
          list={deseases[values.species]}
          multipleValueLabel
          name='diagnosed_diseases'
        />

        <Boolean
          customClassName={`${styles.checkbox_no_border} ${styles.checkbox_p} ${styles.align_content_start}`}
          editable={!values.review}
          label={values.review && 'Participate on research'}
          name='accept_research'
        >
            <p className='Body_small_regular text_dark'>
            I accept to participate in <b className='text_accent'>Research</b> since prior medical history in recognizing genetic patterns and improving our research.
          </p>
        </Boolean>
        </>}

        <AutoSave onSave={save}/>

            {!values.submit && !values.review &&
            <IonRow className='ion-justify-content-end mt-4'>
              <IonCol
                size='12'
                size-md='8'>
                <Button
                  disabled={ !isValid }
                  info={!isValid && <span className={`${styles.d_sm_block} Body_small_regular text_dark`}>{content.get('requisition_forms_log.newform.invalid_form')}</span>}
                  onClick={e => {
                    // This is to use the organization fields when coming from a new/editable RF
                    setFieldValue('accountId', user.organization.id)
                    setFieldValue('clinic', {
                      first_name: user.organization.company,
                      last_name: '',
                      email: user.organization.email
                    })
                    setFieldValue('review', true)
                  }}>
                  {content.get('requisition_forms_log.newform.confirm')}
                </Button>
              </IonCol>
            </IonRow>
            }
            {(values?.pet?.owner?.first_name && !values?.pet?.owner?.email) && <>
              <Row>
                  <Message
                    icon={alertCircleSharp}
                    info
                    red
                    styles={styles}>
                      {content.get('REQUISITION_FORMS_LOG.NEWFORM.OWNER_WITHOUT_EMAIL')}
                  </Message>
              </Row>
              </>}
            {!values.submit && values.review &&
            <>
            <IonRow>
              <IonCol
                size='12'
                size-md='4'>
              </IonCol>
              <IonCol
                size='12'
                size-md='5'>
                <div className='d-flex ion-justify-content-between'>
                  <Button
                    onClick={() => onSubmit(values)}>{content.get('requisition_forms_log.newform.submit')}
                  </Button>
                  <Button
                    color='dark'
                    fill='outline'
                    onClick={e => setFieldValue('review', false)}>{content.get('requisition_forms_log.newform.edit_submit')}</Button>
                </div>
              </IonCol>
              <IonCol
                  size='12'
                  size-md='4'>
              </IonCol>
              <IonCol>
              <p className="ion-text-center Body_regular">
                {content.get('LOGIN.LEGAL')}
                <a
                  className="ml-2 Body_bold"
                  href={content.get('footer.externals.link_url2')}
                >
                  Terms of Service
                </a>
                {' and'}
                <a
                  className="ml-2 Body_bold"
                  href={content.get('footer.externals.link_url3')}
                >
                  Privacy Policy
                </a>
              </p>
              </IonCol>

            </IonRow>
            </>}

        </IonGrid>}
        </div>
    )}
  </NewForm>

</GeneralPage>

  )
}

export default NewRequisitionFormPage
