import React, { useEffect, useState } from 'react'

import { IonRow, IonCol, IonButton, IonList, IonIcon } from '@ionic/react'

import { Modal } from 'components/basepaws/general/Modal/Modal'
import { checkmarkSharp } from 'ionicons/icons'
import PetDetails from './PetDetails'
import content from 'util/content'

const ModalViewReport = (props) => {
  const { modalState, close, currentVet, patient, report } = props

  // set modal title
  const modalTitle = 'Report Details'

  const [showModalSuccess, setShowModalSuccess] = useState(false)

  useEffect(() => {
    setShowModalSuccess(false)
  }, [currentVet])

  const modalSuccess = (
    <>
      <span className='success-section-circle mt-4'>
        <IonIcon color='success'
          src={checkmarkSharp} />
      </span>
      <h1 className='Heading_3 text_primary ion-text-center'>{content.get('MODAL.RELEASE_REPORT.MODAL_SUCCESS.TITLE')}</h1>
      <p className='Lead m-0 text_grey ion-text-center px-3'>{content.get('MODAL.RELEASE_REPORT.MODAL_SUCCESS.REPORT_SEND')}</p>
      <div className='ion-text-center'>
        <div>
          <IonButton
            className='big_button mt-4'
            color='primary'
            fill='solid'
            mode='md'
            routerLink='/patients'
            shape='round'
          >
            Back to Patients
          </IonButton>
        </div>
      </div>
    </>
  )

  const modalContents =
    <>
      <IonRow className="ion-justify-content-center ion-justify-content-between">
        <IonCol size="12"
          size-md="12">
          <IonList className="form mt-3">
            <PetDetails
              patient={patient}
              report={report}
              showClientReportLink={false}
              showVetReportLink={true}
            />
          </IonList>
          {showModalSuccess && modalSuccess}
        </IonCol>
      </IonRow>
    </>

  return (
    <>
      <Modal
        {...props}
        close={close}
        headingText={showModalSuccess ? '' : modalTitle}
        modalState={modalState}
      >
        {modalContents}
      </Modal>
    </>
  )
}

export default ModalViewReport
