import React from 'react'
import { withIonLifeCycle, IonRow, IonCol, IonImg, IonButton } from '@ionic/react'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'

class PointContactRegisterThanks extends React.Component {
  render() {
    return (
      <GeneralPage header="none">
        <IonRow className="register-point-contact">
          <IonCol size="12"
            size-lg="3"
            size-md="5">
            <img alt="Basepaws logo"
              className="d-inline-block align-top"
              height="52"
              src="https://cdn.shopify.com/s/files/1/0087/3798/0475/files/logo_different_face_300x300_68daa56d-0c73-4155-8cae-b3222e508d0f_300x300.png?v=1603721507"
              width="73" />
          </IonCol>
        </IonRow>
        <IonRow className="ion-justify-content-center">
          <IonCol className="ion-text-center"
            size="10"
            size-md="8">
            <IonImg className="empty-section-image large"
              src="/assets/images/basepaws-kit-success.png" />
            <h1 className="ion-text-center">{content.get('POINT_CONTACT.THANKS.TITLE')}!</h1>
            <p className="b1 ion-text-center">{content.get('POINT_CONTACT.THANKS.MESSAGE')}</p>
            <IonButton className="default_button"
              color="primary"
              fill="solid"
              href="/"
              label="Continue with Basepaws"
              shape="round" />
          </IonCol>
        </IonRow>
      </GeneralPage>
    )
  }
}

export default (withIonLifeCycle(PointContactRegisterThanks))
