import React, { useContext, useState } from 'react'
import { IonInput } from '@ionic/react'
import { useField, useFormikContext } from 'formik'
import { FormContext } from '../Form/FormContext'
import { Row } from '../Form/Row'
import { string } from 'yup'
import { yupAdapter } from '../Form/Form'
import Error from '../Error'
import content from 'util/content'

export const Password = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, name, label, placeholder, required, bold, errors } = props
  const fcontext = useFormikContext()

  let validate = string()
  if (required) validate = validate.required('required')

  const [field, { value, touched }, { setValue }] = useField({ name: name, validate: yupAdapter(validate) })
  const [field2, { value: value2, touched: touched2 }, { setValue: setValue2 }] = useField({ name: `${name}_repeat`, validate: yupAdapter(validate) })
  const [equals, setEquals] = useState(true)
  const check = (value, value2) => {
    if ((value || '').length == 0 && (value || '').length == 0) return
    const eq = value == value2
    if (eq) props.onChange && props.onChange(value)
    setEquals(eq)
  }

  if (!editable) {
    return (
    <Row bold={bold}
         label={label}>
      <p className="Body_1 text_black my-3">{value}</p>
    </Row>)
  }

  if (editable) {
    return (
    <Row bold={bold}
         label={label}>
      <IonInput
        className={`${styles.input_w_300} mb-2 ${errors ? 'has-error' : ''}`}
        name={name}
        onIonChange={e => {
          if (!e.detail.value) return
          check(e.detail.value, value2)
          setValue(e.detail.value)
        }}
        {...field}
        {...props}
        data-qa={name}
        placeholder={`Add ${placeholder}`}
        value={value}
      />
      <IonInput
        className={`${styles.input_w_300} mb-2 ${errors ? 'has-error' : ''}`}
        name={field2.name}
        onIonChange={e => {
          if (!e.detail.value) return
          check(value, e.detail.value)
          setValue2(e.detail.value)
        }}
        {...field2}
        {...props}
        data-qa={field2.name}
        placeholder={`Repeat ${placeholder}`}
        value={value2}
    />
        {equals || <Error>{content.get('REQUISITION_FORM_NEW.ERROR.BARCODE_NO_MATCH')}</Error>}
    {errors}
    </Row>)
  }
}
