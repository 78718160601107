import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IonRow, IonCol, IonItem, IonInput, IonIcon, IonButton } from '@ionic/react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import LoaderButton from 'components/basepaws/utils/LoaderButton/index'
import Toaster from 'components/basepaws/utils/Toaster'
import { closeCircle, eye, eyeOff, checkmarkCircle, ellipseOutline } from 'ionicons/icons'
import { servicesNew } from 'util/services'
import content from 'util/content'

import styles from './ChangePasswordForm.module.scss'

const ChangePasswordForm = ({ patient }) => {
  const history = useHistory()

  const [imageSrc, setImageSrc] = useState(patient?.images?.[0]?.src)
  const [saved, setSaved] = useState(false)
  const [passwordFieldType, setPasswordFieldType] = useState({
    currentPassword: 'password',
    currentPasswordIcon: eye,
    password: 'password',
    passwordIcon: eye,
    passwordRepeat: 'password',
    passwordRepeatIcon: eye
  })
  const [password, setPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: null,
    number: null,
    size: null,
    equal: null
  })

  const registerSchema = Yup.object().shape({
    password: Yup.string().test(
      'password-valid',
      '',
      () => passwordCheck.uppercase && passwordCheck.number && passwordCheck.size && passwordCheck.equal
    )
  })

  // TODO: Find another solutions to this
  if (patient?.images?.[0]?.src !== undefined && imageSrc === undefined) {
    setImageSrc(patient?.images?.[0]?.src)
  }

  function customResetForm (setSubmitting) {
    // Formik's resetForm doesn't work 'cause this form is a controlled one.
    setPassword('')
    setCurrentPassword('')
    setPasswordRepeat('')
    setPasswordFieldType({
      currentPassword: 'password',
      currentPasswordIcon: eye,
      password: 'password',
      passwordIcon: eye,
      passwordRepeat: 'password',
      passwordRepeatIcon: eye
    })
    setPasswordCheck({
      uppercase: null,
      number: null,
      size: null,
      equal: null
    })
    setSubmitting(false)
  }

  const onSubmit = async (values, setSubmitting, setFieldError) => {
    setSubmitting(true)
    try {
      await servicesNew.post('/auth/changePassword',
        {
          currentPassword: currentPassword,
          password: password,
          password_repeat: passwordRepeat
        }
      ).then(async () => {
        setSaved(true)
        await servicesNew.updateUser(true)
        customResetForm(setSubmitting)
        history.push('/patients')
      })
      .catch(e => {
        if(e.errors.length > 0 && e.errors.includes('invalid password')) {
          setFieldError('currentPassword', content.get('CHANGEPASSWORD.FORM.ERROR.INCORRECT'))
        }
        setSubmitting(false)
      })
    } catch (e) {
      console.log(e)
    }
  }

  const onChangePassword = (e) => {
    const passwordLocal = e.target.value || ''

    const check = passwordCheck
    check.uppercase = /[A-Z]/.test(passwordLocal)
    check.number = /[0-9]/.test(passwordLocal)
    check.size = passwordLocal.length >= 6
    check.equal = (passwordLocal === passwordRepeat)

    setPassword(passwordLocal)
    setPasswordCheck(check)
  }

  const onChangePasswordRepeat = (e) => {
    const passwordLocal = e.target.value || ''

    const passwordEqual = (passwordLocal === password)
    setPasswordRepeat(passwordLocal)
    setPasswordCheck({
      ...passwordCheck,
      equal: passwordEqual
    })
  }

  const changeType = (field) => {
    const currentType = passwordFieldType[field]
    let nextType = 'password'
    let icon = eye
    if (currentType === 'password') {
      nextType = 'text'
      icon = eyeOff
    }
    setPasswordFieldType({
      ...passwordFieldType,
      [field]: nextType,
      [`${field}Icon`]: icon
    })
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        currentPassword: '',
        password: '',
        passwordRepeat: ''
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        onSubmit(values, setSubmitting, setFieldError)
      }}
      validationSchema={registerSchema}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Toaster
            header={content.get('CHANGEPASSWORD.SUCCESS.TITLE')}
            message={content.get('CHANGEPASSWORD.SUCCESS.MESSAGE')}
            onDidDismiss={() => {
              setSaved(false)
            }}
            status={saved}
          />
          <div className="form ion-margin-top">
            <IonRow className={`${styles.mb_md_5}`}>
              <IonCol size='12'
                      size-md='5'>
                <h4 className={`${styles.text_md_right} Body_bold text_primary mr-3`}>{content.get('CHANGEPASSWORD.FORM.TITLE1')}</h4>
              </IonCol>
              <IonCol size='12'
                      size-md='7'>
                <IonInput
                  className={`input-w-icon ${errors.currentPassword ? 'has-error' : null}`}
                  clearOnEdit={false}
                  name="currentPassword"
                  onIonChange={(e) => setCurrentPassword(e.target.value)}
                  type={passwordFieldType.currentPassword}
                  value={currentPassword}
                >
                  <IonButton fill="clear"
                             mode="md"
                             onClick={() => changeType('currentPassword')}
                             slot="end"
                             tabindex="-1"
                  >
                    <IonIcon
                      color="dark"
                      icon={passwordFieldType.currentPasswordIcon}
                      slot="icon-only"
                    >
                    </IonIcon>
                  </IonButton>
                </IonInput>
                {errors.currentPassword &&
                  <IonItem className="ion-margin-bottom item-no-padding input-error-message absolute_position">
                    <IonRow>
                      <IonCol>
                        <div className="input-error-label Body_small_regular text_dark">
                          <IonIcon
                            color="danger"
                            icon={closeCircle}
                            slot="start"
                          />
                          {errors.currentPassword}
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                }
              </IonCol>
            </IonRow>
            <IonRow className={`${styles.mb_md_5}`}>
              <IonCol size='12'
                      size-md='5'>
                <h4 className={`${styles.text_md_right} Body_bold text_primary mr-3`}>{content.get('CHANGEPASSWORD.FORM.TITLE2')}</h4>
              </IonCol>
              <IonCol size='12'
                      size-md='7'>
                <IonInput
                  className={`input-w-icon ${passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                  clearOnEdit={false}
                  name="password"
                  onIonInput={onChangePassword}
                  type={passwordFieldType.password}
                  value={password}
                >
                  <IonButton fill="clear"
                             mode="md"
                             onClick={() => changeType('password')}
                             slot="end"
                             tabindex="-1"
                  >
                    <IonIcon
                      color="dark"
                      icon={passwordFieldType.passwordIcon}
                      slot="icon-only"
                    ></IonIcon>
                  </IonButton>
                </IonInput>
                <IonRow className="w-100">
                  <IonCol size="auto">
                    <IonButton
                      className="button-form-check Body_small_regular text_dark"
                      disabled
                      fill="clear"
                      mode="md"
                      size="small"
                    >
                      {passwordCheck.uppercase === null &&
                        <IonIcon
                          icon={ellipseOutline}
                          slot="start"
                        />}
                      {passwordCheck.uppercase === true &&
                        <IonIcon color="success"
                                 icon={checkmarkCircle}
                                 slot="start"
                        />}
                      {passwordCheck.uppercase === false &&
                        <IonIcon color="danger"
                                 icon={closeCircle}
                                 slot="start"
                        />}
                      {content.get('CHANGEPASSWORD.FORM.PASSWORD_STRENGHT1')}
                    </IonButton>
                  </IonCol>
                  <IonCol size="auto">
                    <IonButton className="button-form-check ml-2 Body_small_regular text_dark"
                               disabled
                               fill="clear"
                               mode="md"
                               size="small">
                      {passwordCheck.number === null &&
                        <IonIcon
                          icon={ellipseOutline}
                          slot="start"
                        />}
                      {passwordCheck.number === true &&
                        <IonIcon
                          color="success"
                          icon={checkmarkCircle}
                          slot="start"
                        />}
                      {passwordCheck.number === false &&
                        <IonIcon
                          color="danger"
                          icon={closeCircle}
                          slot="start"
                        />}
                      {content.get('CHANGEPASSWORD.FORM.PASSWORD_STRENGHT2')}
                    </IonButton>
                  </IonCol>
                  <IonCol size="auto">
                    <IonButton className="button-form-check ml-2 Body_small_regular text_dark"
                               disabled
                               fill="clear"
                               mode="md"
                               size="small"
                    >
                      {passwordCheck.size === null &&
                        <IonIcon
                          icon={ellipseOutline}
                          slot="start"
                        />}
                      {passwordCheck.size === true &&
                        <IonIcon
                          color="success"
                          icon={checkmarkCircle}
                          slot="start"
                        />}
                      {passwordCheck.size === false &&
                        <IonIcon
                          color="danger"
                          icon={closeCircle}
                          slot="start"
                        />}
                      {content.get('CHANGEPASSWORD.FORM.PASSWORD_STRENGHT3')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCol>
            </IonRow>
            <IonRow className={`${styles.mb_md_5}`}>
              <IonCol size='12'
                      size-md='5'>
                <h4 className={`${styles.text_md_right} Body_bold text_primary mr-3`}>{content.get('CHANGEPASSWORD.FORM.TITLE3')}</h4>
              </IonCol>
              <IonCol size='12'
                      size-md='7'>
                <IonInput
                  className={`input-w-icon ${(passwordCheck.equal != null && passwordCheck.equal === false) || passwordCheck.number === false || passwordCheck.uppercase === false || passwordCheck.size === false ? 'has-error' : null}`}
                  clearOnEdit={false}
                  name="passwordRepeat"
                  onIonInput={onChangePasswordRepeat}
                  type={passwordFieldType.passwordRepeat}
                  value={passwordRepeat}
                >
                  <IonButton fill="clear"
                             mode="md"
                             onClick={() => changeType('passwordRepeat')}
                             slot="end"
                             tabindex="-1"
                  >
                    <IonIcon
                      color="dark"
                      icon={passwordFieldType.passwordRepeatIcon}
                      slot="icon-only"
                    >
                    </IonIcon>
                  </IonButton>
                </IonInput>
                {passwordCheck.equal != null && !passwordCheck.equal &&
                  <IonItem className={`ion-margin-bottom item-no-padding input-error-message ${styles.absolute_position}`}>
                    <IonRow>
                      <IonCol>
                        <div className="input-error-label Body_small_regular text_dark">
                          <IonIcon
                            color="danger"
                            icon={closeCircle}
                            slot="start"
                          />
                          {content.get('CHANGEPASSWORD.ERROR.NO_MATCH')}
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonItem>
                }
              </IonCol>
            </IonRow>
          </div>

          <div className={`${styles.card_footer} ion-justify-content-between`}>
            <IonButton
              className="default_button"
              color="primary"
              fill="outline"
              mode="md"
              onclick={() => { history.push('/patients') }}
              shape="round"
              type="submit"
            >
              {content.get('CHANGEPASSWORD.FORM.CTA_LEFT.TITLE')}
            </IonButton>
            <LoaderButton
              buttonText={content.get('CHANGEPASSWORD.FORM.CTA_RIGHT.TITLE')}
              className="default_button"
              color="primary"
              disabled={isSubmitting}
              isSubmitting={isSubmitting}
              type="submit"
            ></LoaderButton>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
