import React, { useContext } from 'react'
import { IonCol, IonInput, IonItem, IonRow, IonTextarea } from '@ionic/react'
import { FormContext } from '../Form/FormContext'
import { useField } from 'formik'
import { Row } from '../Form/Row'
import { Static } from '../Form/Static'

export const Note = (props) => {
  const context = useContext(FormContext)
  const { styles = context.styles, editable = context.editable, name, label = 'Add a note', placeholder, bold, sublabel, clarification } = props

  const [field, { value }, { setValue }] = useField(name)

  if (!editable) {
    return (
    <Row bold={bold}
      label={label}>
        <Static>{value || <span className="void">No {label}</span>}</Static>
    </Row>)
  }

  if (editable) {
    return (
    <Row bold={bold}
      clarification={clarification}
      label={label}
      sublabel={sublabel}>
      <IonItem className='item-no-padding'
        lines='none'>
        <IonTextarea
          name={name}
          onIonChange={e => { setValue(e.detail.value) }}
          placeholder={placeholder || `Add a ${label}`}
          value={value}
        />
      </IonItem>
    </Row>
    )
  }
}
