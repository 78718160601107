import React from 'react'
import { IonIcon } from '@ionic/react'
import styles from './HidingMessageIcon.module.scss'

const HidingMessageIcon = (props) => {
  const { children, icon, iconColor, iconStyle, messageStyle } = props
  return (
    <>
      {iconColor &&
        <IonIcon
          className={`${styles.icon} ${iconStyle} mr-1`}
          color={iconColor ?? 'info'}
          icon={icon}
        />
      }
      { children && <p className={`${styles.text} ${messageStyle} mr-3 m-0`}>
        {children}
      </p>}
    </>
  )
}

export default HidingMessageIcon
