import React from 'react'
import { IonButton } from '@ionic/react'
import { Row } from '../Form/Row'

export const Button = (props) => {
  // TODO frame is declared twice as true AND false (that is why 'var' is needed). We should analyze what is the final result and declare it only once.
  var { onClick, frame = true, label, disabled, frame = false } = props

  if (frame) {
    return (
      <Row label={label}>
        <IonButton
          className="default_button mr-3"
          color="primary"
          disabled={disabled}
          expand="full"
          fill="solid"
          onClick={e => onClick && onClick()}
          shape="round"
          {...props}
        >
          {props.children}
        </IonButton>
        {props.info}
      </Row>
    )
  }

  return (
    <>
      <IonButton
        className="default_button mr-3"
        color="primary"
        disabled={disabled}
        fill="solid"
        onClick={e => onClick && onClick()}
        shape="round"
        {...props}
      >
        {props.children}
      </IonButton>
      {props.info}
    </>
  )
}
