import React, { useEffect, useState } from 'react'
import { IonRow, IonCol, useIonViewWillEnter, useIonViewDidEnter, IonGrid, isPlatform } from '@ionic/react'
import { useHistory } from 'react-router'
import { useSelector } from 'react-redux'

import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import SearchFilterSort from 'components/basepaws/general/SearchFilterSort/SearchFilterSort'
import PatientListItem from 'components/basepaws/vetPortal/PatientListItem'
import Pagination from 'components/basepaws/utils/Pagination'
import PaginationSelector from 'components/basepaws/utils/Pagination/PaginationSelector'
import { SortByButton } from 'components/basepaws/general/SearchFilterSort'
import testTypes from 'pages/RequisitionFormPage/testTypes'
import Skeleton from 'components/basepaws/utils/Skeleton'
import { setPatients } from 'store/actions'
import services, { servicesNew } from 'util/services'
import content from 'util/content'
import { caretDownSharp, documentSharp, helpCircleSharp } from 'ionicons/icons'

import VetPatientsEmptyState from './VetPatientsEmptyState'
import { exception, processing, waiting, ready, received, registered, reviewed, released } from 'components/basepaws/vetPortal/constants'

const vetPatients = () => {
  const history = useHistory()

  const user = useSelector(state => state.userInReducer.user)
  const patients = useSelector(state => state.patients.patients)

  const [organization, setOrganization] = useState()
  const [patientsFiltered, setPatientsFiltered] = useState([])

  const [currentSlice, setCurrentSlice] = useState([])
  const [reportsPerPage, setReportsPerPage] = useState(20)

  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingUser, setLoadingUser] = useState(true)
  const [loadingPatients, setLoadingPatients] = useState(true)
  const [loadingOrganization, setLoadingOrganization] = useState(true)
  const [loadingOrderPatients, setLoadingOrderPatients] = useState(true)
  const [toggle, setToggle] = useState()

  const desktop = isPlatform('desktop')

  const fieldsToSearch = [
    'name',
    'owner.first_name',
    'owner.last_name',
    'owner.email'
  ]

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
    return user
  }

  const loadPatients = async (userOrganization = null) => {
    setLoadingPatients(true)
    if (userOrganization) {
      setOrganization(userOrganization)
      const patients = await servicesNew.getPatients(userOrganization)
      setPatients(patients)
      setPatientsFiltered(patients)
    }
    setLoadingPatients(false)
  }

  useIonViewDidEnter(() => {
    document.title = 'Patients - Vet Portal - Basepaws'
  })

  useIonViewWillEnter(async () => {
    setLoading(true)
    let userOrganization = user?.organization?.id
    let _user = null
    if (!user.id) {
      _user = await load()
    }
    if (!userOrganization) {
      userOrganization = _user?.organization?.id
    }

    await loadPatients(userOrganization)
    setLoading(false)
  })

  const cleanData = () => {
    setPatients(null)
    setPatientsFiltered(null)
    setCurrentSlice(null)
  }

  useEffect(async () => {
    setLoadingUser(true)
    cleanData()
    if (user?.lastOrganization) {
      setOrganization(user.lastOrganization)
      const patients = await servicesNew.getPatients(user.lastOrganization.id)
      setPatients(patients)
    }
    setLoadingUser(false)
  }, [user])

  useEffect(async () => {
    if (organization?.id) {
      setLoadingOrganization(true)
      await servicesNew.getOrganizationVets(organization.id)
      setLoadingOrganization(false)
    }
  }, [organization, patients])

  const reloadList = async (patientsFromRedux) => {
    setPatients([...patientsFromRedux])
    setPatientsFiltered([...patientsFromRedux])
  }

  const reportOrder = [
    'ready_to_review',
    'ready_to_release',
    'exception',
    'waiting',
    'processing',
    'registered',
    'received',
    'released'
  ]

  useEffect(() => {
    setLoadingOrderPatients(true)

    if (patients?.length > 0) {
      // TODO: This function is directly changing the patients array in redux
      // this should be updated to use dispatch function to update patients
      patients.forEach(patient => { // order reports by reportOrder
        if (patient.reports?.length > 0) {
          patient.reports = patient.reports.sort((a, b) => {
            const indexA = reportOrder.indexOf(a.status)
            const indexB = reportOrder.indexOf(b.status)
            return indexA - indexB
          })

          patient.last_updated_report = patient.reports.reduce((acc, val) => {
            if (val.last_updated === undefined) {
              return acc;
            }
        
            if (acc === undefined || acc < val.last_updated) {
              return val.last_updated;
            }
        
            return acc;
          }, patient.reports[0]?.last_updated);
        }
      })

      setPatientsFiltered([...patients])
      setTestSelected([...testSelected])
      setStatusSelected([...statusSelected])
    }
    setLoadingOrderPatients(false)
  }, [patients])

  const testOptions = testTypes
  const statusOptions = [
    {
      label: 'filters_label.status.waiting.name',
      value: waiting
    },
    {
      label: 'filters_label.status.processing.name',
      value: processing
    },
    {
      label: 'filters_label.status.registered.name',
      value: registered
    },
    {
      label: 'filters_label.status.exception.name',
      value: exception
    },
    {
      label: 'filters_label.status.received.name',
      value: received
    },
    {
      label: 'filters_label.status.reviewed.name',
      value: reviewed
    },
    {
      label: 'filters_label.status.ready.name',
      value: ready
    },
    {
      label: 'filters_label.status.ready_to_release.name',
      value: 'ready_to_release'
    },
    {
      label: 'filters_label.status.released.name',
      value: released
    }
  ]

  const [testSelected, setTestSelected] = useState([])
  const [statusSelected, setStatusSelected] = useState([])

  const compareFunctionTest = (patient, arraySelected) => {
    return patient.reports.some(report => arraySelected.some(t => report.requisition.kit.productName === t))
  }

  const compareFunctionStatus = (patient, arraySelected) => {
    return patient.reports.some(report => {
      if (report.status === exception) {
        return !report?.requisition?.kit?.replacement && arraySelected.includes(report.status);
      } 
      return arraySelected.includes(report.status);
    });
  };
  

  const amountFunctionTest = (value, patient) => {
    return patient.reports.reduce((acc, report) => {
      return acc + (report.requisition?.kit?.productName === value)
    }, 0)
  }

  const amountFunctionStatus = (value, patient) => {
    return patient.reports.reduce((acc, report) => {
      if (value === exception && report.status === exception) {
        return acc + !report?.requisition?.kit?.replacement;
      } 
      else if (report.status === value) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  useEffect(() => {
    const opt = [
      {
        icon: documentSharp,
        label: 'Test',
        functionGetAmount: amountFunctionTest,
        functionFilter: compareFunctionTest,
        options: [...testOptions],
        setValueSelected: setTestSelected,
        valuesSelected: testSelected
      },
      {
        icon: helpCircleSharp,
        label: 'Status',
        functionGetAmount: amountFunctionStatus,
        functionFilter: compareFunctionStatus,
        options: [...statusOptions],
        setValueSelected: setStatusSelected,
        valuesSelected: statusSelected
      }
    ]

    setOptions(opt)
  }, [statusSelected, testSelected])

  const searchSortFilter = (
    <IonRow className="search-sort-fullscreen">
      <SearchFilterSort
        align='between'
        enabledFeatures={{
          buttons: { options },
          filterSelector: true,
          search: true,
          sort: false,
          filter: false,
          filterByDate: false,
          fieldToDate: 'born_on',
          showDiscardButton: false,
          missingEmail: false
        }}
        fieldsToSearch={fieldsToSearch}
        options={options}
        resetFunction={() => {
          setTestSelected([])
          setStatusSelected([])
        }}
        searchIn={patients}
        searchPlaceholder={content.get('vetPatients.search.placeholder')}
        setResultFunction={setPatientsFiltered}
        setStatusSelected={setStatusSelected}
        showResetButton={testSelected.length > 0 || statusSelected.length > 0}
        sortIn={patientsFiltered}
        statusSelected={statusSelected}
      />
    </IonRow>
  )

  const patientList = (
    <>
      {(!loadingOrderPatients && (patientsFiltered && patients?.length > 0)) && <div className='d-flex ion-justify-content-end mt-4'>
        <PaginationSelector
          baseKey={'patients-pagination'}
          reportsPerPage={reportsPerPage}
          setReportsPerPage={setReportsPerPage}
        />
        <SortByButton
          icon={caretDownSharp}
          options={
            [
              {
                label: 'Report Status',
                value: 'report_status',
                value_order: '',
                groupBy: 'reports[].status',
                orderGroup: `${exception}|${ready}|${received}|${waiting}|${processing}|${registered}|${reviewed}`
              },
              {
                label: 'Last updated',
                value: 'last_update',
                value_order: 'last_updated_report',
                direction: 'desc'
              },
              {
                label: 'Oldest',
                value: 'oldest',
                value_order: 'last_updated_report',
                direction: 'asc'
              }
            ]
          }
          setResultFunction={setPatientsFiltered}
          sortIn={patientsFiltered}
        />
      </div>}
      <IonRow className="ion-justify-content-center mt-5">
        <IonCol size-md="12">
          {patientsFiltered?.length > 0 && currentSlice?.map(
            (patient) => {
              return (
                <PatientListItem
                  currentPatient={patient}
                  key={`patient-${patient.id}`}
                  opened={toggle === patient.id}
                  reloadList={reloadList}
                  setOpened={(patientID) => {
                    if (toggle === patientID) { // if the same patient is clicked, close the toggle
                      setToggle(null)
                    } else {
                      setToggle(patientID)
                    }
                  }}
                />
              )
            }
          )}
        </IonCol>
      </IonRow>

      <Pagination
        itemsToPaginate={patientsFiltered}
        pageNeighbours={desktop ? 2 : 1}
        reportsPerPage={reportsPerPage}
        setCurrentSlice={setCurrentSlice}
      />
    </>
  )

  const patientsSearchAndList = (
    <>
      {(patientsFiltered && patients?.length > 0) && searchSortFilter}

      <IonGrid fixed>
        {(!loadingOrderPatients && (!patientsFiltered || patientsFiltered.length === 0))
          ? <VetPatientsEmptyState patientsLength={patients?.length} />
          : patientList
        }
      </IonGrid>
    </>
  )

  return (
    <GeneralPage
      headerStyle='left'
      isNotFixed={true}
      pageClass="page-no-margin"
      title={content.get('vetPatients.title')}
      user={user}
    >
      {(
        loading ||
        loadingUser ||
        loadingPatients ||
        loadingOrganization ||
        loadingOrderPatients
      )
        ? <IonGrid fixed>
          <Skeleton
            animated={true}
            colsSizes={[2, 4, 4, 1, 1]}
            customStyle={{ height: '20px' }}
            rows={3}
          />
        </IonGrid>
        : patientsSearchAndList}
    </GeneralPage>
  )
}

export default vetPatients
