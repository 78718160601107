import React, { useCallback } from 'react'
import { IonCol, IonSearchbar } from '@ionic/react'
import styles from './CatFilters.module.scss'
import debounce from 'lodash.debounce'

const SearchBar = (props) => {
  const { placeholder, searchText, setSearchText } = props

  const changeHandler = (e) => setSearchText(e.detail.value)

  const debouncedChangeHandler = useCallback(
    debounce(changeHandler, 70)
    , [])

  return (
    <>
      <IonCol size="12"
        size-lg="3"
        size-md="4">
        <div className="d-flex no-wrap ion-align-items-center">
          <div className="w-100">
            <IonSearchbar
            className={styles.search_bar}
            onIonChange={debouncedChangeHandler}
            placeholder={placeholder}
            value={searchText}
            />
          </div>
          {/* <div className="loader grey"></div> */}
        </div>
      </IonCol>
    </>
  )
}

export default SearchBar
