import React, { useContext } from 'react'
import { calendarClearSharp } from 'ionicons/icons'
import Moment from 'react-moment'
import { FormContext } from '../Form/FormContext'
import { useField } from 'formik'
import { Row } from '../Form/Row'
import DateInputDesktop from './DateInputDesktop'
import { yupAdapter } from '../Form/Form'
import { date } from 'yup'
import { Static } from '../Form/Static'

export const DateField = (props) => {
  const context = useContext(FormContext)
  const { disabled, styles = context.styles, editable = context.editable, required, name, label = 'Select a Date', placeholder, bold, multipleFirst } = props

  let validate = date()
  if (required) validate = validate.required('required')
  const [field, { value }, { setValue }] = useField({ name: name, validate: yupAdapter(validate) })

  return (<>
    {/* This was commented because it has no use on FE. And now the ID is created by default  (https://reactdatepicker.com/#example-portal-by-id)
     * This also bring us problems with the tab-index.
     */}
    {/* <div id="datepicker-portal"></div> */}
    {
      !editable
        ? <Row bold={bold}
          label={label}>
          <Static
            icon={calendarClearSharp}
            multipleFirst={multipleFirst}>
            {value
              ? <Moment utc format='MM/DD/YYYY'>
                {value}
              </Moment>
              : '--'
            }
          </Static>
        </Row>
        : <Row bold={bold}
          label={label}>
          <DateInputDesktop
            className={`${styles.input_w_300} mb-2`}
            dateFormatCalendar="MMMM"
            dateOptions={{}}
            disabled={disabled}
            format="MM/dd/yyyy"
            label={placeholder}
            maxDate={new Date()}
            name={name}
            onChange={date => setValue(date)}
            placeholder={placeholder}
            // portal="#datepicker-portal"
            selectRange={false}
            selected={value ? new Date(value) : null}
            showYearDropdown
            styles={styles}
          />

        </Row>

    }
  </>)
}
