import React from 'react'
import { withIonLifeCycle, IonGrid } from '@ionic/react'

import { connect } from 'react-redux'

import SuccessWidget from 'components/basepaws/utils/SuccessWidget'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import content from 'util/content'

class AccountRecoverPasswordFormEmailConfirmation extends React.Component {
  render () {
    const user = this.props.user

    return (
      <GeneralPage
        user={user}
        isNotFixed={true}
      >
        <IonGrid fixed>
          <SuccessWidget
            heading={content.get('recoverpassword.email.success.title')}
            leftCTA={{
              url: '/login',
              text: 'Sign in',
              show: true
            }
            }
            message={content.get('recoverpassword.email.success.message')}
            rightCTA={{ show: false }}
          />
        </IonGrid>
      </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountRecoverPasswordFormEmailConfirmation))
