import React from 'react'
import { IonGrid } from '@ionic/react'
import { createSharp } from 'ionicons/icons'
import content from 'util/content'
import EmptyState from 'components/basepaws/general/EmptyState'

const RequisitionFormLogEmptyState = (props) => {
  const { requisitionsLogsLength } = props

  let text = ''
  let title = ''

  if (!requisitionsLogsLength || requisitionsLogsLength === 0) {
    text = content.get('dashboard.requisitions_logs_empty.text')
    title = content.get('dashboard.requisitions_logs_empty.title')
  } else {
    text = content.get('dashboard.requisitions_logs_search_empty.text')
    title = content.get('dashboard.requisitions_logs_search_empty.title')
  }

  return (
    <IonGrid fixed>
      <EmptyState
        asset={createSharp}
        assetType='icon'
        text= {text}
        title={title}
      />
    </IonGrid>
  )
}

export default RequisitionFormLogEmptyState
