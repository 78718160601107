import React, { useEffect, useState } from 'react'
import { IonRow, IonGrid, useIonViewWillEnter, isPlatform } from '@ionic/react'
import RequisitionFormLogCard from 'components/basepaws/vetPortal/RequisitionFormLogCard'
import GreyBanner from 'components/basepaws/vetPortal/GreyBanner'
import SearchFilterSort from 'components/basepaws/general/SearchFilterSort/SearchFilterSort'
import services, { servicesNew } from 'util/services'
import RequisitionFormLogEmptyState from './RequisitionFormLogEmptyState'
import content from 'util/content'
import { useSelector } from 'react-redux'
import { caretDownSharp, documentSharp, helpCircleSharp } from 'ionicons/icons'
import Pagination from 'components/basepaws/utils/Pagination'
import PaginationSelector from 'components/basepaws/utils/Pagination/PaginationSelector'
import { SortByButton } from 'components/basepaws/general/SearchFilterSort'
import testTypes from 'pages/RequisitionFormPage/testTypes'
import Skeleton from 'components/basepaws/utils/Skeleton'

const requisitionFormLogList = () => {
  const [requisitionsLogs, setRequisitionLogs] = useState([])
  const [requisitionsFiltered, setRequisitionsFiltered] = useState([])
  const [loading, setLoading] = useState(true)

  const user = useSelector(state => state.userInReducer.user)

  const [options, setOptions] = useState([])

  const [currentSlice, setCurrentSlice] = useState([])
  const [reportsPerPage, setReportsPerPage] = useState(20)

  const desktop = isPlatform('desktop')

  const load = async () => {
    const user = await services.user()
    if (!user) {
      history.push('/login')
    }
  }

  const loadRequisitionsForms = async () => {
    setLoading(true)
    const requisitionsLogsBE = await servicesNew.getRequisitionForms()
    setRequisitionLogs(requisitionsLogsBE)
    setRequisitionsFiltered([])
    setCurrentSlice([])
    setLoading(false)
  }

  useEffect(() => {
    setRequisitionsFiltered([...requisitionsLogs])
  }, [requisitionsLogs])

  useIonViewWillEnter(async () => {
    if (!user.id) {
      await load()
    }
    loadRequisitionsForms()
  })

  const fieldsToSearch = [
    'kit.code',
    'handle',
    'pet.name',
    'pet.owner.first_name',
    'pet.owner.last_name',
    'pet.owner.email'
  ]

  const testTypesOptions = testTypes
  const [testTypesSelected, setTestTypesSelected] = useState([])

  const statusOptions = [
    {
      label: 'filters_label.status.pending.name',
      value: 'pending'
    },
    {
      label: 'filters_label.status.submitted.name',
      value: 'submitted'
    }
  ]

  const [statusSelected, setStatusSelected] = useState([])

  const compareFunctionStatus = (patient, arraySelected) => {
    return arraySelected.some(v => v === patient.status)
  }

  const compareFunctionTest = (patient, arraySelected) => {
    return arraySelected.some(v => v === patient.kit?.productName)
  }

  const amountFunctionStatus = (value, patient) => {
    return (patient.status === value)
  }

  const amountFunctionTest = (value, patient) => {
    return (patient.kit.productName === value)
  }

  useEffect(() => {
    const opt = [
      {
        icon: helpCircleSharp,
        label: 'Status',
        functionGetAmount: amountFunctionStatus,
        functionFilter: compareFunctionStatus,
        options: [...statusOptions],
        setValueSelected: setStatusSelected,
        valuesSelected: statusSelected
      },
      {
        icon: documentSharp,
        label: 'Test Type',
        functionGetAmount: amountFunctionTest,
        functionFilter: compareFunctionTest,
        options: [...testTypesOptions],
        setValueSelected: setTestTypesSelected,
        valuesSelected: testTypesSelected
      }
    ]

    setOptions(opt)
  }, [testTypesSelected, statusSelected, requisitionsLogs])

  const searchSortFilter = (
    <SearchFilterSort
          align= 'between'
          enabledFeatures={{
            filterSelector: true,
            buttons: { options },
            search: true,
            sort: false,
            filterByDate: false,
            missingEmail: false // hasMissingEmail // enable this state if the need to show it just when there are RFs with missing comes again
          }}
          fieldsToSearch={fieldsToSearch}
          resetFunction={ () => {
            setStatusSelected([])
            setTestTypesSelected([])
          }}
          searchIn={requisitionsLogs}
          searchPlaceholder={content.get('requisition_logs.search')}
          setResultFunction={setRequisitionsFiltered}
          showResetButton={testTypesSelected.length > 0 || statusSelected.length > 0}
        />
  )

  return (
    <>
    {loading
      ? <IonGrid fixed>
          <Skeleton
            animated={true}
            colsSizes={[2, 4, 4, 1, 1]}
            customStyle={{ height: '20px' }}
            rows={3}
          />
        </IonGrid>
      : (!requisitionsLogs || requisitionsLogs.length === 0)
          ? <RequisitionFormLogEmptyState requisitionsLogsLength={requisitionsLogs?.length} />
          : <>
          <IonRow className="search-sort-fullscreen">
            {searchSortFilter}
          </IonRow>
          <IonGrid fixed>
            <div className="mt-5">
              {(requisitionsFiltered.length === 0)
                ? <RequisitionFormLogEmptyState requisitionsLogsLength={requisitionsLogs?.length} />
                : <>
                  <div className='d-flex ion-justify-content-end mb-4'>
                    <PaginationSelector
                      baseKey={'requisition-logs-pagination'}
                      reportsPerPage={reportsPerPage}
                      setReportsPerPage={setReportsPerPage}
                    />
                    <SortByButton
                      defaultOrder={'relevance'}
                      icon={caretDownSharp}
                      options={[
                        {
                          label: 'Relevance',
                          value: 'relevance',
                          value_order: '',
                          groupBy: 'status',
                          orderGroup: 'pending|submitted'
                        },
                        {
                          label: 'Last updates',
                          value: 'last_update',
                          value_order: 'last_updated',
                          direction: 'desc'
                        },
                        {
                          label: 'Oldest',
                          value: 'oldest',
                          value_order: 'last_updated',
                          direction: 'asc'
                        }
                      ]}
                      setResultFunction={setRequisitionLogs}
                      sortIn={requisitionsLogs}
                    />
                  </div>
                  {
                    currentSlice.map((log, index) => {
                      return (
                        <RequisitionFormLogCard
                          key={`RequisitionForm-${index}`}
                          log={log}
                          onDelete={async () => {
                            servicesNew.delete('/requisitionForm/' + log.id)
                            const newRFList = requisitionsLogs.filter(rl => rl.id !== log.id)
                            setRequisitionLogs(newRFList)
                          }}
                        />
                      )
                    })
                  }

                  <Pagination
                    itemsToPaginate={requisitionsFiltered}
                    pageNeighbours={desktop ? 2 : 1}
                    reportsPerPage={reportsPerPage}
                    setCurrentSlice={setCurrentSlice}
                  />
                </>
              }
            </div>
            <div className="mt-4">
              <GreyBanner
                buttonHref={content.get('VET_RESOURCES.BANNER.EMAIL')}
                buttonText={content.get('requisition_forms_log.banner.cta')}
                message={content.get('requisition_forms_log.banner.content')}
                title={content.get('requisition_forms_log.banner.title')}
              />
            </div>
          </IonGrid>
        </>
      }
    </>
  )
}

export default requisitionFormLogList
